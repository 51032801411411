import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IGamesState } from './types';
import { IRootState } from '../types';

export const state: IGamesState = {
  games: [],
  activeUsers: [],
};

const namespaced = true;

const games: Module<IGamesState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default games;
