
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

@Component
export default class ProfileProgressIcon extends Vue {
  @State(profile => profile.user.goldMember, { namespace: 'profile' })
  readonly goldMember!: boolean;
  @Prop(String) readonly profilePhotoUrl!: number;
  @Prop({ type: Number, default: 38 }) readonly size!: number;

  get styles() {
    return {
      height: `${this.size}px`,
      width: `${this.size}px`,
    };
  }

  get iconSize() {
    return this.size - 8 + 'px';
  }
}
