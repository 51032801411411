var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"payment-picker py-2 pl-2",attrs:{"align-center":""},on:{"click":_vm.handleOpen}},[(_vm.selectedItem)?_c('v-layout',{attrs:{"align-center":""}},[_c('img',{staticClass:"mr-4",attrs:{"height":"32px","src":_vm.selectedItem.icon,"alt":_vm.selectedItem.name ? _vm.filterGyriText(_vm.selectedItem.name) : ''}}),_vm._v(" "),_c('div',[(_vm.selectedItem.name)?_c('h5',[(_vm.$i18n.locale === 'gb')?_c('span',{staticClass:"d-inline"},[_vm._v("$")]):_vm._e(),_vm._v("\n          "+_vm._s(_vm.filterForUk(_vm.filterGyriText(_vm.selectedItem.name)))+"\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.loggedIn && _vm.wallets.length && _vm.selectedCoin && _vm.selectedCoin.network
          )?_c('p',{staticClass:"small-font accentLight--text mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n          "+_vm._s(_vm.$cryptoFormat(_vm.coinBalance(_vm.selectedCoin)))+"\n        ")]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{staticClass:"modalGray--text",attrs:{"size":"38"}},[_vm._v("\n      chevron_right\n    ")])],1),_vm._v(" "),_c('BottomDrawerDialog',{model:{value:(_vm.optionsDrawer),callback:function ($$v) {_vm.optionsDrawer=$$v},expression:"optionsDrawer"}},[_c('div',{staticClass:"secondary options-drawer lighten-2"},[_c('h2',{class:{
          'text-center mb-0 primary-text lighten-4': true,
          'pt-3 pb-2': _vm.$vuetify.breakpoint.xs,
          'pt-5 pb-4': _vm.$vuetify.breakpoint.smAndUp,
        }},[_vm._v("\n        "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectPaymentMethod'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"secondary options-drawer lighten-2 pb-8"},[(_vm.creditCardsAllowed)?_c('p',{class:{
          'text-left px-5 mb-0 accentLight--text': true,
          'pt-3 pb-1': _vm.$vuetify.breakpoint.xs,
          'pt-3 pb-1 medium-font': _vm.$vuetify.breakpoint.smAndUp,
        }},[_vm._v("\n        "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectCreditCard'))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.fiatOptionWallets),function(wallet,index){return (_vm.creditCardsAllowed)?[_c('v-layout',{key:("fiat-wallet-" + index),class:{
            'payment-select-item secondary px-5 py-2 mx-5 my-2': true,
            'info darken-2 selected': wallet.name === _vm.selectedItem.name,
            'pa-2': _vm.$vuetify.breakpoint.xs,
          },attrs:{"align-center":""},on:{"click":function($event){return _vm.handleOptionSelect(wallet)}}},[_c('v-layout',{attrs:{"align-center":""}},[_c('img',{staticClass:"mr-4",attrs:{"src":wallet.icon || wallet.imageUrl,"alt":wallet.name ? _vm.filterGyriText(wallet.name) : ''}}),_vm._v(" "),_c('div',[(wallet.name)?_c('h2',[_vm._v(_vm._s(_vm.filterGyriText(wallet.name)))]):_vm._e(),_vm._v(" "),(wallet.network)?_c('p',{staticClass:"small-font accentLight--text mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n                "+_vm._s(_vm.$cryptoFormat(_vm.coinBalance(wallet)))+"\n              ")]):_vm._e()])]),_vm._v(" "),(_vm.rebateAmountInUsd(wallet) && _vm.rebateTokenType(wallet))?_c('div',{staticClass:"d-flex align-center my-2"},[_c('span',{staticClass:"rebate-message"},[_vm._v("\n              "+_vm._s(_vm.$t('components.wallet.paymentPicker.getRebate', {
                  rebateAmount: _vm.$numeral(
                    _vm.rebateAmountInUsd(wallet),
                    _vm.rebateAmountInUsd(wallet) < 1 ? "0,0.00" : "0"
                  ),
                  rebateToken: _vm.rebateTokenType(wallet),
                }))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"rebate-tag-container"},[_c('img',{attrs:{"src":"https://static.gala.games/images/icons/rebate-tag.svg","alt":"Rebate"}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('components.store.checkoutPurchaseControls.rebates'))+"\n              ")])])]):_vm._e()],1)]:_vm._e()}),_vm._v(" "),_c('p',{class:{
          'text-left px-5 mb-0 accentLight--text': true,
          'pt-3 pb-1': _vm.$vuetify.breakpoint.xs,
          'pt-3 pb-1 medium-font': _vm.$vuetify.breakpoint.smAndUp,
          'mt-4': _vm.creditCardsAllowed,
        }},[_vm._v("\n        "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectGalaWallet'))+"\n      ")]),_vm._v(" "),_vm._l((_vm.paymentOptionWallets),function(wallet,index){return [_c('v-layout',{key:("wallet-" + index),class:{
            'payment-select-item secondary px-5 py-2 mx-5 my-2': true,
            'info darken-2 selected': wallet.name === _vm.selectedItem.name,
            'pa-2': _vm.$vuetify.breakpoint.xs,
          },attrs:{"align-center":""},on:{"click":function($event){return _vm.handleOptionSelect(wallet)}}},[_c('v-layout',{attrs:{"align-center":""}},[_c('img',{staticClass:"mr-4",attrs:{"src":wallet.icon || wallet.imageUrl,"alt":wallet.name ? _vm.filterGyriText(wallet.name) : ''}}),_vm._v(" "),_c('div',[(wallet.name)?_c('h2',[(_vm.$i18n.locale === 'gb')?_c('span',{staticClass:"d-inline"},[_vm._v("$")]):_vm._e(),_vm._v("\n                "+_vm._s(_vm.filterForUk(_vm.filterGyriText(wallet.name)))+"\n              ")]):_vm._e(),_vm._v(" "),(wallet.network)?_c('p',{staticClass:"small-font accentLight--text mb-0"},[_vm._v("\n                "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n                "+_vm._s(_vm.$cryptoFormat(_vm.coinBalance(wallet)))+"\n              ")]):_vm._e()])]),_vm._v(" "),(_vm.rebateAmountInUsd(wallet) && _vm.rebateTokenType(wallet))?_c('div',{staticClass:"d-flex align-center my-2"},[_c('span',{staticClass:"rebate-message"},[_vm._v("\n              "+_vm._s(_vm.$t('components.wallet.paymentPicker.getRebate', {
                  rebateAmount: _vm.$numeral(
                    _vm.rebateAmountInUsd(wallet),
                    _vm.rebateAmountInUsd(wallet) < 1 ? "0,0.00" : "0"
                  ),
                  rebateToken: _vm.rebateTokenType(wallet),
                }))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"rebate-tag-container"},[_c('img',{attrs:{"src":"https://static.gala.games/images/icons/rebate-tag.svg","alt":"Rebate"}}),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('components.store.checkoutPurchaseControls.rebates'))+"\n              ")])])]):_vm._e()],1)]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }