import { MutationTree } from 'vuex';
import { IGame, IGamesState, IUserAllowance } from './types';

import _startCase from 'lodash.startcase';

export const mutations: MutationTree<IGamesState> = {
  setGames(state: IGamesState, games: IGame[]) {
    state.games = [...games];
  },

  updateGameActiveUsers(
    gamesState: IGamesState,
    { gameName, activeUsers }: { gameName: string; activeUsers: number },
  ) {
    const gameToUpdate = gamesState.games.find(
      game => game.codeName === gameName,
    );

    if (gameToUpdate) {
      gameToUpdate.activeUsers = activeUsers > 0 ? activeUsers : 0;
    }

    gamesState.games = [...gamesState.games];
  },

  addGameActiveUser(
    gamesState: IGamesState,
    { gameName }: { gameName: string },
  ) {
    const gameToUpdate = gamesState.games.find(
      game => game.codeName === gameName,
    );

    if (gameToUpdate) {
      gameToUpdate.activeUsers = (gameToUpdate.activeUsers || 0) + 1;
    }

    gamesState.games = [...gamesState.games];
  },
  updateAllowancesGiven(
    gamesState: IGamesState,
    {
      collection,
      allowances,
    }: { collection: string; allowances: IUserAllowance[] },
  ) {
    const gameToUpdate = gamesState.games.find(
      game => game.collection === collection,
    );
    if (gameToUpdate) {
      gameToUpdate.allowancesGiven = allowances;
    }
  },
};
