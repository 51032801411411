import { Component, Vue } from 'vue-property-decorator';
import { GalaChainInfo } from '~/types/gala-chain';
import { TreasureChestInfo } from '~/types/treasure-chest';

@Component
export default class TextOutput extends Vue {
  filterAllSymbolsText(text: string) {
    const gyriFiltered = this.filterGyriText(text);
    return this.filterForUk(this.filterGCText(gyriFiltered));
  }

  filterGyriText(text: string) {
    const replacementText =
      this.$i18n.locale === 'gb' ? '\uE001Chain' : GalaChainInfo.CHAIN_NAME;
    return text?.replace(/gyri/i, replacementText);
  }

  filterGCText(text: string) {
    const replacementText = TreasureChestInfo.TREASURE_CHEST_NAME;
    return text.replace(/\[GC\]/i, replacementText);
  }

  filterForUk(text: string) {
    const rpText =
      this.$i18n.locale === 'gb' ? text.replaceAll(/Gala/gi, '\uE001') : text;
    return rpText;
  }
}
