

















































































































import { Component, Mixins } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { ExternalWallet, AuthQuery } from '~/mixins';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import {
  IWalletConnectionError,
  WalletConnectionErrorCode,
} from '~/store/web3_wallet/types';

@Component({ components: { TwoFactorAuthInputPrompt } })
export default class WalletConnectionErrorBanner extends Mixins(
  ExternalWallet,
  AuthQuery,
) {
  @State(w3w => w3w.walletConnectionError, { namespace: 'web3Wallet' })
  walletConnectionError!: IWalletConnectionError;
  @State(w3w => w3w.showWalletConnectionError, { namespace: 'web3Wallet' })
  showWalletConnectionError!: boolean;

  @Mutation('toggleWalletConnectionError', { namespace: 'web3Wallet' })
  toggleWalletConnectionError!: (show?: boolean) => void;

  walletConnectionErrorCode = WalletConnectionErrorCode;
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  dappPath = window.location.href.toString().replace(/(http[s]*:\/\/)/, '');

  get onConnectionPage() {
    return this.$route.name === 'connect-wallet';
  }

  get shortMessage() {
    return (
      this.walletConnectionError.shortMessage ||
      this.walletConnectionError.message
    );
  }

  get selectedProviderOption() {
    return this.providerOptions.find(
      option => option.label === this.connectedExternalWallet.walletProvider,
    );
  }

  get missingProviderLink() {
    if (this.selectedProviderOption) {
      return this.isMobile
        ? this.selectedProviderOption.missingProviderMobileLink(this.dappPath)
        : this.selectedProviderOption.missingProviderDesktopLink;
    }
    return '';
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  handleConnectClick() {
    this.connect(
      {
        label: this.connectedExternalWallet.walletProvider,
        connectToUser: false,
      },
      this.doAuthQuery,
    );
  }
}
