import { ActionTree } from 'vuex';
import { IShareConfigState } from './types';
import { IRootState } from '../types';
import shareConfig from '../../queries/shareConfig.gql';
import getFriends from '../../queries/friends.gql';
import activeLicenseCount from '../../queries/activeLicenseCount.gql';

export const actions: ActionTree<IShareConfigState, IRootState> = {
  async fetchShareConfig({ commit, dispatch }) {
    try {
      if (this.app.apolloProvider) {
        commit('setFetching', true);
        dispatch('fetchUserNodeLicenseCount');

        const client = this.app.apolloProvider.defaultClient;

        const shareConfigResults = await client.query({
          query: shareConfig,
          fetchPolicy: 'network-only',
        });

        const {
          data: { galaShareConfig: shareConfigData },
        } = shareConfigResults;

        commit('setShareConfig', { ...shareConfigData });
        commit('setFetching', false);
      }
    } catch (error) {
      this.$sentry.captureException(error);
      commit('setFetching', false);
      console.warn(error);
    }
  },

  async fetchUserNodeLicenseCount({ commit }) {
    try {
      if (this.app.apolloProvider) {
        const gatewayClient = this.app.apolloProvider.clients.gateway;

        const userNodeLicenseCounts = await gatewayClient.query({
          query: activeLicenseCount,
          fetchPolicy: 'cache-first',
        });

        const shareConfigData = {
          founderNodesOwned:
            userNodeLicenseCounts.data.activeLicenseCount.founder,
          townstarNodesOwned:
            userNodeLicenseCounts.data.activeLicenseCount.townstar,
          spiderTanksNodesOwned:
            userNodeLicenseCounts.data.activeLicenseCount.all.find(
              (n: any) => n.licenseName === 'spider-tanks-node-license',
            )?.quantity ?? 0,
        };

        commit('setNodesOwned', shareConfigData);
      }
    } catch (error) {
      this.$sentry.captureException(error);
      console.warn(error);
    }
  },

  async fetchFriends({ commit }) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const {
        data: { friends },
      } = await client.query({
        query: getFriends,
        fetchPolicy: 'network-only',
      });

      commit('setFriends', friends);
    }
  },
};
