import { GetterTree } from 'vuex';
import { IGamesState } from './types';
import { IRootState } from '../types';
import _countBy from 'lodash.countby';
import _flatten from 'lodash.flatten';

export const getters: GetterTree<IGamesState, IRootState> = {
  gamesUsingGalaChain(state: IGamesState) {
    return state.games
      .filter(game => game.usesGalaChain && game.showGamePage)
      .map(gameObj => gameObj.collection);
  },
};
