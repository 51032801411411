




import { Component, Vue, Watch } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import { availableLocales, getCurrentLocale } from '../utils/locales';
import { State } from 'vuex-class';
import DropUp from '~/components/UIComponents/DropUp.vue';
import { LocaleObject } from '@nuxtjs/i18n';

@Component({ components: { CountryFlag, DropUp } })
export default class LanguageSwitcher extends Vue {
  @State(profile => profile.ipLocation, { namespace: 'profile' })
  userIpLocation!: { country: string; region: string };

  selectedValue = 'en';

  created() {
    const localeInfo = {
      localeCookie: this.$i18n.getLocaleCookie(),
      browserLocale: this.$i18n.getBrowserLocale(),
      locales: this.$i18n.locales,
    };
    this.selectedValue = getCurrentLocale(localeInfo).code;
  }

  get options() {
    return availableLocales(this.$i18n.locales as LocaleObject[]).map(l => ({
      ...l,
      value: l.code,
    }));
  }

  @Watch('selectedValue')
  handleLanguageSelected() {
    setTimeout(() => {
      this.$i18n.setLocale(this.selectedValue);
      this.$store.dispatch('cmsContent/loadCmsContent', {
        i18n: this.$i18n,
        sentry: this.$sentry,
        ipLocation: this.userIpLocation,
      });
    }, 200);
  }
}
