













































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FullScreenDialog extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({ type: Boolean, default: false }) readonly noPadding!: boolean;
  @Prop({ type: Boolean, default: true }) readonly topPadding!: boolean;
  @Prop({ type: Boolean, default: true }) readonly contained!: boolean;
  @Prop({ type: Boolean, default: false }) readonly alwaysFull!: boolean;
  @Prop({ type: Boolean, default: false, required: false })
  readonly hideBackArrow!: boolean;
  @Prop({ type: String, default: 'arrow_back_ios' }) closeIcon!: string;
  @Prop({ type: String, default: 'modalBackground' }) color!: string;
  @Prop(Function) readonly closeDialog!: Function;

  get mobile() {
    return this.$vuetify.breakpoint.xs || this.alwaysFull;
  }

  get showBalance() {
    if (this.$slots.balance) {
      return true;
    } else {
      return false;
    }
  }
}
