











































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { IPromotionEligibility } from '~/store/profile/types';
import ClaimPromotion from '~/mutations/claimPromotion.gql';

@Component({})
export default class PromoFriendsFreeBoxes extends Vue {
  @Getter('getPromoInitialized', { namespace: 'promoPopup' })
  promoInitialized!: boolean;
  @Getter('getHasSeen', { namespace: 'promoPopup' })
  hasSeen!: boolean;
  @Getter('getHasClaimed', { namespace: 'promoPopup' })
  hasClaimed!: boolean;
  @Getter('getCurrPromo', { namespace: 'promoPopup' })
  currPromo!: IPromotionEligibility;
  @Getter('getIsSending', { namespace: 'promoPopup' })
  isSending!: boolean;

  @Action('setPromoInitialized', { namespace: 'promoPopup' })
  private setPromoInitialized!: (payload: boolean) => void;
  @Action('setHasSeen', { namespace: 'promoPopup' })
  private setHasSeen!: (payload: boolean) => void;
  @Action('setHasClaimed', { namespace: 'promoPopup' })
  private setHasClaimed!: (payload: boolean) => void;
  @Action('setIsSending', { namespace: 'promoPopup' })
  private setIsSending!: (payload: boolean) => void;
  @Action('initializePromo', { namespace: 'promoPopup' })
  private initializePromo!: () => Promise<boolean>;

  sendImg = require('@/assets/icons/send-icon-mb.png');
  hasError = false;

  get headline() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.headline`,
    );
  }
  get subheadline() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.subheadline`,
    );
  }
  get explainer() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.explainer`,
    );
  }
  get ctaText() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.ctaText`,
    );
  }
  get cheadline() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.claimed.headline`,
    );
  }
  get csubheadline() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.claimed.subheadline`,
    );
  }
  get cCtaText() {
    return this.$t(
      `components.promoPopup.${this.currPromo?.promotionId}.claimed.ctaText`,
    );
  }

  get getPromoInitialized() {
    return this.promoInitialized;
  }

  get showPopup() {
    return this.currPromo?.eligible && !this.hasSeen;
  }

  get boxImage() {
    return this.currPromo?.imageUris?.main;
  }

  get frontSide() {
    return !this.hasClaimed && !this.isSending && !this.hasError;
  }

  get sendingSide() {
    return this.isSending && !this.hasError;
  }

  get backSide() {
    return this.hasClaimed && !this.isSending && !this.hasError;
  }

  get checkHasError() {
    return this.hasError;
  }

  closePopup() {
    this.setIsSending(false);
    this.setHasSeen(true);
    this.hasError = false;
  }

  goTo(route: string) {
    this.closePopup();
    this.$router.push(route);
  }

  async claimPromotion() {
    this.setIsSending(true);
    try {
      const { data } = await this.$apollo.mutate<{
        claimPromotion: {
          numRewardsSentToFriends: number;
          numRewardsSentToSelf: number;
          rewardId: string;
        };
      }>({
        mutation: ClaimPromotion,
        variables: {
          promotionId: this.currPromo?.promotionId,
        },
      });

      console.log(data);

      if (data && data.claimPromotion.numRewardsSentToFriends) {
        this.setHasClaimed(true);
      }
    } catch (error) {
      console.log(error);
      this.hasError = true;
    }

    this.setIsSending(false);
  }

  async mounted() {
    const promoInit = await this.initializePromo();
    if (promoInit) {
      this.setPromoInitialized(true);
    }
  }
}
