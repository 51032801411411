export enum GalaChainInfo {
  CHAIN_NAME = 'GalaChain',
}

export enum GalaChainMethods {
  MintToken = 'MintToken',
  TransferToken = 'TransferToken',
  BurnTokens = 'BurnTokens',
}

export interface ITokenClassKey {
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface ITokenInstanceKey extends ITokenClassKey {
  instance: string;
}

export interface ITokenInsanceKeyWithQuantity {
  tokenInstanceKey: ITokenInstanceKey;
  quantity: string;
}
