import { MutationTree } from 'vuex';
import { IGalaHonor, IProfileState, IUser } from './types';

const initialUserState: IUser = {
  preferences: {
    currency: 'USD',
    language: 'english',
    notifications: true,
  },
  profilePhotoUrl: '',
  id: '',
  email: '',
  emailVerified: undefined,
  phone: '',
  firstName: '',
  lastName: '',
  fullNameNotProvided: true,
  displayName: '',
  role: '',
  permissions: [],
  walletConnected: false,
  walletExists: false,
  twoFaAuthenticated: false,
  twoFaEnabled: false,
  loggedIn: false,
  gameToken: '',
  goldMember: false,
  discordProfile: undefined,
  networkNotifications: {},
  referralCode: '',
  isInBlockedCountry: false,
  shouldBlockGameplay: false,
  galaHonor: {
    spiderTanks: {
      currentHonor: 0,
      estimatedHonorChange: 0,
    },
  },
  hasGyriPassphrase: false,
  auth0UserIdentities: [],
  campaignWelcomeModal: '',
  created: new Date(),
  promotionEligibility: [],
};

export const mutations: MutationTree<IProfileState> = {
  updateIsFetching(profileState: IProfileState, payload: boolean) {
    profileState.isFetching = payload;
  },
  updateUser(profileState: IProfileState, userInfo: Partial<IUser>) {
    const shouldBlockGameplay =
      new Date() > new Date('2022-05-20T15:00:00.000Z');
    profileState.user = {
      ...profileState.user,
      loggedIn: true,
      ...userInfo,
      shouldBlockGameplay,
    };
  },
  updateGameJWT(
    profileState: IProfileState,
    payload: { token: string; timeSet: number },
  ) {
    Object.assign(profileState.user, {
      gameToken: payload.token,
    });

    profileState.gameTokenTimeSet = payload.timeSet;
  },

  clearUser(profileState: IProfileState) {
    Object.assign(profileState.user, initialUserState);
  },

  profileLoaded(state, payload: IUser) {
    state.error = false;
    state.user = payload;
  },

  profileError(state) {
    state.error = true;
    state.user = initialUserState;
  },

  updateGalaHonor(profileState: IProfileState, payload: IGalaHonor) {
    profileState.user.galaHonor = payload;
  },

  updateIpLocation(
    profileState: IProfileState,
    payload: { country: string; region: string },
  ) {
    profileState.ipLocation = payload;
  },
};
