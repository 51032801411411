








import { Component, Vue } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import { numericFormatOptions } from '../utils/locales';
import DropUp from '~/components/UIComponents/DropUp.vue';
import { Getter, Mutation } from 'vuex-class';

@Component({ components: { CountryFlag, DropUp } })
export default class NumericFormatSelector extends Vue {
  @Getter('numericFormatJsCode')
  readonly numericFormatJsCode!: string;

  @Mutation('setBrowserPersistentSetting')
  readonly setBrowserPersistentSetting!: (payload: {
    key: string;
    value: string;
  }) => void;

  get options() {
    return numericFormatOptions.map(o => ({
      value: o.jsCode,
      displayText: this.$t(o.displayTextKey),
      flagCode: o.flagCode,
    }));
  }

  handleInput(newSelection: string) {
    this.setBrowserPersistentSetting({
      key: 'numericFormatJsCode',
      value: newSelection,
    });
  }
}
