



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BottomDrawerDialog extends Vue {
  @Prop(Boolean) readonly value!: boolean;
  @Prop(String) readonly maxWidth?: string;

  readonly defaultWidth = '600px';
}
