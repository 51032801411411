import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IInventoryState } from './types';
import { IRootState } from '../types';

export const state: IInventoryState = {
  wallets: [],
  walletsErrored: false,
  userItems: [],
  userItemsErrored: false,
  userTickets: [],
  claimFees: [],
  itemCollections: [
    {
      name: 'Vox Series 1',
      icon: require('@/assets/icons/icon_vox-series-1.png'),
    },
    {
      name: 'Gala Music',
      icon: require('@/assets/icons/icon_music_cat.png'),
    },
  ],
  claimNetwork: {
    network: 'ETHEREUM',
    currency: 'ETH',
  },
  isFetchingWallets: false,
  mostRecentExchangeRewards: [],
};

const namespaced = true;

const inventory: Module<IInventoryState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default inventory;
