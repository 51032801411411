import {
  AllowanceQuantity,
  AllowanceQuantityUnspent,
  BalanceQuantityUnlocked,
  TokenClassId,
} from '../../modules/core/galachain-types.js';
import { GameName } from '../../modules/core/game-types';

export enum GamePlatform {
  Mac = 'Mac',
  Win = 'PC',
  Linux = 'Linux',
  Browser = 'Browser',
  iOS = 'iOS',
  Android = 'Android',
}

export interface IGameProduct {
  baseId: string;
  game: string;
  name: string;
  coin?: string;
  description: string;
  image: string;
  rarity: IGameItemRarity;
}

export interface IGameItemRarity {
  icon: string;
  hexcode?: string;
  label?: string;
  supplyLimit?: number;
}

export interface ITokenClassKey {
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface IRebate {
  percentageOfUsdPurchasePrice: number;
  type: string;
  tokenClassKey: ITokenClassKey;
}

export interface IGameItemProductPrice {
  price: string;
  basePrice: string;
  usdPriceInCents: number;
  usdBasePriceInCents: number;
  symbol: string;
  creditCard: boolean;
  rebate?: IRebate;
}

export interface IGameItemProductTier {
  tier: number;
  supply: number;
}

export interface IGameItemForSaleByGala extends IGameProduct {
  productId: string;
  prices: IGameItemProductPrice[];
  galaGoldOnly: boolean;
  nodeOwnerOnly: boolean;
  galaGoldOrNodeOwnerOnly: boolean;
  maxQuantityPerOrder: number;
  invoiceAddress: string;
  qtyLeft: number;
  purchasingDisabled: boolean;
  expiresAt?: number;
  category?: string;
  images: Array<{
    url: string;
    caption?: string;
    thumbnail?: string;
    hasAudio?: boolean;
  }>;
  countdown: IGameItemCountdown;
  currentTier: number;
  tiers: IGameItemProductTier[];
  restrictionId: string;
  priceAdjustmentId: string;
  quantityMultiplier?: number;
  gyriExchanges?: Array<{ id: number }>;
  autoOpenAsGyriExchange?: boolean;
  type: string;
  hasRebate?: boolean;
  expiresInMonths?: number;
  galaOriginal?: boolean;
  thirdPartyCreator?: boolean;
  galachainInvoiceAccountKey?: string;
  quantityDetails?: Array<{
    forPurchasesWithToken: string;
    suggestedQuantity: number;
    quantityDetailStringKey?: string;
  }>;
}

interface IGameItemCountdown {
  target: Date;
  runningLabel: string;
}

interface ICraftedItemPiece extends IGameProduct {
  quantityNeeded: number;
}

export interface ICraftedItem extends IGameProduct {
  requiredPieces: ICraftedItemPiece[];
}

export interface IGameActiveUsers {
  gameName: string;
  activeUsers: number;
}

export interface IGameDescriptionAsset {
  thumbnail: string;
  medium: string;
  large?: string;
  poster?: string;
  type: 'img' | 'video';
  hidePlayIcon?: boolean;
}

export enum AllowanceType {
  TRANSFER = 3,
}

export interface IRequiredAllowance {
  type: string;
  tokenImg: string;
  tokenId: string;
  action: AllowanceType;
  amount: string;
  ownerKey: string;
  uses: string;
  category: string;
  additionalKey: string;
}

export interface IUserAllowance {
  action: AllowanceType;
  amount: string;
  amountSpent: string;
  created: 1645742505789;
  expires: 0;
  instanceId: string;
  issuerKey: string;
  tokenId: string;
  uses: string;
  usesSpent: string;
}

export interface IRequiredGameItem {
  asset: string;
  baseId: string;
  contractAddress: string;
  itemName: string;
}

export interface IGame {
  name: GameName;
  displayName: string;
  showGamePage: boolean;
  codeName: string;
  developer: string;
  gamePublisher: string;
  genre: string;
  developmentStatus: string;
  platform: GamePlatform[];
  displayedPlatform?: string;
  isReleased: boolean;
  isReleasedInternally?: boolean;
  descriptionAssets: IGameDescriptionAsset[];
  aboutUsImage: string;
  linkImage: string;
  longDescription: string;
  route: string;
  playLink: string;
  logo: string;
  cardCarouselPromoImage?: string;
  cardCarouselPromoLogo?: string;
  croppedLogo?: string;
  craftedItems: ICraftedItem[];
  isMacReleased?: boolean;
  isWinReleased?: boolean;
  isInstalled?: boolean;
  installedVersion?: string;
  latestWindowsVersion?: string;
  latestMacVersion?: string;
  installerUrlMac?: string;
  installerUrlWin?: string;
  showActivePlayers?: boolean;
  timePlayerIsActive?: number;
  activePlayerCountInDataBricks?: boolean;
  websiteLink?: string;
  discordLink?: string;
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  youtubeLink?: string;
  downloadLink?: string;
  activeStartTimestamp?: number;
  activeEndTimestamp?: number;
  comingSoon?: string;
  bannerLogo?: string;
  bannerCtaText?: string;
  bannerCtaLink?: string;
  gamePageBackground?: string;
  gamePageHeaderLogo?: string;
  showNodePromotion?: boolean;
  emphasizedStoreCategory?: string;
  requireAuthToPlay?: boolean;
  requireEmailVerification?: boolean;
  bannerImageClass?: string;
  usesGalaChain?: boolean;
  hasRestrictionsToPlay?: boolean;
  restrictionId?: string;
  collection: string;
  requiredAllowances?: IRequiredAllowance[];
  allowancesGiven?: IUserAllowance[];
  narrowText?: boolean;
  showCarouselBanner?: boolean;
  updatedAt?: number; // milliseconds since January 1, 1970 00:00:00 UTC (Date.now())
  inventoryDisclaimer?: string;
  activeUsers?: number;
  hasItemRequiredToPlay?: boolean;
  requiredItemIds?: IRequiredGameItem[];
  requiredItemUrl?: string;
  requiredItemStatus?: boolean;
  hasApkFile?: boolean;
  apkLabelText?: string;
  apkLinkIcon?: boolean;
  apkLinkText?: string;
  apkLink?: string;
  apkUsesCreators?: boolean;
  hasIOSDownload?: boolean;
  iOSTestflightLabelText?: string;
  iOSTestflightLinkIcon?: boolean;
  iOSTestflightLinkText?: string;
  iOSDownloadLink?: string;
  iOSUsesCreators?: boolean;
  customGyriGachaLoadingVideo?: string;
  windowsUsesCreators?: boolean;
  macUsesCreators?: boolean;
  tenantId?: string;
  totallyBlockedInLocations?: string[];
  isTotallyBlocked?: boolean;
  gameIFrameCustomCss?: string;
  gameIFrameWrapperDivCustomCss?: string;
  mainGenres?: string[];
  secondaryGenres?: string[];
  customDevStatus?: string;
  devStatusAndProgress?: IDevStatusAndProgress;
  usePlayButtonRedirect?: boolean;
  playButtonRedirectLink?: string;
  storeFilterLinkRoute?: string;
  isFreeToPlay?: boolean;
}

export interface IGamesState {
  games: IGame[];
  activeUsers: IGameActiveUsers[];
}

export interface IPurchaseAgreement {
  label: string;
  link: string;
  checked: boolean;
  content: string;
}

export interface IDevStatusAndProgress {
  primaryStatus?: string;
  statusDetails?: string;
}

export interface IGameFilterCategory {
  title: string;
  filterList: IGameFilter[];
  showAll?: boolean;
}

export interface IGameFilter {
  value: string;
  selected: boolean;
}

export interface TokenClass {
  id: TokenClassId;
  name: string;
  image: string;

  balance?: {
    balanceQuantityUnlocked: BalanceQuantityUnlocked;
    totalBurnAllowanceQuantityUnspent: AllowanceQuantityUnspent;
    totalLockAllowanceQuantityUnspent: AllowanceQuantityUnspent;
  };
}

export interface IRequestedArbitraryAllowance {
  tokenClass?: TokenClass;
  tokenName?: string;
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
  instance: string;
  desiredAmount: string;
  allowanceType: number;
}

export interface IRequestedArbitraryAllowanceDiff
  extends IRequestedArbitraryAllowance {
  remaining: AllowanceQuantityUnspent;
  needed: AllowanceQuantity;
}
