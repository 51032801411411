var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showModal,"max-width":"1440px","width":"fit-content","retain-focus":false,"persistent":""},on:{"input":function($event){return _vm.close()}}},[_c('div',{staticClass:"prompt modalBackground py-10 px-10"},[_c('h1',{staticClass:"text-center modal-title mb-2"},[_vm._v("\n      "+_vm._s(_vm.$t('components.modalPrompts.gyriExchangeRewards.modalTitle'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"modal-subtitle text-center"},[_vm._v("\n      "+_vm._s(_vm.$t('components.modalPrompts.gyriExchangeRewards.modalSubtitle', {
          numberOfRewards: _vm.rewards.length,
        }))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-wrap justify-center mb-10"},_vm._l((_vm.sortedRewards),function(reward){return _c('div',{key:JSON.stringify(reward),staticClass:"ma-5 card-wrapper"},[_c('ItemCard',{attrs:{"fungible":reward.instance === '0',"quantity":Number(reward.quantity),"itemImage":reward.image,"itemName":_vm.rewardFullName(reward),"itemNameTextColor":_vm.gyriChainInfo.textColor,"chainIcon":_vm.gyriChainInfo.icon,"chainIconAltText":_vm.gyriChainInfo.iconAltText,"chainDisplayName":_vm.gyriChainInfo.chainDisplayNameWithContractType,"rarityIcon":reward.rarityIcon,"rarityLabel":reward.rarityLabel}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"d-flex flex-wrap justify-center mb-4"},[(_vm.game)?_c('GameActionButton',{staticClass:"game-action-button mb-4",attrs:{"game":_vm.game,"includeGameNameOnButton":true}}):_vm._e(),_vm._v(" "),(_vm.game)?_c('button',{class:{
          'gala-btn outline clear line-height-em full-width': true,
          'medium-font': _vm.$vuetify.breakpoint.xl,
        },on:{"click":function($event){return _vm.goToStore(_vm.game.route)}}},[_vm._v("\n        "+_vm._s(_vm.$t(
            'components.modalPrompts.gyriExchangeRewards.buyMoreGachaPacksButton'
          ))+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center"},[_c('button',{class:{
          'gala-btn outline clear line-height-em full-width': true,
          'medium-font': _vm.$vuetify.breakpoint.xl,
        },on:{"click":function($event){return _vm.close()}}},[_vm._v("\n        "+_vm._s(_vm.$t('common.cta.close'))+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }