import { TokenChainId } from './token-chain-id';

export enum ChainNetwork {
  ETHEREUM = 'ETHEREUM',
  GYRI = 'GYRI',
  ETH_TREASURE_CHEST = 'ETH_TREASURE_CHEST',
  GYRI_TREASURE_CHEST = 'GALACHAIN_ALLOWANCE',
  OFF_CHAIN_TOKEN = 'OFF_CHAIN_TOKEN',
}

export enum UserItemsDisplayState {
  BRIDGE = 'BRIDGE',
  DETAILS = 'DETAILS',
  EXCHANGE = 'EXCHANGE',
  SEND = 'SEND',
}

interface IUserItemRarity {
  icon: string;
  label: string;
  hexcode: string;
  supplyLimit?: number;
}

interface INodeFee {
  currency: string;
  amount: number;
}

interface INodeFees {
  mint?: INodeFee;
  lock?: INodeFee;
  unlock?: INodeFee;
}

interface INodeData {
  online: number;
  distributionEligible: boolean;
  fee: INodeFees;
}

interface IUserItem {
  network: string;
  fungible: boolean;
  name: string;
  description: string;
  collection: string;
  image: string;
  quantity: number;
  animation_url?: string;
  isExchangeable: boolean;
  uniqueInventoryPath?: string;
  fullName?: string;
  sendId?: string;
  canBridgeTo?:
    | [{ network: 'gyri' | 'ethereum'; destinationChainIds: TokenChainId[] }]
    | [];
  rarity: IUserItemRarity;
  exchangeRevealVideo?: string;
  logo?: string;
  icon?: string;
  node?: INodeData;
}

export interface IFungibleERC1155UserItem extends IUserItem {
  network: 'ETHEREUM';
  ethereumTokenStandard: 'erc1155';
  ethereumContractAddress: string;
  ethereumBaseId: string;
  ethereumFullId: string;
  fungible: true;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

export interface INonFungibleERC1155UserItem extends IUserItem {
  network: 'ETHEREUM';
  ethereumTokenStandard: 'erc1155';
  ethereumContractAddress: string;
  ethereumBaseId: string;
  ethereumFullId: string;
  fungible: false;
  nonFungibleInstanceId: string;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

interface IEthTreasureChestUserItem extends IUserItem {
  network: 'ETH_TREASURE_CHEST';
  ethereumBaseId: string;
  fungible: true;
  ccLockedCount?: number;
  treasureChestClaimDetails: Array<{
    claimType: string;
    quantity: number;
  }>;
  treasureChestExportDetails: Array<{
    network: string;
    contractType: string;
    contractAddress: string;
    nonFungible: boolean;
  }>;
}

export interface IGyriUserItem extends IUserItem {
  network: ChainNetwork.GYRI;
  gyriTokenClassKey: {
    collection: string;
    category: string;
    type: string;
    additionalKey: string;
  };
  fungible: false;
  nonFungibleInstanceId: string;
  quantity: 1;
  canBridgeTo?:
    | [{ network: 'ethereum'; destinationChainIds: TokenChainId[] }]
    | [];
  gyriExchanges: Array<{
    id: number;
    odds: Array<{
      metadata: {
        name: string;
        rarity: IUserItemRarity;
        image: string;
      };
      quantity: number;
      odds: number;
      collection: string;
      category: string;
      type: string;
      additionalKey: string;
    }>;
  }>;
}

export interface IItemOdds {
  metadata: {
    name: string;
    rarity: IUserItemRarity;
    image: string;
  };
  quantity: number;
  odds: number;
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface IGyriExchange {
  id: number;
  odds: IItemOdds[];
}

export interface IOffchainExchange extends IGyriExchange {
  exchangeVariantId: string;
}

export interface IOffchainUserItem extends Omit<IGyriUserItem, 'network'> {
  network: ChainNetwork.OFF_CHAIN_TOKEN;
  offChainTokenInstanceId: string;
  gyriExchanges: IOffchainExchange[];
}

export interface IGalaChainItemAllowance extends IUserItem {
  network: 'GALACHAIN_ALLOWANCE';
  gyriTokenClassKey: {
    collection: string;
    category: string;
    type: string;
    additionalKey: string;
  };
  fungible: true;
  quantity: number;
  allowanceType: string;
  allowanceTransferFrom?: string;
}

export interface IErc721UserItem extends IUserItem {
  network: 'ETHEREUM';
  ethereumTokenStandard: 'erc721';
  ethereumContractAddress: string;
  model: string;
  fungible: false;
  nonFungibleInstanceId: string;
  properties: Array<{
    property: string;
    value: string;
    colors: Array<{
      name: string;
      value: string;
    }>;
  }>;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

export interface IOffChainToken extends IUserItem {
  network: 'OFF_CHAIN_TOKEN';
  nonFungibleInstanceId: string;
  fungible: false;
  quantity: number;
}

export type RequiredUserItem =
  | IFungibleERC1155UserItem
  | INonFungibleERC1155UserItem
  | IEthTreasureChestUserItem;

export type UserItem =
  | IFungibleERC1155UserItem
  | INonFungibleERC1155UserItem
  | IEthTreasureChestUserItem
  | IGyriUserItem
  | IErc721UserItem
  | IGalaChainItemAllowance
  | IOffchainUserItem;
