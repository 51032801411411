import { Context } from '@nuxt/types';
import { getCookie } from '~/utils';

const routesToLogoutUserOn = ['/external/register'];

export default async function(context: Context) {
  const { store, app, route } = context;

  if (await app.$auth.isLoggedIn()) {
    const logoutUser = routesToLogoutUserOn.includes(route.path);
    if (logoutUser) {
      await store.dispatch('profile/userLogout');
    } else {
      const { user: currentUser } = store.state.profile;
      if (!currentUser.loggedIn) {
        store.commit('profile/updateUser', {
          loggedIn: true,
        });
      }

      if (!currentUser.email || !currentUser.gameToken) {
        await store.dispatch('profile/getUserData');

        // UA tracking
        const { user: latestUser } = store.state.profile;
        app.$ua.setUser(latestUser);
      }

      return;
    }
  }

  const blankUser = getCookie('blankUser');
  if (!blankUser) {
    const utmVariables = localStorage.getItem('utm-variables') || '{}';
    const offer = localStorage.getItem('offer') || '';
    const r = localStorage.getItem('r') || '';
    const referredBy = r.replace(/[^a-zA-Z0-9]/g, '');
    await store.dispatch('profile/createBlankUser', {
      ...JSON.parse(utmVariables),
      referredBy,
      offer,
    });
  } else {
    await store.dispatch('profile/getGameJWT');
  }
}
