




























import { Component, Prop, Vue } from 'vue-property-decorator';
import TwoFactorBox from '~/components/Authentication/TwoFactorBox.vue';

@Component({
  components: { TwoFactorBox },
})
export default class TwoFaModalContent extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly inProgress!: boolean;

  @Prop({ type: String, required: true })
  readonly value!: string;

  btnDisabled = true;

  handleDisableBtn(disabled: boolean) {
    this.btnDisabled = disabled;
  }

  handleTwoFaEntryChanged(value: string) {
    this.$emit('input', value);
  }

  async handleSubmit() {
    if (this.btnDisabled) {
      return;
    }

    this.$emit('submit');
  }
}
