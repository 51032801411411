import { render, staticRenderFns } from "./BottomDrawerDialog.vue?vue&type=template&id=721630e9&scoped=true&"
import script from "./BottomDrawerDialog.vue?vue&type=script&lang=ts&"
export * from "./BottomDrawerDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "721630e9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBottomSheet,VDialog})
