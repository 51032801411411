var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'subscribe-form-wrapper': true,
    'enable-simple-signup': _vm.enableSimpleSignup,
  },attrs:{"fill-background":false}},[(_vm.showHeader)?_c('h2',{staticClass:"text-h6 mb-4"},[_vm._v("\n    "+_vm._s(_vm.$t('components.uiComponents.signUp.beMoreThanEntertained'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('v-form',{ref:"form",class:{
      'd-flex full-width subscribe-form align-start': true,
      'row-layout': _vm.rowLayout,
    },attrs:{"aria-label":_vm.$t('components.uiComponents.signUp.signUpAriaLabel'),"id":"251d16-subscribeForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{class:{
        'input-container': true,
        'd-none': _vm.enableSimpleSignup,
      }},[_c('div',{},[_c('v-text-field',{class:{
            'email-input': true,
            filled: _vm.filled,
          },attrs:{"outlined":"","rounded":"","background-color":'transparent',"dense":_vm.dense || undefined,"color":'white',"aria-label":_vm.$t('pages.account.email'),"id":'games_footer_subscribe_email',"name":"subscribe_email","type":"email","placeholder":_vm.placeholderText
              ? _vm.placeholderText
              : _vm.$t('components.uiComponents.signUp.enterYourEmail'),"required":"","aria-required":"true","maxlength":"150","rules":_vm.rules.email},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('input',{attrs:{"id":"contact-list","type":"hidden","name":"contact-list","value":"general"}}),_vm._v(" "),_c('input',{attrs:{"id":"container","type":"hidden","name":"container","value":"sub-form-wrapper"}})],1),_vm._v(" "),_c('div',{staticClass:"form-checkboxes"},[_c('div',{staticClass:"mb-1"},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"auto","name":"accept_tc","aria-required":"true","required":"","rules":_vm.rules.tc},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"d-flex flex-wrap align-center promotional-agreement font--body-small g-text-primary",staticStyle:{"line-height":"1 !important"}},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'components.uiComponents.signUp.iAgreeWithLinkedNames[0]'
                  ))+" \n                "),_c('a',{staticClass:"font--body-small text-transform-none underline pa-0",staticStyle:{"min-width":"0","height":"auto"},attrs:{"href":"https://gala.com/legal/gala-privacy-policy","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'components.uiComponents.signUp.iAgreeWithLinkedNames[1]',
                      { name1: _vm.$t('common.misc.privacyPolicy') }
                    ))+"\n                ")]),_vm._v("\n                 "+_vm._s(_vm.$t(
                    'components.uiComponents.signUp.iAgreeWithLinkedNames[2]'
                  ))+" \n                "),_c('a',{staticClass:"font--body-small text-transform-none underline pa-0",staticStyle:{"min-width":"0","height":"auto"},attrs:{"href":"https://gala.com/legal/gala-games-terms-and-conditions","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'components.uiComponents.signUp.iAgreeWithLinkedNames[3]',
                      { name2: _vm.$t('common.misc.termsAndConditions') }
                    ))+"\n                ")])])]},proxy:true}]),model:{value:(_vm.subscribeTCsChecked),callback:function ($$v) {_vm.subscribeTCsChecked=$$v},expression:"subscribeTCsChecked"}})],1),_vm._v(" "),_c('div',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"auto","name":"accept_promotions","aria-required":"true","required":"","rules":_vm.rules.promo},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"promotional-agreement font--body-small align-self-center g-text-primary"},[_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t('components.uiComponents.signUp.iAgreeToReceive'))+"\n                ")]),_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t('components.uiComponents.signUp.iUnderstand'))+"\n                ")])])]},proxy:true}]),model:{value:(_vm.subscribePromotionsChecked),callback:function ($$v) {_vm.subscribePromotionsChecked=$$v},expression:"subscribePromotionsChecked"}})],1)])]),_vm._v(" "),_c('v-btn',{class:{
        'medium font-weight-regular text-body-1 rounded-pill': true,
        filled: _vm.filled,
        'mx-auto full-width': _vm.enableSimpleSignup,
      },staticStyle:{"text-transform":"none","font-weight":"400"},attrs:{"dark":"","depressed":"","color":'white',"outlined":_vm.btnStyle === 'white' ? true : undefined,"type":"submit","disabled":_vm.isSubscribing}},[_c('span',[_vm._v("\n        "+_vm._s(_vm.buttonLabel)+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }