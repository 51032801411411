import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Timer extends Vue {
  interval: any = null;
  expired = false;
  end = 0;
  totalSecondsInTimer = 0;
  totalSecondsRemaining = 0;
  remainingTime = [
    { unitName: 'days', value: 0 },
    { unitName: 'hours', value: 0 },
    { unitName: 'minutes', value: 0 },
    { unitName: 'seconds', value: 0 },
  ];

  get unitsToShow() {
    const greatestUnitIndex = this.remainingTime.findIndex(
      unit => unit.value > 0,
    );
    return this.remainingTime.slice(greatestUnitIndex);
  }

  get timerProgress() {
    const ratio = (this.totalSecondsRemaining / this.totalSecondsInTimer) * 100;
    return Math.floor(ratio);
  }

  beginTimer(endTime: number) {
    this.end = new Date(endTime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.end);
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  timerCount(end: number) {
    // Get todays date and time
    const now = new Date().getTime();

    // Find the distance between now an the count down date
    const distance = end - now;

    if (this.totalSecondsInTimer === 0) {
      this.totalSecondsInTimer = Math.floor(distance / 1000);
    }
    this.totalSecondsRemaining = Math.floor(distance / 1000);
    if (distance > 0) {
      this.calcTime(distance);
      return;
    } else {
      this.expired = true;
      clearInterval(this.interval);
      this.$emit('expired', true);
    }
  }

  calcTime(dist: number) {
    // Time calculations for days, hours, minutes and seconds
    this.remainingTime = [
      { unitName: 'days', value: Math.floor(dist / (1000 * 60 * 60 * 24)) },
      {
        unitName: 'hours',
        value: Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      },
      {
        unitName: 'minutes',
        value: Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)),
      },
      { unitName: 'seconds', value: Math.floor((dist % (1000 * 60)) / 1000) },
    ];
  }
}
