




























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import PrivacyPolicy from '~/components/Settings/PrivacyPolicy.vue';
import TermsAndConditions from '~/components/Settings/TermsAndConditions.vue';
import getNeededAgreements from '~/queries/neededAgreements.gql';
import acceptAgreementsMutation from '~/mutations/acceptAgreements.gql';
import LegalAgreements, {
  ILegalAgreementsPayload,
  defaultLegalAgreementsPayload,
} from '~/components/LegalAgreements/LegalAgreements.vue';
import { resetCache } from '~/utils';

@Component({
  components: {
    PrivacyPolicy,
    TermsAndConditions,
    FullScreenDialog,
    LegalAgreements,
  },
})
export default class AcceptTermsAndConditions extends Vue {
  @Prop({ type: Boolean, default: false }) skipCache!: boolean;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  isFetching = false;
  showModal = false;
  termsDialog = false;
  privacyPolicyDialog = false;
  termsAndConditionsNeeded = false;
  privacyPolicyNeeded = false;
  ipAddress = '';
  legalAgreementsPayload: ILegalAgreementsPayload = defaultLegalAgreementsPayload;

  get allRequiredDocumentsAccepted(): ILegalAgreementsPayload['allAccepted'] {
    return this.legalAgreementsPayload.allAccepted;
  }

  get documentsAccepted(): ILegalAgreementsPayload['documentsAccepted'] {
    return this.legalAgreementsPayload.documentsAccepted;
  }

  async created() {
    const { data } = await this.$apollo.query({
      query: getNeededAgreements,
      fetchPolicy: this.skipCache ? 'network-only' : 'cache-first',
    });
    const neededAgreements = data.neededAgreements || {};
    if (neededAgreements.termsAndConditions || neededAgreements.privacyPolicy) {
      this.termsAndConditionsNeeded = !!neededAgreements.termsAndConditions;
      this.privacyPolicyNeeded = !!neededAgreements.privacyPolicy;
      this.showModal = true;
    }
  }

  handleDocumentDialog(document: string) {
    if (document.toLowerCase() === 'privacy policy') {
      this.privacyPolicyDialog = true;
    } else {
      this.termsDialog = true;
    }
  }

  async handleContinue() {
    if (!this.allRequiredDocumentsAccepted) {
      return;
    }
    this.isFetching = true;
    try {
      const client = this.$apolloProvider.defaultClient;
      const { data } = await client.mutate({
        mutation: acceptAgreementsMutation,
        variables: {
          acceptedDocuments: this.documentsAccepted.map(
            ({ documentType, versionAccepted }) => ({
              documentType,
              versionAccepted,
            }),
          ),
        },
        refetchQueries: [
          {
            query: getNeededAgreements,
          },
        ],
      });
      if (data.acceptAgreements && data.acceptAgreements.success) {
        resetCache(client.cache, false, 'neededAgreements');
        this.showModal = false;
        this.$emit('agreements-accepted');
      } else {
        throw new Error('failed to save acceptance');
      }
    } catch (error) {
      this.$sentry.captureException(error);
      this.updateSnackbarErrorText(
        this.$t('components.home.acceptTermsAndConditions.errorMsg' as string),
      );
      this.toggleErrorSnackbar();
    }
    this.isFetching = false;
  }
}
