






























































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import ProfileProgressIcon from '~/components/Home/ProfileProgressIcon.vue';
import ReferAFriend from '~/components/Share/ReferralDialog.vue';
import ExternalWallet from '~/mixins/ExternalWallet';
import { CopyToClipboard } from '../../mixins';
import NotificationBell from '../SvgIcons/NotificationBell.vue';
import { IInventoryState } from '../../store/inventory/types';
import TextOutput from '~/mixins/TextOutput';

@Component({
  components: {
    ProfileProgressIcon,
    ReferAFriend,
    NotificationBell,
  },
})
export default class ProfileNavigationDrawer extends Mixins(
  ExternalWallet,
  CopyToClipboard,
  TextOutput,
) {
  @Prop(Boolean) drawerControl!: boolean;

  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State(shareState => shareState.nodesOwned, { namespace: 'share' })
  nodesOwned!: number;
  @State(profile => profile.user.goldMember, { namespace: 'profile' })
  readonly goldMember!: boolean;
  @State('inventory')
  inventoryState!: IInventoryState;

  @Mutation toggleReferralDialog!: (args: any) => void;

  @Action('userLogout', { namespace: 'profile' })
  private userLogout!: () => void;

  ethLogo = require(`@/assets/logos/ETH.png`);

  get darkMode() {
    return this.$vuetify.theme.dark;
  }

  get themeChangeText() {
    return this.darkMode ? 'Lights On' : 'Lights Off';
  }

  get profileWallets() {
    return (
      this.inventoryState.wallets?.filter(x =>
        ['GALA[ETH]', 'GALA[GYRI]', 'Ethereum'].includes(x.name),
      ) ?? []
    );
  }

  handleThemeChange() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  toggleReferralModal() {
    this.toggleReferralDialog(true);
    this.$emit('close');
  }

  @Watch('drawerControl')
  onDialogChange(val: boolean) {
    val === false && this.$emit('close');
  }

  async handleLogout() {
    await this.userLogout();
  }

  truncateAddress(address: string) {
    if (!address) return;

    const string = `${address.slice(0, 10)}....${address.substr(
      address.length - 4,
      address.length,
    )}`;

    return string;
  }
}
