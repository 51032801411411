


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { IGame } from '~/store/games/types';
import { IUser } from '~/store/profile/types';
import VerifyEmailToPlayModal from '~/components/EmailVerification/VerifyEmailToPlayModal.vue';

@Component({
  components: {
    VerifyEmailToPlayModal,
  },
})
export default class VerifyEmailToPlayBtn extends Vue {
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Prop(Object) readonly game!: IGame;
  @Prop(Boolean) readonly isCheckingAuth!: boolean;

  @Action('promptToRegister')
  private promptToRegister!: (options?: {
    redirectAfterLoginPath?: string;
  }) => void;

  isWindows: boolean = navigator.userAgent.includes('Win');
  isMac: boolean = navigator.userAgent.includes('Mac');
  displayVerifyEmailModal: boolean = false;

  async initAction() {
    const passedAuthValidation = await this.validateAuthRequirement();
    if (!passedAuthValidation) {
      // UA tracking
      this.$ua.trackGameActionErrorEvent({
        userAgent: navigator.userAgent,
        game: this.game?.codeName,
        error: 'FAILED_AUTH_VALIDATION_IN_VERIFY_EMAIL_TO_PLAY',
        isWindows: this.isWindows,
        isMac: this.isMac,
      });

      return;
    } else {
      this.displayVerifyEmailModal = true;
    }
  }

  async validateAuthRequirement() {
    if (!(await this.$auth.isLoggedIn())) {
      if (this.currentUser.loggedIn) {
        window.location.assign(
          `${window.location.origin}${this.$route.path}?register=1`,
        );
      } else {
        this.promptToRegister({ redirectAfterLoginPath: this.$route.fullPath });
      }
      return false;
    }
    return true;
  }
}
