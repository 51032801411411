import IpifyService from '~/services/ipify.service';
import { IUserAgentInfo } from '~/store/types';

export default async function getUserAgentInfo() {
  const results: IUserAgentInfo = {
    language: navigator.language,
    displayMode: 'browser',
  };

  // Check to see if app is loaded as pwa or in browser
  const mqStandAlone = '(display-mode: standalone)';
  // @ts-ignore
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    results.displayMode = 'standalone';
  }

  // try and get ip address using ipfy api; if blocked
  const ipAddress = await IpifyService.getIpAddress();

  if (ipAddress) {
    results.ip = ipAddress;
  }

  return results;
}
