import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { _slicedToArray } from '~/utils';
// You can declare a mixin as the same style as components.
@Component
export default class InViewport extends Vue {
  @Prop({ type: Boolean, default: true })
  inViewportActive!: boolean;
  @Prop({ type: Boolean, default: false })
  inViewportOnce!: boolean;
  @Prop({ type: String, default: '0px 0px -1px 0px' })
  inViewportRootMargin!: string;
  @Prop({ type: String, default: null })
  inViewportRoot!: string;
  @Prop({ type: Array, default: () => [0, 1] })
  inViewportThreshold!: number[];

  inViewport = {
    // Public props
    now: false,
    // Is in viewport
    fully: false,
    // Is fully in viewport
    above: false,
    // Is partially or fully above the viewport
    below: false,
    // Is partially or fully below the viewport
    // Internal props
    listening: false,
    maxThreshold: 1,
  };
  inViewportObserver: IntersectionObserver | null = null;

  mounted() {
    return this.$nextTick(this.inViewportInit);
  }

  destroyed() {
    return this.removeInViewportHandlers();
  }

  get inViewportThresholdWithMax() {
    if (this.inViewportThreshold.indexOf(this.inViewport.maxThreshold) >= 0) {
      return this.inViewportThreshold;
    } else {
      return this.inViewportThreshold.concat(this.inViewport.maxThreshold);
    }
  }

  reInitInViewportMixin() {
    this.removeInViewportHandlers();
    return this.inViewportInit();
  }

  // Instantiate
  inViewportInit() {
    if (this.inViewportActive) {
      return this.addInViewportHandlers();
    }
  }

  // Add listeners
  addInViewportHandlers() {
    // Don't add twice
    if (this.inViewport.listening) {
      return;
    }

    this.inViewport.listening = true; // Create IntersectionObserver instance

    this.inViewportObserver = new IntersectionObserver(this.updateInViewport, {
      root: document.querySelector(this.inViewportRoot),
      rootMargin: this.inViewportRootMargin,
      threshold: this.inViewportThresholdWithMax,
    }); // Start listening

    return this.inViewportObserver.observe(this.$el);
  }

  // Remove listeners
  removeInViewportHandlers() {
    if (!this.inViewport.listening) {
      return;
    }

    this.inViewport.listening = false; // Destroy instance, which also removes listeners

    if (this.inViewportObserver != null) {
      this.inViewportObserver.disconnect();
    }

    this.inViewportObserver = null;
  }

  // Handle state changes.  There should only ever be one entry and we're
  // destructuring the properties we care about since they have long names.
  updateInViewport(arg: IntersectionObserverEntry[]) {
    let root;
    let target;

    const _slice$call = [].slice.call(arg, -1);

    const _slice$call2 = _slicedToArray(_slice$call, 1);

    if (_slice$call2) {
      const _slice$call2$ = _slice$call2[0];
      target = _slice$call2$.boundingClientRect;
      root = _slice$call2$.rootBounds;
    }

    // Get the maximum threshold ratio, which is less than 1 when the
    // element is taller than the viewport. The height may be 0 when the
    // parent element is hidden.
    this.inViewport.maxThreshold =
      target.height > 0 ? Math.min(1, root.height / target.height) : 1; // Check if some part of the target is in the root box.  The isIntersecting
    // property from the IntersectionObserver was not used because it reports
    // the case where a box is immediately offscreen as intersecting, even
    // though no aprt of it is visible.

    this.inViewport.now = target.top <= root.bottom && target.bottom > root.top; // Calculate above and below.  The +1 on the bottom check co-incides with
    // the default root-margin which has a -1 on the bottom margin.

    this.inViewport.above = target.top < root.top;
    this.inViewport.below = target.bottom > root.bottom + 1; // Determine whether fully in viewport. The rules are different based on
    // whether the target is taller than the viewport.

    this.inViewport.fully =
      target.height > root.height
        ? target.top <= root.top && target.bottom >= root.bottom + 1
        : !this.inViewport.above && !this.inViewport.below;

    if (this.inViewportOnce && this.inViewport.now) {
      // If set to update "once", remove listeners if in viewport
      return this.removeInViewportHandlers();
    }
  }

  @Watch('inViewportActive')
  handleInViewportActive(active: boolean) {
    if (active) {
      return this.addInViewportHandlers();
    } else {
      return this.removeInViewportHandlers();
    }
  }
  @Watch('inViewportRootMargin')
  handleInViewportRootMargin() {
    return this.reInitInViewportMixin();
  }

  @Watch('inViewportRoot')
  handleInViewportRoot() {
    return this.reInitInViewportMixin();
  }

  @Watch('inViewportThresholdWithMax')
  handleInViewportThresholdWithMax(now: number[], old: number[]) {
    if (now.toString() !== old.toString()) {
      // In IE, this kept getting retriggered, so doing a manual comparison
      // of old and new before deciding whether to take action.
      return this.reInitInViewportMixin();
    }
  }
}
