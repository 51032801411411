





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import WalletConnectionErrorBanner from '../Home/WalletConnectionErrorBanner.vue';
import TriggerWalletCreationBtn from '../NewWallet/TriggerWalletCreationBtn.vue';
import {
  ICmsContentState,
  ISanityPromoPack,
} from '../../store/cms_content/types';

@Component({
  components: {
    WalletConnectionErrorBanner,
    TriggerWalletCreationBtn,
  },
})
export default class BannerNotification extends Vue {
  @Prop(Boolean) readonly showTopToolbarBannerCloseBtn!: boolean;
  @Prop(Boolean) readonly showWalletPrompt!: boolean;
  @Prop(Boolean) readonly showPromoPackPrompt!: boolean;

  @Mutation togglePromoPackDialog!: (args: {
    active: boolean;
    promoPack: ISanityPromoPack;
  }) => void;

  @Getter('showWalletConnectionErrorBanner', { namespace: 'web3Wallet' })
  readonly showWalletConnectionErrorBanner!: boolean;

  @State(
    (cmsContent: ICmsContentState) =>
      cmsContent.promoPacks.find(x => x.featured),
    {
      namespace: 'cmsContent',
    },
  )
  readonly promoPack!: ISanityPromoPack;

  trackPromoPackOpenEvent = () => {
    this.$ua.trackGalaPackShowOfferEvent({
      path: this.$route.path,
    });
  };

  closeTopToolbar() {
    localStorage.setItem('topToolbarBannerDismissed', Date.now().toString());
    this.$emit('close');
  }
}
