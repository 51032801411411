import { Component, Vue } from 'vue-property-decorator';
import MoonPayWidgetParams from '~/queries/moonPayWidgetParams.gql';
import { IDocumentsAccepted } from '../types/legal-documents';
import { getParsedCookie } from '../utils';

@Component
export default class MoonPay extends Vue {
  moonPay = (window as any).MoonPayWebSdk.init;

  async showMoonpay(
    productId: string,
    quantity: number,
    expectedPrice: number,
    totpToken: string,
    acceptedDocuments: IDocumentsAccepted,
    priceAdjustmentCode?: string,
  ) {
    // We construct this one just so we can call getSdkEnvironment()
    // on it and get some stuff that's needed for generating a signature
    const moonpaySdkWidget = this.moonPay({
      flow: 'nft',
      environment: process.env.moonPayEnvironment,
      variant: 'overlay' as any,
      debug: true,
    });

    const moonPaySdkInfo = moonpaySdkWidget.getSdkEnvironment();

    const widgetParamsResponse = await this.$apollo.query({
      query: MoonPayWidgetParams,
      variables: {
        productId,
        quantity,
        expectedPrice,
        acceptedDocuments: acceptedDocuments.map(
          ({ documentType, versionAccepted }) => ({
            documentType,
            versionAccepted,
          }),
        ),
        orderContext: getParsedCookie('utmVariables') || undefined,
        priceAdjustmentCode,
        moonPaySdkInfo,
        totpToken,
      },
    });

    const {
      __typename,
      ...params
    } = widgetParamsResponse.data.moonPayWidgetParams;

    const moonpayWidget = this.moonPay({
      flow: 'nft',
      environment: process.env.moonPayEnvironment,
      params,
      variant: 'overlay' as any,
      debug: true,
    });

    moonpayWidget.show();

    return moonpayWidget;
  }
}
