import { ActionTree } from 'vuex';
import BinanceW3WProvider from '@binance/w3w-ethereum-provider';
import {
  IW3wState,
  IConnectWalletMutationOptions,
  Network,
  IProviderRpcError,
  WalletConnectionErrorCode,
} from './types';
import { IRootState, IBaseGqlResponse } from '../types';
import connectWalletMutation from '~/mutations/connectWallet.gql';
import disconnectWalletMutation from '~/mutations/disconnectWallet.gql';
import EthAddressRiskAssessment from '~/queries/ethAddressRiskAssessment.gql';
import { WalletConnectionError } from '~/utils/walletConnectionError';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';

export const actions: ActionTree<IW3wState, IRootState> = {
  async validateWalletConnection({ commit, dispatch, state }, label: string) {
    if (!process.env.w3wConnectionEnabled) {
      const errorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.w3wDisabled',
      ) as string;
      throw new WalletConnectionError(
        errorMessage,
        WalletConnectionErrorCode.W3W_FUNCTIONALITY_DISABLED,
      );
    }
    const providerOption = state.providerOptions.find(
      option => option.label === label,
    );
    const provider = providerOption?.getInterface();
    if (!provider) {
      const errorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.missingProvider',
      ) as string;
      throw new WalletConnectionError(
        errorMessage,
        WalletConnectionErrorCode.MISSING_PROVIDER,
      );
    }

    if (providerOption?.label === 'binance wallet') {
      await (provider as BinanceW3WProvider).enable();
    } else {
      await provider.request?.({ method: 'eth_requestAccounts' });
    }
    const chainId = await provider.request?.({ method: 'eth_chainId' });
    state.lastChainId = +chainId;
    if (!state.provider) {
      // @ts-ignore
      provider.on('chainChanged', newChainId => {
        if (newChainId !== state.lastChainId) {
          state.lastChainId = newChainId;
          window.location.reload();
        }
      });
      // @ts-ignore
      provider.on('accountsChanged', () => {
        window.location.reload();
      });
    }

    commit('setWeb3Provider', provider);

    const validNetwork = (await dispatch('verifyNetwork', +chainId)) as boolean;

    if (!validNetwork) {
      const errorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.invalidNetwork',
      ) as string;
      throw new WalletConnectionError(
        errorMessage,
        WalletConnectionErrorCode.INVALID_NETWORK,
      );
    }

    const [address] = await state.web3Provider!.listAccounts();

    if (
      state.connectedExternalWallet.ethAddress &&
      state.connectedExternalWallet.ethAddress !== address
    ) {
      const errorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.addressMismatch',
        {
          linkedAddress: state.connectedExternalWallet.ethAddress.slice(-4),
          provider: label,
          providerAddress: address.slice(-4),
        },
      ) as string;
      throw new WalletConnectionError(
        errorMessage,
        WalletConnectionErrorCode.ADDRESS_MISMATCH,
      );
    }

    commit('setConnectedWallet', { address, label });
  },

  async connectWalletToApp(
    { commit, dispatch, state },
    {
      label,
      connectToUser,
      twoFaWrapper,
    }: {
      label: string;
      connectToUser?: boolean;
      twoFaWrapper: <TReturnType>(
        queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
        twoFaCheckpoint?: TwoFaCheckpoint,
      ) => Promise<TReturnType>;
    },
  ) {
    try {
      await dispatch('validateWalletConnection', label);

      const skipConnectToUser =
        state.connectedExternalWallet.ethAddress ===
          state.connectedWallet.address &&
        state.connectedExternalWallet.walletProvider ===
          state.connectedWallet.label;

      if (
        (skipConnectToUser && state.connectedExternalWallet.ethAddress) ||
        !connectToUser
      ) {
        const walletIsSafe = await dispatch('validateWalletAddressIsSafe');
        if (!walletIsSafe) {
          return {
            success: false,
            message: this.$i18n.t(
              'components.wallet.connectWeb3Wallet.errorMessages.riskyWallet',
            ),
          };
        }
      }

      if (connectToUser && !skipConnectToUser) {
        const response = await dispatch('connectWalletToUser', {
          twoFaWrapper,
        });
        if (!response.success) {
          commit('resetConnectedWallet');
          commit('resetWeb3Provider');
        }
        return response;
      }
      return { success: true };
    } catch (err) {
      this.$sentry.captureException(err);
      const message = await dispatch('handleWalletConnectionError', err);
      return {
        success: false,
        message,
      };
    }
  },

  async connectWalletToUser(
    { commit, state, dispatch },
    { twoFaWrapper }: { twoFaWrapper: any },
  ) {
    try {
      const { address, label } = state.connectedWallet;
      const signer = state.web3Provider!.getSigner(address);
      const timestamp = Date.now().toString();
      const signedContent = `Welcome to Gala Games. ${timestamp}`;
      const sig = await signer.signMessage(signedContent);
      const variables = {
        authData: { ethAddress: address, timestamp, signedContent, sig },
        walletProvider: label,
      };

      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;

        const { data } = await twoFaWrapper(async (totpToken: string) => {
          return client.mutate<
            IBaseGqlResponse<'connectWallet'>,
            IConnectWalletMutationOptions
          >({
            mutation: connectWalletMutation,
            variables: { ...variables, totpToken },
          });
        }, TwoFaCheckpoint.web3WalletConnection);

        if (data?.connectWallet) {
          const { success } = data.connectWallet;
          if (success) {
            try {
              await this.$auth.forceTokenRefresh();
            } catch (err) {
              this.$sentry.captureException(err);
            }
            dispatch('inventory/getWalletsData', null, { root: true });
            await dispatch('profile/refreshUser', null, { root: true });
          }
          return data.connectWallet;
        }
      }
    } catch (error) {
      this.$sentry.captureException(error);
      return {
        success: false,
        message: this.$i18n.t(
          'components.wallet.connectWeb3Wallet.errorMessages.unableToConnectToUser',
        ) as string,
      };
    }
  },

  async reestablishW3wConnection({ dispatch, state }) {
    const {
      ethAddress: externalWalletAddress,
      walletProvider: label,
    } = state.connectedExternalWallet;
    if (externalWalletAddress && !state.web3Provider) {
      try {
        const connectionToAppResponse = await dispatch('connectWalletToApp', {
          label,
          connectToUser: false,
        });

        if (!connectionToAppResponse.success) {
          throw new Error(connectionToAppResponse.message);
        }
      } catch (error) {
        this.$sentry.captureException(error);
        throw error;
      }
    } else if (externalWalletAddress) {
      try {
        await dispatch('validateWalletConnection', label);
        const walletIsSafe = await dispatch('validateWalletAddressIsSafe');
        if (!walletIsSafe) {
          const message = this.$i18n.t(
            'components.wallet.connectWeb3Wallet.errorMessages.riskyWallet',
          ) as string;
          throw new Error(message);
        }
      } catch (error) {
        const message = await dispatch('handleWalletConnectionError', error);
        this.$sentry.captureException(error);
        throw new Error(message);
      }
    }
  },

  async disconnectWalletFromUser(
    { commit, state, dispatch },
    {
      twoFaWrapper,
    }: {
      twoFaWrapper: <TReturnType>(
        queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
        twoFaCheckpoint?: TwoFaCheckpoint,
      ) => Promise<TReturnType>;
    },
  ) {
    try {
      const { address, label } = state.connectedWallet;
      const signer = state.web3Provider!.getSigner(address);
      const timestamp = Date.now().toString();
      const signedContent = `Disconnecting wallet from user. ${timestamp}`;
      const sig = await signer.signMessage(signedContent);
      const variables = {
        authData: { ethAddress: address, timestamp, signedContent, sig },
        walletProvider: label,
      };

      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;

        const { data } = await twoFaWrapper(async (totpToken: string) => {
          return client.mutate<
            IBaseGqlResponse<'disconnectWallet'>,
            IConnectWalletMutationOptions
          >({
            mutation: disconnectWalletMutation,
            variables: { ...variables, totpToken },
          });
        }, TwoFaCheckpoint.web3WalletConnection);

        if (data?.disconnectWallet) {
          const { success } = data.disconnectWallet;
          if (success) {
            try {
              await this.$auth.forceTokenRefresh();
            } catch (err) {
              this.$sentry.captureException(err);
            }
            dispatch('inventory/getWalletsData', null, { root: true });
            await dispatch('profile/refreshUser', null, { root: true });
            commit('resetWeb3Provider');
            commit('resetConnectedWallet');
          }
          return data.disconnectWallet;
        }
      }
    } catch (err) {
      this.$sentry.captureException(err);
      const error = err as IProviderRpcError;
      let message = 'Unable to disconnect wallet from user account';
      if (error?.code === 4001) {
        message = 'Action rejected by user in web3 provider';
      }
      return {
        success: false,
        message,
      };
    }
  },

  verifyNetwork({ commit, state }, networkId: Network): boolean {
    const isValidNetwork = networkId && networkId === state.expectedNetwork;
    if (!isValidNetwork) {
      const networkName = state.networks[networkId];
      const expectedNetworkName = state.networks[state.expectedNetwork];
      const invalidNetworkErrorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.invalidKnownNetwork',
        { network: networkName, expectedNetworkName },
      ) as string;
      const invalidUnknownNetworkErrorMessage = this.$i18n.t(
        'components.wallet.connectWeb3Wallet.errorMessages.invalidNetwork',
        { expectedNetworkName },
      ) as string;
      const errorMessageToUse = networkName
        ? invalidNetworkErrorMessage
        : invalidUnknownNetworkErrorMessage;

      throw new WalletConnectionError(
        errorMessageToUse,
        WalletConnectionErrorCode.INVALID_NETWORK,
      );
    }
    return true;
  },
  handleWalletConnectionError({ commit, state }, error: WalletConnectionError) {
    let message;
    switch (error.cause) {
      case WalletConnectionErrorCode.W3W_FUNCTIONALITY_DISABLED: {
        message = error.message;
        break;
      }
      case WalletConnectionErrorCode.MISSING_PROVIDER: {
        message = error.message;
        commit('setWalletConnectionError', {
          code: WalletConnectionErrorCode.MISSING_PROVIDER,
          message: ``,
          shortMessage: message,
        });
        break;
      }
      case WalletConnectionErrorCode.INVALID_NETWORK: {
        message = error.message;
        commit('setWalletConnectionError', {
          code: WalletConnectionErrorCode.INVALID_NETWORK,
          message,
          shortMessage: this.$i18n.t(
            'components.wallet.connectWeb3Wallet.errorMessages.invalidNetwork',
          ),
        });
        break;
      }
      case WalletConnectionErrorCode.ADDRESS_MISMATCH: {
        message = error.message;
        commit('setWalletConnectionError', {
          code: WalletConnectionErrorCode.ADDRESS_MISMATCH,
          message,
          shortMessage: this.$i18n.t(
            'components.wallet.connectWeb3Wallet.errorMessages.addressMismatchShort',
          ),
        });
        break;
      }
      default: {
        if (error?.code === 4001) {
          message = this.$i18n.t(
            'components.wallet.connectWeb3Wallet.errorMessages.connectionRejected',
          );
          error.cause = WalletConnectionErrorCode.MISSING_BROWSER_CONNECTION;
        } else {
          message = this.$i18n.t('common.notifications.somethingWentWrong');
          error.cause = WalletConnectionErrorCode.UNKNOWN;
        }

        commit('setWalletConnectionError', {
          code: WalletConnectionErrorCode.MISSING_BROWSER_CONNECTION,
          message: this.$i18n.t(
            'components.home.walletConnectionErrorBanner.missingBrowserConnection',
            {
              provider: state.connectedExternalWallet.walletProvider,
              lastFourOfAddress: state.connectedExternalWallet.ethAddress.slice(
                -4,
              ),
            },
          ),
          shortMessage: this.$i18n.t(
            'components.home.walletConnectionErrorBanner.missingBrowserConnectionShort',
          ),
        });
      }
    }
    if (message) {
      commit('toggleWalletConnectionError', true);
    }
    return message;
  },

  async validateWalletAddressIsSafe({ commit, state, dispatch }) {
    try {
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        await client.query({
          query: EthAddressRiskAssessment,
          fetchPolicy: 'cache-first',
        });
        return true;
      }
    } catch (error) {
      const e = error as Error;
      if (e.message.includes('HIGH_RISK_WALLET')) {
        // If we make it here, the address is risky and has been disconnected from the user on the backend
        // that means we need to refresh auth token and get up-to-date data about the user's wallet
        try {
          await this.$auth.forceTokenRefresh();
        } catch (err) {
          this.$sentry.captureException(err);
        }
        dispatch('inventory/getWalletsData', null, { root: true });
        await dispatch('profile/refreshUser', null, { root: true });
        commit('resetWeb3Provider');
        commit('resetConnectedWallet');

        commit(
          'updateSnackbarErrorText',
          this.$i18n.t(
            'components.wallet.connectWeb3Wallet.errorMessages.riskyWallet',
          ) as string,
          { root: true },
        );
        commit('toggleErrorSnackbar', null, { root: true });
        return false;
      }
      return true;
    }
  },
};
