import { Context } from '@nuxt/types';

export default function(context: Context) {
  const { route, from, store } = context;
  const previousPage = {
    path: from.path,
    sameAsCurrent: route.path === from.path,
  };

  store.commit('updatePreviousPage', previousPage);
}
