interface IContext {
  $nuxtI18nHead: (args: { addSeoAttributes: true }) => any;
  $t: (key: string) => string;
  $te: (key: string) => boolean;
}

function buildHead(
  context: IContext,
  options: {
    ogTitleKey?: string;
    ogDescriptionKey?: string;
    htmlDescriptionKey?: string;
  } = {},
) {
  const i18nHead = context.$nuxtI18nHead({ addSeoAttributes: true });

  const localizedMetaTags = [
    {
      hid: 'description',
      name: 'description',
      content: context.$t(
        options.htmlDescriptionKey ?? 'common.htmlMeta.htmlDescription',
      ),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: context.$t(options.ogTitleKey ?? 'common.htmlMeta.ogTitle'),
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: context.$t(
        options.htmlDescriptionKey ?? 'common.htmlMeta.htmlDescription',
      ),
    },
  ];

  return {
    ...i18nHead,
    meta: [...i18nHead.meta, ...localizedMetaTags],
  };
}

export function intlHead(
  options: {
    ogTitleKey?: string;
    ogDescriptionKey?: string;
    htmlDescriptionKey?: string;
  } = {},
) {
  return function(this: any) {
    return buildHead(this, options);
  };
}

const defaultGameMetaKeys = {
  ogTitleKey: 'pages.games.htmlMeta.ogTitle',
  ogDescriptionKey: 'pages.games.htmlMeta.htmlDescription',
  htmlDescriptionKey: 'pages.games.htmlMeta.htmlDescription',
};

export function gamesIntlHead() {
  return function(this: any) {
    return buildHead(this, defaultGameMetaKeys);
  };
}

export function namedGameIntlHead() {
  return function(this: any) {
    const gameName = this.$nuxt.$route.params.name;
    const ogTitleKey = `pages.games.htmlMeta.games.${gameName}.ogTitle`;
    const ogDescriptionKey = `pages.games.htmlMeta.games.${gameName}.ogDescription`;

    // If this key exists in our locale file, use that meta.
    if (this.$te(ogTitleKey)) {
      return buildHead(this, {
        ogTitleKey,
        ogDescriptionKey,
        htmlDescriptionKey: ogDescriptionKey,
      });
    } else {
      // Otherwise, fall back to the default games page meta
      return buildHead(this, defaultGameMetaKeys);
    }
  };
}
