import { Context } from '@nuxt/types';

export default async function(context: Context) {
  const { env, store, params, redirect } = context;

  const game = store.state.games.games.find(
    (g: any) => g.route === params.name,
  );

  if (!game) {
    return redirect('/');
  }

  if (!game.isReleased && !game.isReleasedInternally) {
    return redirect(`/games/${game.route}`);
  }

  if (!env.displayGameInIframe) {
    await store.dispatch('profile/getUserData');

    return redirect(`${game.playLink}#${store.state.profile.user.gameToken}`);
  }
}
