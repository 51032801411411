import axios from 'axios';

class CryptocompareService {
  baseUrl = 'https://min-api.cryptocompare.com/data';
  async getPrice(symbols: string[], target: string) {
    try {
      const uniqueSymbols = symbols
        .map(x => x.replace(/\[(.*?)\]/, ''))
        .filter((value, index, array) => array.indexOf(value) === index);
      const results = await axios.get(
        `${this.baseUrl}/pricemulti?fsyms=${uniqueSymbols.join(
          ',',
        )}&tsyms=${target}`,
      );
      return results.data;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getPriceMulti(symbols: string[], target: string) {
    try {
      const { data } = await axios.get(`${this.baseUrl}/pricemultifull`, {
        params: {
          fsyms: symbols.join(','),
          tsyms: target,
        },
      });
      return data.RAW;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getHourlyHistoricalPrice(
    symbol: string,
    target: string,
    timestamp: number,
  ) {
    try {
      const { data } = await axios.get(
        `${this.baseUrl}/v2/histohour?fsym=${symbol}&tsym=${target}&toTs=${timestamp}&limit=1`,
      );
      if (data && data.Data && data.Data.Data && data.Data.Data[1]) {
        return data.Data.Data[1];
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  async getHistoricalData(coin: string, interval: string) {
    try {
      // e.g. "BTC", "1W"
      // interval should be "24hr" || "1w" || "1m" || "1y"
      const validIntervals = ['1d', '1w', '1m', '1y', 'all'];
      if (!validIntervals.includes(interval)) {
        throw new Error(`invalid interval input, got ${interval}`);
      }
      const baseURL = 'https://min-api.cryptocompare.com/data/v2';
      let URLExtension = '';
      switch (interval) {
        case '1d':
          URLExtension = `/histohour?fsym=${coin}&tsym=USD&limit=24`;
          break;
        case '1w':
          URLExtension = `/histohour?fsym=${coin}&tsym=USD&limit=168`; // hourly intervals
          break;
        case '1m':
          URLExtension = `/histohour?fsym=${coin}&tsym=USD&aggregate=6&limit=120`; // 6 hr intervals
          break;
        case '1y':
          URLExtension = `/histoday?fsym=${coin}&tsym=USD&limit=182&aggregate=2`; // 2 day intervals
          break;
        case 'all':
          URLExtension = `/histoday?fsym=${coin}&tsym=USD&allData=true&aggregate=7`;
          break;
        default:
          URLExtension = `/histohour?fsym=${coin}&tsym=USD&limit=24`;
      }
      const historicalData = await axios({
        method: 'GET',
        url: baseURL + URLExtension,
      }).then(res => res.data.Data.Data);
      // historicalData = array of time points with time, close, high, low, open, volumnefrom, volumeto
      // generate labels and datasets
      const chartData: any = {
        labels: [],
        datasets: [
          {
            label: coin,
            borderColor: '#fff',
            fill: false,
            borderWidth: 1,
            lineTension: 0, // make straight lines between data points
            data: [], // close value
            volume: [], // volume in USD for period
          },
        ],
      };
      historicalData.forEach((data: any) => {
        chartData.labels.push(getDateFromUTCSeconds(data.time));
        chartData.datasets[0].data.push(data.close);
        chartData.datasets[0].volume.push(data.volumeto);
      });
      function getDateFromUTCSeconds(UTCSeconds: number) {
        // date should be in format 'Jan 18' etc.
        const d = new Date(0);
        d.setUTCSeconds(UTCSeconds);
        // const formattedDate = d.toUTCString().split(' ').slice(1,3).reverse().join(' ')
        const formattedDate = d.toUTCString();
        return formattedDate;
      }
      return chartData;
    } catch (error) {
      console.log(error);
    }
  }
}
export default new CryptocompareService();
