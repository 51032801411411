import { Module } from 'vuex';
import { ICustomReveal } from './types';
import { IRootState } from '../types';

const customReveal: Module<ICustomReveal, IRootState> = {
  namespaced: true,
  state: {
    currentVideoState: 'loading',
    videoBoxType: 'bronze',
    shouldLoop: false,
    totalCards: 0,
    loaded: [],
    revealed: [],
    showCards: false,
    videoReady: false,
    skipAnimation: false,
  },
  getters: {
    currentVideoState: state => {
      return state.currentVideoState;
    },
    videoReady: state => {
      return state.videoReady;
    },
    videoBoxType: state => {
      return state.videoBoxType;
    },
    loop: state => {
      return state.shouldLoop;
    },
    cardsLoaded(state) {
      return state.loaded;
    },
    cardsRevealed(state) {
      return state.revealed;
    },
    totalCards(state) {
      return state.totalCards;
    },
    areCardsLoaded(state) {
      return state.loaded.length === state.totalCards;
    },
    areCardsRevealed(state) {
      return state.revealed.length === state.totalCards;
    },
    shouldShowCards(state) {
      return state.showCards;
    },
    shouldSkipAnimation(state) {
      return state.skipAnimation;
    },
  },
  actions: {
    async loadCard({ commit, state }, val: string) {
      if (!state.loaded.includes(val)) {
        commit('updateLoadedCards', val);
      }
    },
    async revealCard({ commit, state }, val: string) {
      if (!state.revealed.includes(val)) {
        commit('updateRevealedCards', val);
      }
    },
    async setTotalCards({ commit }, val: number) {
      commit('updateTotalCards', val);
    },
    async setShowCards({ commit }, val: boolean) {
      commit('updateShowCards', val);
    },
    async setVideoBoxType({ commit }, val: string) {
      commit('updateVideoBoxType', val);
    },
    async setVideoState({ commit }, val: string) {
      commit('updateVideoState', val);
    },
    async setVideoReady({ commit }, val: string) {
      commit('updateVideoReady', val);
    },
    async setSkipAnimation({ commit }, val: boolean) {
      commit('updateSkipAnimation', val);
    },
    async resetCustomReveal({ commit }) {
      commit('$reset');
    },
  },
  mutations: {
    updateVideoState(state, val: string) {
      state.currentVideoState = val;
    },
    updateVideoReady(state, val: boolean) {
      state.videoReady = val;
    },
    updateVideoBoxType(state, val: string) {
      state.videoBoxType = val;
    },
    updateLoop(state, val: boolean) {
      state.shouldLoop = val;
    },
    updateTotalCards(state, val: number) {
      state.totalCards = val;
    },
    updateLoadedCards(state, item: string) {
      state.loaded.push(item);
    },
    updateRevealedCards(state, item: string) {
      state.revealed.push(item);
    },
    updateShowCards(state, value: boolean) {
      state.showCards = value;
    },
    updateSkipAnimation(state, value: boolean) {
      state.skipAnimation = value;
    },
    $reset(state) {
      state.loaded = [];
      state.revealed = [];
      state.showCards = false;
      state.currentVideoState = 'loading';
      state.shouldLoop = false;
      state.totalCards = 0;
      state.videoReady = false;
      state.skipAnimation = false;
    },
  },
};

export default customReveal;
