var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"share-buttons"},[_c('v-row',{class:{
      'flex-column flex-sm-row align-center justify-center mt-n2 mb-2': _vm.isModal,
    }},[(_vm.nativeShareSupported && _vm.inviteNativeShareText)?_c('button',{staticClass:"py-2 copy-invitation-btn",class:{
        'gala-btn': true,
        'modal-style': _vm.isModal,
        'clear light outline': !_vm.isModal,
        'px-2 mb-n1': _vm.$vuetify.breakpoint.smAndUp,
        'd-block': _vm.$vuetify.breakpoint.xs,
      },on:{"click":_vm.triggerNativeShare}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("\n        mdi-cellphone-iphone\n      ")]),_vm._v("\n      "+_vm._s(_vm.$t('components.share.shareButtons.shareOnDevice'))+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.inviteCopyText && !_vm.nativeShareSupported)?_c('button',{staticClass:"copy-invitation-btn py-2",class:{
        'gala-btn': true,
        'modal-style': _vm.isModal,
        'clear light outline': !_vm.isModal,
        'px-2 mb-n1': _vm.$vuetify.breakpoint.smAndUp,
        'd-block': _vm.$vuetify.breakpoint.xs,
      },on:{"click":function($event){return _vm.copyToClipboard(_vm.inviteCopyText)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("\n        mdi-content-copy\n      ")]),_vm._v("\n      "+_vm._s(_vm.$t('components.share.shareButtons.copyInvitation'))+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.shareUrl)?_c('button',{staticClass:"py-2",class:{
        'gala-btn': true,
        'modal-style': _vm.isModal,
        'clear light outline': !_vm.isModal,
        'px-2 mb-n1': _vm.$vuetify.breakpoint.smAndUp,
        'd-block': _vm.$vuetify.breakpoint.xs,
      },on:{"click":function($event){return _vm.copyToClipboard(_vm.shareUrl)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("\n        mdi-xml\n      ")]),_vm._v("\n      "+_vm._s(_vm.$t('components.share.shareButtons.copyReferralUrl'))+"\n    ")],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }