




















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import BrandCard from '~/components/BrandCard.vue';
import TwoFaModalContent from '~/components/Authentication/TwoFaModalContent.vue';

@Component({
  components: { BrandCard, TwoFaModalContent },
})
export default class TwoFactorAuthPrompt extends Vue {
  @Prop({ type: Boolean })
  readonly value!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly inProgress!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly softHide!: boolean;

  twoFaPin = '';

  @Emit('input')
  handleDialogInput(value: boolean) {
    return value;
  }

  @Emit('submit')
  handleTwoFaSubmit(twoFaPin: string) {
    return this.twoFaPin;
  }

  @Watch('value')
  handleDialogIsOpenChanged() {
    this.twoFaPin = '';
  }
}
