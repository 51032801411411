














import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { intlHead } from '~/utils/i18n_head';

@Component({
  head: intlHead(),
})
export default class ExternalLayout extends Vue {
  @State('snackbarErrorText') snackbarErrorText!: boolean;
  @State('showSuccessSnackbar') showSuccessSnackbarState!: boolean;
  @State('showErrorSnackbar') showErrorSnackbarState!: boolean;

  @Mutation('toggleSuccessSnackbar')
  private toggleSuccessSnackbar!: (args: any) => void;
  @Mutation('toggleErrorSnackbar')
  private toggleErrorSnackbar!: (args: any) => void;

  get showSuccessSnackbar() {
    return this.showSuccessSnackbarState;
  }
  set showSuccessSnackbar(value) {
    this.toggleSuccessSnackbar(value);
  }
  get showErrorSnackbar() {
    return this.showErrorSnackbarState;
  }
  set showErrorSnackbar(value) {
    this.toggleErrorSnackbar(value);
  }
}
