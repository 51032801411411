


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Checkbox extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;
  @Prop(String) readonly ariaLabel!: string;

  get localValue() {
    return this.value;
  }

  set localValue(isChecked: boolean) {
    this.$emit('input', isChecked);
  }

  toggleChecked() {
    if (this.disabled) return;
    this.localValue = !this.localValue;
  }
}
