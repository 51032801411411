import { ActionTree } from 'vuex';
import { INotificationsState, INewNotifications } from './types';
import { IRootState } from '../types';
import newNotificationsQuery from '~/queries/notifications/newNotifications.gql';

export const actions: ActionTree<INotificationsState, IRootState> = {
  async getNewNotifications({ commit }) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.clients.gateway;
      const results = (await client.query({
        query: newNotificationsQuery,
        fetchPolicy: 'network-only',
      })) as { data: { newNotifications: INewNotifications } };

      commit('setNewNotifications', results.data.newNotifications);
    }
  },
};
