import { TAdaptor } from '../types';
import debugAdaptor from './debugAdaptor';
import activeCampaignAdaptor from './activeCampaignAdaptor';
import galaAnalyticsAdaptor from './galaAnalyticsAdaptor';
import addShoppersAdaptor from './addShoppersAdaptor';

interface IAllAdaptors extends Array<TAdaptor> {}
const allAdaptors: IAllAdaptors = [
  debugAdaptor,
  activeCampaignAdaptor,
  galaAnalyticsAdaptor,
  addShoppersAdaptor,
];

export {
  debugAdaptor,
  activeCampaignAdaptor,
  galaAnalyticsAdaptor,
  addShoppersAdaptor,
};

export default allAdaptors;
