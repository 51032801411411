
































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import NoticeDialog from '~/components/UIComponents/NoticeDialog.vue';
import { CheckForRequiredAllowances } from '~/mixins';
import Restrictions from '~/mixins/Restrictions';
import GameLaunchDialog from './GameLaunchDialog.vue';
import GiveLockAllowanceComponent from '~/components/ModalPrompts/GiveLockAllowance.vue';
import { GamePlatform, IGame } from '~/store/games/types';
import { IUser } from '~/store/profile/types';
import VerifyEmailToPlayBtn from '~/components/EmailVerification/VerifyEmailToPlayBtn.vue';

@Component({
  components: {
    GiveLockAllowanceComponent,
    NoticeDialog,
    GameLaunchDialog,
    VerifyEmailToPlayBtn,
  },
})
export default class GameActionButton extends Mixins(
  CheckForRequiredAllowances,
  Restrictions,
) {
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;
  @State(profile => profile.user.emailVerified, { namespace: 'profile' })
  emailVerified!: boolean;
  @State(profile => profile.user.email, { namespace: 'profile' })
  email!: boolean;

  @Prop(Object) readonly game!: IGame;
  @Prop({ type: String, default: 'medium-font' })
  readonly largeScreenFontSize!: IGame;

  @Prop({ type: Boolean, default: false })
  includeGameNameOnButton!: boolean;

  @Prop(Boolean) readonly lowercaseText!: boolean;

  @Action('addActiveUser', { namespace: 'games' })
  public addActiveUser!: (data: {
    gameName: string;
    cacheTtl?: number;
  }) => Promise<void>;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;
  @Action('promptToRegister')
  private promptToRegister!: (options?: {
    redirectAfterLoginPath?: string;
  }) => void;
  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (payload: {
    show?: boolean;
    walletType?: string;
  }) => void;

  isDisabled: boolean = false;
  showGiveLockAllowanceDialog: boolean = false;
  winIcon: any = require('@/assets/logos/win-logo.png');
  macIcon: any = require('@/assets/logos/mac-logo.png');
  displayIcon: any = null;
  isWindows: boolean = navigator.userAgent.includes('Win');
  isMac: boolean = navigator.userAgent.includes('Mac');
  qualified = false;

  showGameLaunchDialog: boolean = false;

  shouldShowModal: boolean = false;
  showVerifyEmailToPlayBtn: boolean = true;
  isCheckingAuth: boolean = true;

  get isEmailVerificationRequired() {
    if (this.game.requireEmailVerification) {
      return true;
    }

    return false;
  }

  async created() {
    this.isDisabled = false;
    if (
      this.currentUser.isInBlockedCountry &&
      this.currentUser.shouldBlockGameplay
    ) {
      this.isDisabled = true;
    }

    if (this.game.hasRestrictionsToPlay && this.game.restrictionId) {
      this.qualified = await this.isQualified(this.game.restrictionId, {});
    } else {
      this.qualified = true;
    }

    if (this.isEmailVerificationRequired) {
      this.setShowVerifyEmailToPlayBtn();
    } else {
      this.isCheckingAuth = false;
      this.showVerifyEmailToPlayBtn = false;
    }
  }

  destroyed() {}

  async setShowVerifyEmailToPlayBtn() {
    if (
      !(await this.$auth.isLoggedIn()) ||
      (this.email && !this.emailVerified && this.emailVerified !== undefined)
    ) {
      this.showVerifyEmailToPlayBtn = true;
    } else {
      this.showVerifyEmailToPlayBtn = false;
    }

    this.isCheckingAuth = false;
  }

  handleCloseModal() {
    const dateExpiry = new Date().valueOf() + 24 * 60 * 60 * 1000;
    localStorage.setItem('countryRestrictionPopup', dateExpiry.toString());
    this.shouldShowModal = false;
    this.initAction();
  }

  show() {
    if (!this.qualified) {
      this.$emit('updateGameActionButtonVisiblility', false);
      return false;
    }

    let showButton = false;

    if (
      this.game.platform.includes(GamePlatform.Browser) &&
      this.game.isReleased &&
      this.game.playLink
    ) {
      showButton = true;
    } else if (
      (this.game.platform.includes(GamePlatform.Win) ||
        this.game.platform.includes(GamePlatform.Mac)) &&
      this.game.isReleased
    ) {
      if (
        (this.isWindows && this.game.isWinReleased) ||
        (this.isMac && this.game.isMacReleased)
      ) {
        showButton = true;
      }
    }

    this.$emit('updateGameActionButtonVisiblility', showButton);

    return showButton;
  }

  async doAction() {
    if (!this.qualified) return;

    try {
      this.showGiveLockAllowanceDialog = false;
      this.isDisabled = true;

      if (this.game.platform.includes(GamePlatform.Browser)) {
        if (process.env.displayGameInIframe) {
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
          } else if ((document.body as any).webkitRequestFullscreen) {
            (document.body as any).webkitRequestFullscreen();
          }
        }

        // UA tracking
        this.$ua.trackGamePlayBeginEvent({
          userAgent: navigator.userAgent,
          game: this.game?.codeName,
          platform: GamePlatform.Browser,
          isWindows: this.isWindows,
          isMac: this.isMac,
        });

        if (this.game?.codeName && !this.game.activePlayerCountInDataBricks) {
          this.addActiveUser({
            gameName: this.game.codeName,
            cacheTtl: this.game.timePlayerIsActive,
          });
        }

        this.$router.push(`/games/${this.game.route}/play`);
      } else if (
        this.game.platform.includes(GamePlatform.Win) ||
        this.game.platform.includes(GamePlatform.Mac)
      ) {
        // All game launch logic is now driven from within the game launch dialog
        this.showGameLaunchDialog = true;
      }
    } catch (err) {
      this.$ua.trackGameActionErrorEvent({
        userAgent: navigator.userAgent,
        game: this.game?.codeName,
        error: err,
        isWindows: this.isWindows,
        isMac: this.isMac,
      });
    }
  }

  async processAllowances() {
    this.showGiveLockAllowanceDialog = false;

    const requiredAllowances = await this.checkAllowanceRequirement(this.game);

    if (requiredAllowances.needsLockAllowance) {
      if (!this.hasGyriPassphrase) {
        this.toggleCreateWalletPrompt({ show: true, walletType: 'gyri' });
        return;
      }

      this.showGiveLockAllowanceDialog = true;
      return;
    }

    this.showGiveLockAllowanceDialog = false;

    this.doAction();
  }

  async initAction() {
    const passedAuthValidation = await this.validateAuthRequirement();
    if (!passedAuthValidation) {
      // UA tracking
      this.$ua.trackGameActionErrorEvent({
        userAgent: navigator.userAgent,
        game: this.game?.codeName,
        error: 'FAILED_AUTH_VALIDATION',
        isWindows: this.isWindows,
        isMac: this.isMac,
      });

      return;
    }

    if (
      this.currentUser.isInBlockedCountry &&
      this.currentUser.shouldBlockGameplay
    ) {
      // UA tracking
      this.$ua.trackGameActionErrorEvent({
        userAgent: navigator.userAgent,
        game: this.game?.codeName,
        error: 'USER_IS_IN_BLOCKED_COUNTRY',
        isWindows: this.isWindows,
        isMac: this.isMac,
      });

      return;
    }

    const dateExpiry = localStorage.getItem(
      'countryRestrictionPopup',
    ) as string;
    // This checks if a user already saw the popup within the last 24 hours
    if ((dateExpiry && new Date(dateExpiry) < new Date()) || !dateExpiry) {
      if (
        this.currentUser.isInBlockedCountry &&
        !this.currentUser.shouldBlockGameplay
      ) {
        return (this.shouldShowModal = true);
      }
    }

    try {
      this.isDisabled = true;

      await this.processAllowances();
    } catch (error) {
      // UA tracking
      this.$ua.trackGameActionErrorEvent({
        userAgent: navigator.userAgent,
        game: this.game?.codeName,
        error,
        isWindows: this.isWindows,
        isMac: this.isMac,
      });

      console.error(error);
      this.isDisabled = false;
      this.showErrorSnackbar();
    }
  }

  cancel() {
    this.showGiveLockAllowanceDialog = false;
    this.isDisabled = false;
  }

  hideGameLaunchDialog() {
    this.showGameLaunchDialog = false;
    this.isDisabled = false;
  }

  showErrorSnackbar(message = 'Something went wrong.') {
    this.updateSnackbarErrorText(message);
    this.toggleErrorSnackbar(true);
  }

  playReadyButtonText() {
    return this.includeGameNameOnButton
      ? (this.$t('components.games.gameActionButton.playGame', {
          gameName: this.game.displayName,
        }) as string)
      : (this.$t('components.games.gameActionButton.play') as string);
  }

  buttonText() {
    let text = this.playReadyButtonText();
    this.displayIcon = null;
    return text;
  }

  async validateAuthRequirement() {
    if (this.game.requireAuthToPlay && !(await this.$auth.isLoggedIn())) {
      if (this.currentUser.loggedIn) {
        window.location.assign(
          `${window.location.origin}${this.$route.path}?register=1`,
        );
      } else {
        this.promptToRegister({ redirectAfterLoginPath: this.$route.fullPath });
      }
      return false;
    }
    return true;
  }

  versionCompare(
    v1: any,
    v2: any,
    options?: { lexicographical: boolean; zeroExtend: boolean },
  ) {
    let lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split('.'),
      v2parts = v2.split('.');

    const isValidPart = (x: any) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    };

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push('0');
      while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }

      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }
}
