








































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import sendVerifyEmailMutation from '~/mutations/sendVerifyEmail.gql';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import validator from 'validator';
import AuthQuery from '~/mixins/AuthQuery';
import { UpdateUserAction } from '~/store/profile/actions';
import {
  getFromBrowserStorage,
  LocalStorageKeys,
  setInBrowserStorage,
} from '~/utils/browserStorage';

@Component({
  components: { FullScreenDialog },
})
export default class VerifyEmailAddress extends Mixins(AuthQuery) {
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.emailVerified, { namespace: 'profile' })
  emailVerified!: boolean;
  @State(profile => profile.user.email, { namespace: 'profile' })
  email!: string;

  newEmail = '';

  @Action('updateUser', { namespace: 'profile' })
  private updateUser!: UpdateUserAction;

  @Mutation('updateShowSettingsPage')
  private updateShowSettingsPage!: (args: any) => void;

  modalControl = true;
  sending = false;
  sent = false;
  sendError = '';
  isUserSettingUp2FA = true;
  delayShow = false;

  get showModal() {
    const result =
      this.modalControl &&
      this.loggedIn &&
      this.email &&
      !this.emailVerified &&
      this.emailVerified !== undefined &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'register' &&
      !this.isUserSettingUp2FA &&
      !this.delayShow;

    console.info('ℹ️ VerifyEmailAddress showModal', {
      modalControl: this.modalControl,
      loggedIn: this.loggedIn,
      email: this.email,
      emailVerified: this.emailVerified,
      routeName: this.$route.name,
      isUserSettingUp2FA: this.isUserSettingUp2FA,
      delayShow: this.delayShow,

      result,
    });

    return result;
  }

  created() {
    if (this.$route.query.component !== 'TwoFa') {
      this.isUserSettingUp2FA = false;
    }

    const delay = getFromBrowserStorage<number>(
      LocalStorageKeys.hideEmailVerification,
    );

    console.info('ℹ️ VerifyEmailAddress created', {
      delay,
      now: Date.now(),
      result: delay && delay > Date.now(),
    });

    if (delay && delay > Date.now()) {
      this.delayShow = true;
    }
  }

  async resendVerificationEmailClicked() {
    try {
      this.sending = true;

      const { data } = await this.$apollo.mutate({
        mutation: sendVerifyEmailMutation,
      });

      this.sending = false;

      if (data.sendVerifyEmail && data.sendVerifyEmail.success) {
        // UA tracking
        this.$ua.trackSendEmailVerificationEmailEvent();

        this.sent = true;
      } else {
        throw new Error('failed to save resend email');
      }
    } catch (error) {
      this.$sentry.captureException(error);
      this.sending = false;
      this.sendError = this.$t(
        'components.emailVerification.verifyEmailAddress.sendError',
      ) as string;
    }
  }

  validEmailAddress(val: string) {
    if (!val || val === '') {
      return this.$t('components.settings.verifyEmail.enterValidAddressMsg');
    }

    return (
      validator.isEmail(val) ||
      this.$t('components.settings.verifyEmail.enterValidAddressMsg')
    );
  }

  async updateAndSendVerificationEmailClicked() {
    try {
      this.sending = true;

      await this.doAuthQuery(async totpToken => {
        await this.updateUser({ email: this.newEmail, totpToken });
      });

      this.sending = false;

      this.$ua.trackSendEmailVerificationEmailEvent();

      this.sent = true;
    } catch (error) {
      this.$sentry.captureException(error);
      this.sending = false;
      this.sendError = this.$t(
        'components.emailVerification.verifyEmailAddress.sendError',
      ) as string;
    }
  }

  handleUpdateEmail() {
    this.modalControl = false;
    this.updateShowSettingsPage('email');
    this.$router.push('/account');
  }

  @Watch('modalControl')
  resetState(val: boolean, oldVal: boolean) {
    if (oldVal === true && val === false) {
      this.sending = false;
      this.sent = false;
      this.sendError = '';
    }
  }

  @Watch('showModal')
  setModalDelay(value: boolean) {
    if (value === false) {
      setInBrowserStorage(
        LocalStorageKeys.hideEmailVerification,
        (Date.now() + 1000 * 60 * 60 * 24).toString(), // Hide for 1 day
      );
    }
  }
}
