import { Context } from '@nuxt/types';

export default async function(context: Context) {
  const { store } = context;
  const ipLocation = await store.dispatch('profile/getIpLocation');

  await context.store.dispatch('cmsContent/loadCmsContent', {
    i18n: context.i18n,
    sentry: context.$sentry,
    ipLocation,
  });
}
