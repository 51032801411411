var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fullscreen-dialog d-flex flex-column",class:{ secondary: _vm.mobile }},[(_vm.mobile)?_c('div',{staticClass:"d-flex toolbar align-center",class:_vm.color},[(!_vm.hideBackArrow)?_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(_vm._s(_vm.closeIcon))])],1):_vm._e(),_vm._v(" "),_c('h1',{class:{
        'dialog-title text-capitalize white--text': true,
        centered: !_vm.showBalance,
      }},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_vm._t("balance")],2):_vm._e(),_vm._v(" "),_c('div',{class:{
      'content-container': _vm.contained,
      'py-3 px-2': !_vm.noPadding,
      'no-top-padding': !_vm.topPadding,
      'slot-container flex-grow-1': true,
      'd-flex align-center justify-center': !_vm.mobile,
    }},[_c('div',{class:( _obj = {}, _obj[(_vm.color + " mb-16 pa-6 desktop-container")] = !_vm.mobile, _obj )},[(!_vm.mobile && !_vm.hideBackArrow)?_c('v-icon',{staticClass:"close-icon",attrs:{"size":"32"},on:{"click":_vm.closeDialog}},[_vm._v("\n        close\n      ")]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("example-disclaimer")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }