export const galaSiteLinks = [
  {
    logo: 'https://static.gala.games/images/logos/gala-cube-logo-white.svg',
    highlightColor: '#FF00A5',
    title: 'data.galaSiteLinks.musicTitle',
    tagline: 'data.galaSiteLinks.musicTagline',
    mobileTagline: 'data.galaSiteLinks.musicMobileTagline',
    background: 'https://static.gala.games/images/backgrounds/music-card.png',
    url: 'https://music.gala.com/',
    linkText: 'data.galaSiteLinks.musicLinkText',
  },
  {
    logo: 'https://static.gala.games/images/logos/gala-cube-logo-white.svg',
    highlightColor: '#7633FF',
    title: 'data.galaSiteLinks.filmTitle',
    tagline: 'data.galaSiteLinks.filmTagline',
    mobileTagline: 'data.galaSiteLinks.filmMobileTagline',
    background: 'https://static.gala.games/images/backgrounds/film-card.png',
    url: 'https://film.gala.com/',
    linkText: 'data.galaSiteLinks.filmLinkText',
  },
  {
    logo: 'https://static.gala.games/images/logos/gala-cube-logo-white.svg',
    highlightColor: '#3453FF',
    title: 'data.galaSiteLinks.connectTitle',
    tagline: 'data.galaSiteLinks.connectTagline',
    mobileTagline: 'data.galaSiteLinks.connectMobileTagline',
    background: 'https://static.gala.games/images/backgrounds/connect-card.png',
    url: 'https://connect.gala.com/',
    linkText: 'data.galaSiteLinks.connectLinkText',
  },
  {
    logo: 'https://static.gala.games/images/logos/vexi-logo-white.svg',
    highlightColor: '#EF7503',
    title: 'data.galaSiteLinks.vexiTitle',
    tagline: 'data.galaSiteLinks.vexiTagline',
    mobileTagline: 'data.galaSiteLinks.vexiMobileTagline',
    background: 'https://static.gala.games/images/backgrounds/vexi.png',
    url: 'https://collectvox.com/',
    linkText: 'data.galaSiteLinks.vexiLinkText',
  },
  {
    logo: 'https://static.gala.games/images/logos/gala-cube-logo-white.svg',
    highlightColor: '#00B1A7',
    title: 'data.galaSiteLinks.scanTitle',
    tagline: 'data.galaSiteLinks.scanTagline',
    mobileTagline: 'data.galaSiteLinks.scanMobileTagline',
    background:
      'https://static.gala.games/images/backgrounds/galascan-card.png',
    url: 'https://galascan.gala.com/',
    linkText: 'data.galaSiteLinks.scanLinkText',
  },
];
