export enum GalaStoreToggleFilterCategory {
  games = 'games',
  rarity = 'rarity',
  availability = 'availability',
  type = 'type',
  price = 'price',
  name = 'name',
}

export interface IFilterValue {
  name: string;
  imgSrc?: string;
  selected?: boolean;
  comparator: string;
  value: string | number;
  game?: string;
  typeGames?: string[];
}

export interface IToggleFilter {
  field: string;
  type: string;
  comparator?: string;
  values: IFilterValue[];
  showAll?: boolean;
}

export interface IGalaStoreFilterOptions {
  [key: string]: IToggleFilter;
}

export interface IFilterOption {
  field: string;
  value: string | number | Array<string | number>;
  comparator: string;
}

export interface ISortOptions {
  sortField: string;
  sortDirection: number;
}

export interface IStoreItemsInput {
  currency: string;
  stringFilterOptions?: IFilterOption[];
  numberFilterOptions?: IFilterOption[];
  sortOptions?: ISortOptions;
  platform?: string;
  page?: number;
  limit?: number;
  hasWallet?: boolean;
}

export interface IStoreTokenPrice {
  token: string;
  usdPrice: number;
  usdPriceDecimals: number;
  tokenPriceInCents: number;
}

export interface IConnectedAccount {
  connectionName: string;
  accountId: string;
}

export enum GameItemProductTransactionType {
  transfer = 'transfer',
  burn = 'burn',
}

export enum NumericTransactionType {
  transfer = 1,
  burn,
}

export interface IOrderPaymentMessage {
  orderId: string;
  token?: string; // payment token address
  amount: string;
  transBlock: number; // the block number that the order expires after
  transType?: NumericTransactionType;
  chainId: string;
  systemName: string;
  wallet: string;
}

export interface IExternalWalletOrderPaymentData {
  smartContractAbi: string;
  smartContractAddress: string;
  orderPaymentMessage: IOrderPaymentMessage;
  signedMessage: string;
  expirationTimestamp: number;
}

export interface IExternalWalletOrderResponse {
  success: boolean;
  message?: string;
  paymentData?: IExternalWalletOrderPaymentData;
}
