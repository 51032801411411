













































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class GalaLogo extends Vue {
  @Prop() height?: number;

  defaultHeight = 40;
}
