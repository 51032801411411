import { render, staticRenderFns } from "./VerifyEmailAddress.vue?vue&type=template&id=c48c16a2&scoped=true&"
import script from "./VerifyEmailAddress.vue?vue&type=script&lang=ts&"
export * from "./VerifyEmailAddress.vue?vue&type=script&lang=ts&"
import style0 from "./VerifyEmailAddress.vue?vue&type=style&index=0&id=c48c16a2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c48c16a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VDialog,VIcon,VLayout,VProgressLinear,VTextField})
