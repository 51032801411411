import { Context } from '@nuxt/types';
import fetch from 'isomorphic-fetch';
import { HttpLink } from 'apollo-link-http';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { InvalidationPolicyCache } from 'apollo-invalidation-policies';
import { CachePersistor } from 'apollo-cache-persist';
import { WebSocketLink } from 'apollo-link-ws';
import { split, ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import introspectionQueryResultData from '../../fragmentTypesServer.json';

const SCHEMA_VERSION = '3';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const restorePersistentCache = async (cache: InvalidationPolicyCache) => {
  const persistor = new CachePersistor({
    // @ts-ignore
    cache,
    // @ts-ignore
    storage: window.sessionStorage,
  });

  const currentVersion = window.sessionStorage.getItem(SCHEMA_VERSION_KEY);

  if (currentVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    await persistor.restore();
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    // and mark ourselves as having updated to the latest version.
    await persistor.purge();
    window.sessionStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }
};

export default function({ env, app, store, route }: Context) {
  const httpUri = env.server;
  const wsOrWss = env.isProd ? 'wss' : 'ws';
  const wsUri = httpUri.replace(/https?/, wsOrWss);
  const httpLink = new HttpLink({
    uri: httpUri,
    credentials: 'include',
    async fetch(uri, options) {
      if (await app.$auth.isLoggedIn()) {
        (options as RequestInit).headers = {
          ...options?.headers,
          Authorization: await app.$auth.getTokenSilently(),
        };
      }

      if (route.fullPath) {
        (options as RequestInit).headers = {
          ...options?.headers,
          'X-Frontend-Path': route.fullPath || '',
        };
      }

      return fetch(uri, options);
    },
  });

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      lazy: true,
      async connectionParams() {
        return { token: (await app.$auth.getTokenSilently()) || undefined };
      },
    },
  });

  const captureErrorCodes = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const ctx = operation.getContext();
      const errorIdsStr = ctx?.response?.headers?.get('x-gala-errorids');

      if (errorIdsStr) {
        const errorIds = errorIdsStr.split(',');
        store.commit('addApiErrorIds', errorIds);
      }

      return response;
    });
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const errorHandlingLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (
          message?.toLowerCase().includes('authentication required') ||
          message === 'SESSION_REVOKED'
        ) {
          store.dispatch('profile/userLogout');
        }
      });
    }
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  // CACHE SETUP
  const cache = new InvalidationPolicyCache({
    // @ts-ignore
    fragmentMatcher,
    invalidationPolicies: {
      timeToLive: 30000,
      types: {
        RecentArticlesResponse: {
          timeToLive: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
        },
        UserProfile: {
          timeToLive: 1 * 60 * 60 * 1000, // 1 hour in milliseconds
        },
        GameToken: {
          timeToLive: 1 * 60 * 60 * 1000, // 1 hour in milliseconds
        },
        NeededAgreementsResponse: {
          timeToLive: 1 * 60 * 60 * 1000, // 1 hours in milliseconds
        },
        GalaPowerResponse: {
          timeToLive: 15 * 60 * 1000, // 15 minutes in milliseconds
        },
      },
    },
  });
  restorePersistentCache(cache);

  return {
    link: ApolloLink.from([errorHandlingLink, captureErrorCodes, link]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  };
}
