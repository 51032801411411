import { Context } from '@nuxt/types';

export default async function(context: Context) {
  const { redirect, query, store } = context;

  const { token, newuser } = query;
  try {
    if (!token) {
      throw new Error('missing required token parameter');
    }
    const attemptVerifyEmailAddress = await store
      .dispatch('profile/verifyEmail', {
        token,
      })
      .catch(() => {
        redirect('/');
      });

    if (!attemptVerifyEmailAddress?.verifyEmailAddress?.success) {
      redirect('/');
    } else {
      context.app.$ua.trackEmailVerificationCompleteEvent();
    }
    // check if user is logged in and logged-in user.id matches verifyEmailAddress.message
    // If they don't match, logout the user
    const userId = store?.state?.profile?.user?.id;
    if (
      userId &&
      userId !== attemptVerifyEmailAddress?.verifyEmailAddress?.message
    ) {
      await store.dispatch('profile/userLogout');
    } else {
      redirect(`/?emailVerified=true${newuser ? '&newuser=true' : ''}`);
    }
  } catch (error) {
    console.log('not critical error, probably with $ua', error);
  }
}
