import { MutationTree } from 'vuex';
import { IShareConfigState, IFriend } from './types';

export const mutations: MutationTree<IShareConfigState> = {
  setShareConfig(shareState: IShareConfigState, payload: IShareConfigState) {
    const {
      goldMember,
      shareUrl,
      referralCode,
      referrerReward,
      companyFee,
      rewardCurrency,
      rewardAmount,
      userBalanceThreshold,
      referralOutcomes,
      galaRewards,
      townStarRewards,
      upgradeBenefits,
      basicWalletBenefits,
    } = payload;

    shareState.goldMember = goldMember;
    shareState.shareUrl = shareUrl;
    shareState.referralCode = referralCode;
    shareState.referrerReward = referrerReward;
    shareState.companyFee = companyFee;
    shareState.rewardCurrency = rewardCurrency;
    shareState.rewardAmount = rewardAmount;
    shareState.userBalanceThreshold = userBalanceThreshold;
    shareState.referralOutcomes = referralOutcomes;
    shareState.galaRewards = galaRewards;
    shareState.townStarRewards = townStarRewards;
    shareState.upgradeBenefits = upgradeBenefits;
    shareState.basicWalletBenefits = basicWalletBenefits;
  },
  setNodesOwned(
    shareState: IShareConfigState,
    {
      townstarNodesOwned,
      founderNodesOwned,
      spiderTanksNodesOwned,
    }: {
      founderNodesOwned: number;
      townstarNodesOwned: number;
      spiderTanksNodesOwned: number;
    },
  ) {
    shareState.founderNodesOwned = founderNodesOwned;
    shareState.townstarNodesOwned = townstarNodesOwned;
    shareState.spiderTanksNodesOwned = spiderTanksNodesOwned;
    shareState.nodesOwned = townstarNodesOwned + founderNodesOwned;
  },

  setFetching(shareState: IShareConfigState, payload: boolean) {
    shareState.fetching = payload;
  },
  setFriends(shareState: IShareConfigState, payload: IFriend[]) {
    shareState.friends = payload;
  },
};
