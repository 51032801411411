







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import searchProductsQuery from '~/queries/searchProducts.gql';
import { ISearchItem } from '~/types/search-item';
import { debounce } from 'lodash';
import { IGame } from '../../store/games/types';

@Component
export default class SearchBar extends Vue {
  @State('discordLink') readonly discordLink!: string;
  @State(games => games.games, { namespace: 'games' })
  games!: IGame[];
  @Prop() width?: number;

  model: string = '';
  isLoading = false;
  items: ISearchItem[] = [];
  limit = 6;
  search: string = '';
  debounceDelay = 200;

  debounceHandler = debounce((cb: Function) => {
    cb();
  }, this.debounceDelay);

  handleSelection(search: ISearchItem | string) {
    if (typeof search === 'string' || !search) {
      //@ts-ignore
      this.$refs['search'].blur();
      this.$emit('search', {
        type: 'search',
        value: search,
      });
    } else {
      this.$emit('search', search);
    }
  }

  @Watch('search')
  handleSearch(value: string) {
    if (!value || value.length < 3) {
      this.items = [];
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    const searchProductsInput = {
      text: value,
      limit: this.limit,
      platform: 'platform',
      searchFields: ['game', 'name', 'category', 'rarity.label'],
    };

    try {
      this.debounceHandler(async () => {
        const { data } = await this.$apollo.query<{
          searchProducts: {
            type: string;
            value: string;
            baseId?: string;
            game?: string;
          }[];
        }>({
          query: searchProductsQuery,
          variables: {
            searchProductsInput,
          },
        });

        const searchResultsWithAllRelatedGames = [...data.searchProducts];

        const gamesMatchingSearch = this.games.filter(game => {
          return (
            game.name?.toLowerCase().startsWith(value.toLowerCase()) &&
            game.showGamePage
          );
        });

        if (gamesMatchingSearch.length > 0) {
          gamesMatchingSearch.forEach(game => {
            const existingGameEntry = searchResultsWithAllRelatedGames.find(
              relatedGame => relatedGame.value === game.name,
            );

            if (!existingGameEntry) {
              searchResultsWithAllRelatedGames.unshift({
                baseId: undefined,
                game: undefined,
                type: 'game',
                value: game.name,
              });
            }
          });
        }

        this.items = searchResultsWithAllRelatedGames;
        this.isLoading = false;
      });
    } catch (e) {
      this.isLoading = false;
    }
  }
}
