import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MailtoFailureHandler extends Vue {
  windowBlurTimer: any;
  showPromptToSendEmail = false;

  clearWindowBlurTimer() {
    if (this.windowBlurTimer) {
      clearTimeout(this.windowBlurTimer);
    }
    this.removeWindowBlurListener();
  }

  removeWindowBlurListener() {
    window.removeEventListener('blur', this.clearWindowBlurTimer);
  }

  checkForBlurEvent() {
    if (!this.windowBlurTimer) {
      window.addEventListener('blur', this.clearWindowBlurTimer);
      this.windowBlurTimer = setTimeout(() => {
        this.showPromptToSendEmail = true;
        this.removeWindowBlurListener();
      }, 500);
    }
  }
}
