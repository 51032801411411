import {
  IHydratedAdaptor,
  TTrackScopedEvent,
  EEventTypes,
  IHydrateAdaptorPayload,
  TRACK_EVENT_METHOD_TO_EVENT_NAME_MAP,
  TTrackEventMethodKeys,
} from './types';
import { noop } from '../../utils';

type TGenerateFallback<T = any> = (
  eventName: EEventTypes,
) => TTrackScopedEvent<T>;
type TAdaptorOrDefault = (
  methodName: keyof IHydratedAdaptor,
  fallback: TTrackScopedEvent | EEventTypes,
) => TTrackScopedEvent;
type TPartiallyHydratedAdaptor = Partial<
  {
    [k in keyof IHydratedAdaptor]: any;
  }
>;
type TApplyCustomFallback = (
  methodNames: TTrackEventMethodKeys,
  customFallback: TGenerateFallback,
  ret: TPartiallyHydratedAdaptor,
) => void;
interface IGenerateHydrateAdaptorHelpersReturn {
  applyCustomFallback: TApplyCustomFallback;
  partiallyHydratedAdaptor: TPartiallyHydratedAdaptor;
}
type TGenerateHydrateAdaptorHelpers = (
  adaptor: IHydrateAdaptorPayload,
) => IGenerateHydrateAdaptorHelpersReturn;

export const generateHydrateAdaptorHelpers: TGenerateHydrateAdaptorHelpers = adaptor => {
  const trackEvent = adaptor?.trackEvent ?? noop;

  const generateFallback: TGenerateFallback = eventName => (
    eventValue?: any,
  ) => {
    const finalPayload: {
      eventName: EEventTypes;
      eventValue?: any;
    } = { eventName };

    if (typeof eventValue !== 'undefined') {
      finalPayload.eventValue = eventValue;
    }

    trackEvent({ eventName, eventValue });
  };

  const adaptorOrDefault: TAdaptorOrDefault = (methodName, fallback) => {
    if (adaptor[methodName]) {
      return adaptor[methodName] as TTrackScopedEvent;
    }

    if (typeof fallback !== 'string') {
      return fallback;
    }

    return generateFallback(fallback);
  };

  const setUser = adaptor?.setUser ?? noop;
  const init = adaptor?.init ?? noop;

  const partiallyHydratedAdaptor: TPartiallyHydratedAdaptor = {
    setUser,
    init,
    trackEvent,
  };

  Object.entries(TRACK_EVENT_METHOD_TO_EVENT_NAME_MAP).forEach(([k, v]) => {
    partiallyHydratedAdaptor[k as keyof IHydratedAdaptor] = adaptorOrDefault(
      k as keyof IHydratedAdaptor,
      generateFallback(v),
    );
  });

  const applyCustomFallback: TApplyCustomFallback = (
    methodNames,
    customFallback,
    ret,
  ) => {
    methodNames.forEach(k => {
      ret[k] = adaptorOrDefault(
        k,
        customFallback(TRACK_EVENT_METHOD_TO_EVENT_NAME_MAP[k]),
      );
    });
  };

  return {
    applyCustomFallback,
    partiallyHydratedAdaptor,
  };
};

export default generateHydrateAdaptorHelpers;
