import { ALL_METHOD_KEYS, IHydratedAdaptor, IPlugin, TOnError } from './types';
import { noop } from '../../utils';

export interface IGenerateProxyPayload {
  hydratedAdaptors: IHydratedAdaptor[];
  onError: TOnError;
}

type TGenerateProxy = (payload: IGenerateProxyPayload) => IPlugin;
export const generateProxy: TGenerateProxy = payload => {
  const { hydratedAdaptors, onError } = payload;

  const handler = {
    get(target: any, prop: keyof IHydratedAdaptor) {
      const methodProxy = new Proxy(target[prop], {
        apply: (internalTgt, thisArg, args: any[]) => {
          hydratedAdaptors.forEach(adaptor => {
            const func = adaptor[prop];
            try {
              // @ts-ignore
              func(...args);
            } catch (error) {
              onError(error);
            }
          });
        },
      });
      return methodProxy;
    },
  };

  const proxyTarget: any = {};
  ALL_METHOD_KEYS.forEach(k => {
    proxyTarget[k] = noop;
  });

  const finalProxy = new Proxy<IPlugin>(proxyTarget as IPlugin, handler);
  return finalProxy;
};

export default generateProxy;
