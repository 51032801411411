
























































import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import { IShareConfigState } from '~/store/share/types';

import ShareButtons from '~/components/Share/ShareButtons.vue';

@Component({
  components: {
    ShareButtons,
  },
})
export default class ReferAFriend extends Vue {
  @State(profile => profile.user.referralCode, { namespace: 'profile' })
  readonly referralCode!: string;
  @State('showReferralDialog') readonly showReferralDialogState!: boolean;
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State('share')
  shareState!: IShareConfigState;
  @Action('fetchShareConfig', { namespace: 'share' })
  fetchShareConfig!: () => void;

  logoWidth = 0;

  get showReferralDialog() {
    return this.showReferralDialogState;
  }
  set showReferralDialog(value) {
    this.toggleReferralDialog(value);
  }

  get shareUrl() {
    if (this.shareState.shareUrl) {
      return this.shareState.shareUrl;
    }

    if (!this.referralCode) return '';

    return `${window.location.origin}?source=gala&r=${this.referralCode}`;
  }

  mounted() {
    if (this.currentUser.loggedIn) {
      this.fetchShareConfig();
    }
  }

  @Mutation toggleReferralDialog!: (args: any) => void;

  async copyReferralCode() {}
}
