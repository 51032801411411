



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IUser } from '../../store/profile/types';

@Component({})
export default class CaptureNameModal extends Vue {
  @State(profile => profile.user, { namespace: 'profile' })
  user!: IUser;

  @Prop(Boolean) readonly hideCloseIcon!: boolean;
  @Prop(Function) readonly onCloseRequested!: () => void;
  @Prop({ type: Function, required: true }) readonly onFullNameSubmitted!: (
    first: string,
    last: string,
  ) => void;

  firstName = '';
  lastName = '';
  w3wConnectionEnabled = process.env.w3wConnectionEnabled;

  get heading() {
    return this.w3wConnectionEnabled
      ? this.$t('components.wallet.connectWeb3Wallet.connectYourWallet')
      : this.$t('components.newWallet.newWallet.createYourWallet');
  }
  get description() {
    return this.w3wConnectionEnabled
      ? this.$t(
          'components.wallet.connectWeb3Wallet.walletConnectionPiExplanation',
        )
      : this.$t('components.newWallet.newWallet.walletCreationPiExplanation');
  }

  get canContinue() {
    return this.firstName.length > 0 && this.lastName.length > 0;
  }

  handleContinue(ev: Event) {
    ev.preventDefault();
    this.onFullNameSubmitted?.(this.firstName, this.lastName);
  }

  close() {
    this.onCloseRequested?.();
  }
}
