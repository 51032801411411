import { GetterTree } from 'vuex';
import { IW3wState } from './types';
import { IRootState } from '../types';

export const getters: GetterTree<IW3wState, IRootState> = {
  w3wConnectionActive(state: IW3wState) {
    return (
      !!state.web3Provider &&
      !!state.connectedWallet.address &&
      state.connectedExternalWallet.ethAddress === state.connectedWallet.address
    );
  },
  showWalletConnectionErrorBanner(
    state: IW3wState,
    w3wGetters: any,
    rootState: IRootState,
  ) {
    return (
      state.walletConnectionError &&
      rootState.profile &&
      rootState.profile.user.walletConnected
    );
  },
};
