
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DiceIcon extends Vue {
  @Prop({ type: String, default: '#000000' }) readonly color!: string;
  @Prop({ type: String, default: '80px' }) readonly height!: string;
}
