export function _slicedToArray(arr: any[], i: number) {
  return _arrayWithHoles(arr) || _nonIterableRest();
}

export function _nonIterableRest() {
  throw new TypeError('Invalid attempt to destructure non-iterable instance');
}

export function _arrayWithHoles(arr: any[]) {
  if (Array.isArray(arr)) {
    return arr;
  }
}
