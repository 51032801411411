/* tslint:disable no-var-requires */
const everflowSDK = require('@everflow/everflow-sdk');
import { getCookie, deleteCookie } from '~/utils';
import { ApolloClient } from 'apollo-client';
import createUserReferralMutation from '~/mutations/createUserReferral.gql';

function setReferralCookies(name: string, value: string, expiration?: Date) {
  const domains = process.env.referralDomains?.split(',') ?? [];
  const cookieNamePrefix = process.env.referralCookiePrefix;

  domains.forEach(domain => {
    if (window.origin.includes(domain)) {
      document.cookie = `${cookieNamePrefix}${name}=${value}; domain=${domain}; path=/;${
        expiration ? ` expires=${expiration}` : ''
      }`;
    }
  });
}

export async function captureReferrals(queryParams: any) {
  const { cjevent, referralCode, r, source, offerid, aid, cid, pubCid } =
    queryParams || {};

  const referral = referralCode ?? r;

  // Internal Referrals
  if (referral && source === 'gala') {
    setReferralCookies('galaReferralCode', referral);
    if (offerid) {
      setReferralCookies('galaOfferId', offerid);
    }
  }

  // COMMISSION_JUNCTION
  if (cjevent && getCookie('cjEvent') !== cjevent) {
    // expiration must be 45 days from initial click
    const now = new Date();
    const expiration = now.setUTCDate(now.getUTCDate() + 45);

    setReferralCookies('cjEvent', cjevent, new Date(expiration));
    setReferralCookies('cjAffiliateId', pubCid, new Date(expiration));
  }

  // EVERFLOW
  if (source === 'ef' && queryParams.offerid && queryParams.aid) {
    const validCid = cid && typeof cid === 'string';

    const transactionId = validCid
      ? cid
      : await createEverflowTransactionId(queryParams);

    if (transactionId) {
      setReferralCookies('everflowTransactionId', transactionId);
      setReferralCookies('everflowOfferId', offerid);
      setReferralCookies('everflowAffiliateId', aid);
    }
  }
}

async function createEverflowTransactionId(queryParams: any) {
  everflowSDK.configure({
    tracking_domain: 'https://www.glgmnfj3trk.com',
  });
  const { offerid, aid } = queryParams;

  everflowSDK.configure({
    tracking_domain: 'https://www.glgmnfj3trk.com',
  });

  await everflowSDK.click({
    offer_id: offerid,
    affiliate_id: aid,
    source_id: queryParams.source_id,
    sub1: queryParams.sub1,
    sub2: queryParams.sub2,
    sub3: queryParams.sub3,
    sub4: queryParams.sub4,
    sub5: queryParams.sub5,
  });
}

export function storedExternalAffiliateInfo() {
  if (storedEverflowInfo()) {
    return storedEverflowInfo();
  }

  if (storedCJInfo()) {
    return storedCJInfo();
  }
}

function storedEverflowInfo() {
  const everflowTransactionId = getCookie('everflowTransactionId');
  const everflowOfferId = getCookie('everflowOfferId');
  const everflowAffiliateId = getCookie('everflowAffiliateId');

  if (everflowTransactionId && everflowOfferId && everflowAffiliateId) {
    return {
      galaPlatform: 'games',
      affiliateService: 'everflow',
      offerId: everflowOfferId,
      affiliateId: everflowAffiliateId,
      xTransactionId: everflowTransactionId,
    };
  }
}

function storedCJInfo() {
  const cjEvent = getCookie('cjEvent');
  const cjAffiliateId = getCookie('cjAffiliateId');
  if (cjEvent) {
    return {
      galaPlatform: 'games',
      affiliateService: 'commissionJunction',
      affiliateId: cjAffiliateId,
      xTransactionId: cjEvent,
    };
  }
}

export function removeStoredReferralInfo() {
  const domains = process.env.referralDomains?.split(',') ?? [];
  const cookieNamePrefix = process.env.referralCookiePrefix;

  domains.forEach(domain => {
    [
      'galaReferralCode',
      'galaOfferId',
      'everflowTransactionId',
      'everflowOfferId',
      'everflowAffiliateId',
      'cjEvent',
      'cjAffiliateId',
    ].forEach(name => {
      deleteCookie(`${cookieNamePrefix}${name}`, '', domain);
    });
  });

  return;
}

export async function createReferral(apollo: ApolloClient<any>) {
  const created = sessionStorage.getItem('refCreated');

  if (!created) {
    const affiliate = storedExternalAffiliateInfo();

    if (affiliate) {
      const result = await apollo.mutate({
        mutation: createUserReferralMutation,
        variables: { affiliate },
      });

      sessionStorage.setItem('refCreated', 'true');

      return result;
    }
  }
}
