




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import WalletCreationModalFlow from '~/components/NewWallet/WalletCreationModalFlow.vue';

@Component({ components: { WalletCreationModalFlow } })
export default class TriggerWalletCreationBtn extends Vue {
  @State(profile => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly round!: string;
  @Prop({ type: Boolean, default: false })
  readonly incompleteAccountWidget!: string;
  @Prop({ type: Boolean, default: false })
  readonly settingGyriPassphrase!: boolean;

  @Getter('w3wConnectionActive', { namespace: 'web3Wallet' })
  readonly w3wConnectionActive!: boolean;

  showModals = false;
  w3wConnectionEnabled = process.env.w3wConnectionEnabled;

  get buttonText() {
    return this.settingGyriPassphrase
      ? this.$t('components.newWallet.triggerWalletCreationBtn.setTransferCode')
      : this.$t('components.newWallet.triggerWalletCreationBtn.finish');
  }

  async created() {
    const { createWallet } = this.$route.query;
    if (createWallet) {
      this.showModals = true;
    }
  }

  handleClick() {
    if (
      this.w3wConnectionEnabled &&
      !this.w3wConnectionActive &&
      !this.settingGyriPassphrase
    ) {
      let route = '/connect-wallet';
      if (
        this.$router.currentRoute &&
        this.$router.currentRoute.params &&
        this.$router.currentRoute.params.id
      ) {
        // Sending the item ID allows us to redirect users to purchase page after wallet connection if they are in the process of buying an item
        route = route + '/?item=' + this.$router.currentRoute.params.id;
      }

      this.$router.push(route);
      this.$emit('clicked');
    } else {
      this.showModals = true;
    }
  }
}
