import sanitizeHtml from 'sanitize-html';

const sanitizeLegalDocumentHtml = (dirtyHtml: string) =>
  sanitizeHtml(dirtyHtml, {
    allowedAttributes: {
      p: ['style'],
      span: ['style'],
      a: ['href'],
      ol: ['type'],
    },
    allowedStyles: {
      '*': {
        'font-size': [/^\d+(?:px|em|%|pt)$/],
        'font-weight': [/^[1-9]00$/],
      },
    },
  });

export default sanitizeLegalDocumentHtml;
