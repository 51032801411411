
















import { Component, Vue, Prop } from 'vue-property-decorator';
import PrivacyPolicyQuery from '~/queries/privacyPolicy.gql';
import { sanitizeLegalDocumentHtml } from '../../utils';

@Component
export default class PrivacyPolicy extends Vue {
  @Prop({ type: String, required: true })
  readonly termsQuery!: any;

  privacyPolicyHtml = '';
  async created() {
    const {
      data: { privacyPolicy },
    } = await this.$apollo.query({
      query: PrivacyPolicyQuery,
      fetchPolicy: 'no-cache',
    });
    if (privacyPolicy && privacyPolicy.content) {
      this.privacyPolicyHtml = sanitizeLegalDocumentHtml(privacyPolicy.content);
    }
  }
}
