import { Context } from '@nuxt/types';
import { setCookie, getCookie } from '.';
import { ISanityPromoPack } from '../store/cms_content/types';

export default function promoPackDisplay(context: Context) {
  const promoCookie = 'gp1-display';
  const { user: currentUser } = context.store.state.profile;
  const noPages = !['connect-wallet', 'verify-ip'].includes(
    String(context.route.name),
  );
  const promoPack = context.store.state.cmsContent?.promoPacks.find(
    (x: ISanityPromoPack) => x.featured,
  );
  const promoEnabled = context.store.state.cmsContent?.promoPack?.enabled;
  const promoInitial = promoPack?.displayInterval.initialDisplay;
  const promoInterval = promoPack?.displayInterval.intervalDisplay;
  let promoCookieValue = getCookie(promoCookie);

  if (promoCookieValue === undefined) {
    promoCookieValue = '1';
    setCookie(promoCookie, promoCookieValue);
  } else {
    promoCookieValue = (Number(promoCookieValue) + 1).toString();
    setCookie(promoCookie, promoCookieValue);
  }

  if (
    (Number(promoCookieValue) === promoInitial ||
      Number(promoCookieValue) % promoInterval === 0) &&
    currentUser?.loggedIn === false &&
    noPages === true &&
    promoEnabled === true
  ) {
    setTimeout(() => {
      context.store.commit('togglePromoPackDialog', {
        active: true,
        promoPack,
      });
    }, 1500);
  }
}
