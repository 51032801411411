<template>
  <div id="error-container" class="container">
    <div class="content-container error-handler">
      <v-card dark class="v-card v-sheet mt-10 py-6 px-10">
        <div v-if="$route.query.error">
          <div v-if="$route.query.error === 'access_denied'">
            <div v-if="$route.query.error_description === 'User is banned'">
              <h1 class="error-heading">
                {{ $t('errors.banned.title') }}
              </h1>

              <div class="error-description">
                {{ $t('errors.banned.description') }}
              </div>
            </div>
            <div
              v-else-if="
                $route.query.error_description ===
                  'Failed to send IP verification email'
              "
            >
              <h1 class="error-heading">
                {{ $t('errors.ipVerificationEmailFailure.title') }}
              </h1>

              <div class="error-description">
                {{ $t('errors.ipVerificationEmailFailure.description') }}
              </div>
            </div>
            <div v-else>
              <h1 class="error-heading">
                {{ $t('errors.accessDenied.title') }}
              </h1>
              <div class="error-description">
                {{ $t('errors.accessDenied.description') }}
              </div>
            </div>
          </div>
          <div v-else>
            <h1 class="error-heading">{{ $route.query.error }}</h1>
            <div class="error-description">
              {{ $route.query.error_description }}
            </div>
          </div>

          <div class="error-options">
            <a href="/">
              <v-icon size="24">
                mdi-arrow-left
              </v-icon>
              {{ $t('errors.backToHome') }}
            </a>

            <a href="/contact-support">
              {{ $t('errors.contactSupport') }}
            </a>
          </div>
        </div>
        <div v-else>
          <div v-if="error.statusCode === 404">
            <h1 class="error-heading">{{ $t('errors.notFound.title') }}</h1>
            <div class="error-description">
              {{ $t('errors.notFound.description') }}
            </div>

            <div class="error-options">
              <a href="/">
                <v-icon size="24">
                  mdi-arrow-left
                </v-icon>
                {{ $t('errors.backToHome') }}
              </a>

              <a href="/contact-support">
                {{ $t('errors.contactSupport') }}
              </a>
            </div>
          </div>
          <div v-else>
            <h1 class="error-heading">{{ $t('errors.serverError.title') }}</h1>
            <div class="error-description">
              {{ $t('errors.serverError.description') }}
            </div>

            <div class="error-options">
              <a href="/">
                <v-icon size="24">
                  mdi-arrow-left
                </v-icon>
                {{ $t('errors.backToHome') }}
              </a>

              <a href="/contact-support">
                {{ $t('errors.contactSupport') }}
              </a>
            </div>

            <div class="error-submission">
              {{ $t('errors.serverError.errorSubmission') }}
            </div>

            <div class="error-details">
              <div class="error-details-icon">
                <v-icon size="20" color="error">
                  mdi-alert-circle-outline
                </v-icon>
              </div>

              <div class="error-details-message">
                {{
                  error.message
                    ? error.message.replace('GraphQL error: ', '')
                    : ''
                }}

                <div class="error-id">
                  {{
                    error &&
                    error.graphQLErrors &&
                    error.graphQLErrors[0] &&
                    error.graphQLErrors[0].extensions
                      ? error.graphQLErrors[0].extensions.errorId
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
};
</script>

<style>
main:has(#error-container) {
  background-color: #000 !important;
}
</style>

<style scoped>
.v-card {
  background-color: #000 !important;
}

h1.error-heading {
  color: #fff;

  font-family: Figtree !important;
  font-size: 45px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 115% !important;
}

div.error-description {
  margin-top: 10px;

  color: #808080;

  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

div.error-options {
  margin-top: 17px;
}

div.error-options .v-icon {
  color: #0084f8;
  height: 32px;
  margin-right: 5px;
}

div.error-options,
div.error-options a {
  color: #0084f8;

  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

div.error-options a:not(:first-child) {
  margin-left: 40px;
}

div.error-submission {
  margin-top: 20px;

  color: var(--Gray, #c9d6df);

  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

div.error-details {
  margin-top: 10px;

  padding: var(--components-alert-padding, 16px);
  align-items: flex-start;
  gap: var(--components-alert-padding, 16px);

  border-radius: var(--radius-8, 8px);
  background: var(--surface-secondary, #181818);

  display: flex;
}

div.error-details-icon {
  display: inline-block;

  margin-right: 10px;
}

div.error-details-message {
  display: inline-block;

  color: var(--text-primary, #fff);

  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

div.error-id {
  margin-top: 5px;

  color: var(--Gray, #c9d6df);

  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>
