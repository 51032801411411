import { ILax } from '~/types/lax';
// @ts-ignore
import lax from 'lax.js';

declare module 'vue/types/vue' {
  // this.$lax inside Vue components
  // tslint:disable:interface-name
  interface Vue {
    $lax: ILax;
  }
}

// @ts-ignore
const nuxtLax: Plugin = (ctx, inject) => {
  lax.init();
  lax.addDriver('scrollY', () => {
    return window.scrollY;
  });

  inject('lax', lax);
};

export default nuxtLax;
