



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component
export default class DiscordIcon extends Vue {
  @State('discordLink') readonly discordLink!: string;
  @Prop() width?: number;

  defaultWidth = 25;
}
