




























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LegalAgreementsQuery from '~/queries/legalAgreements.gql';
import AgreementDialog from './AgreementDialog.vue';
import AgreementStatement from './AgreementStatement.vue';
import ScrollToAccept from './ScrollToAccept.vue';
import {
  ILegalDocument,
  IDocumentsAccepted,
  IDocumentAccepted,
} from '~/types/legal-documents';

const defaultLegalDocument: ILegalDocument = {
  content: '',
  documentType: '',
  version: '',
};

enum DocumentTypes {
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  PRIVACY_POLICY = 'privacy_policy',
  NODE_NON_INVESTMENT_STATEMENT = 'node_non_investment_statement',
  BATTLESTAR_GALACTICA_TERMS_AND_CONDITIONS = 'battlestar_galactica_terms_and_conditions',
  CREDIT_CARD_TERMS_AND_CONDITIONS = 'credit_card_terms_and_conditions',
}

export const defaultLegalAgreementsPayload: ILegalAgreementsPayload = {
  documentsAccepted: [],
  allAccepted: false,
};

export interface ILegalAgreementsPayload {
  documentsAccepted: IDocumentsAccepted;
  allAccepted?: boolean;
}

export interface INodeNonInvestmentStatementPayload {
  text: string;
  trailingLink?: {
    href: string;
    text: string;
    text2?: string;
  };
}

export interface IAdditionalPrivacyPolicy {
  href: string;
  text: string;
}

export interface IAdditionalTermsOfService {
  href: string;
  text: string;
}

@Component({
  components: { AgreementDialog, ScrollToAccept, AgreementStatement },
})
export default class LegalAgreements extends Vue {
  @Prop({ type: String })
  readonly termsAndConditions!: 'scroll-to-accept' | 'checkbox';

  @Prop({ type: String })
  readonly privacyPolicy!: 'checkbox';

  @Prop({ type: String })
  readonly termsAndConditionsWithPrivacyPolicy!: 'checkbox';

  @Prop({ type: Object })
  readonly nodeNonInvestmentStatement?: INodeNonInvestmentStatementPayload;

  @Prop({ type: String })
  readonly battlestarGalacticaTermsAndConditions!: 'checkbox';

  @Prop({ type: Object, default: () => defaultLegalAgreementsPayload })
  readonly value!: ILegalAgreementsPayload;

  @Prop({ type: Object })
  readonly additionalTermsOfService!: IAdditionalTermsOfService;

  @Prop({ type: Object })
  readonly additionalPrivacyPolicy!: IAdditionalPrivacyPolicy;

  fetching = true;

  termsAndConditionsData = defaultLegalDocument;
  privacyPolicyData = defaultLegalDocument;
  nodeNonInvestmentStatementData = defaultLegalDocument;
  battlestarGalacticaTermsAndConditionsData = defaultLegalDocument;

  termsAndConditionsVersionAccepted = '';
  privacyPolicyVersionAccepted = '';
  nodeNonInvestmentStatementVersionAccepted = '';
  battlestarGalacticaTermsAndConditionsVersionAccepted = '';

  initVars(payload: ILegalAgreementsPayload) {
    this.termsAndConditionsVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.TERMS_AND_CONDITIONS,
      payload.documentsAccepted,
    );
    this.privacyPolicyVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.PRIVACY_POLICY,
      payload.documentsAccepted,
    );
    this.nodeNonInvestmentStatementVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.NODE_NON_INVESTMENT_STATEMENT,
      payload.documentsAccepted,
    );
    this.battlestarGalacticaTermsAndConditionsVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.BATTLESTAR_GALACTICA_TERMS_AND_CONDITIONS,
      payload.documentsAccepted,
    );
  }

  documentTypeFilter(docType: DocumentTypes) {
    return (doc: IDocumentAccepted) => doc.documentType === docType;
  }

  findByDocumentType(
    docType: DocumentTypes,
    arr?: IDocumentsAccepted,
  ): IDocumentAccepted | undefined {
    if (!arr) arr = this.acceptedDocuments;
    return arr.find(this.documentTypeFilter(docType));
  }

  getVersionByDocumentType(
    docType: DocumentTypes,
    arr?: IDocumentsAccepted,
  ): IDocumentAccepted['versionAccepted'] {
    const doc = this.findByDocumentType(docType, arr);
    if (!doc) return '';

    return doc.versionAccepted;
  }

  mounted() {
    this.fetchTermsAndConditions();
    this.initVars(this.value);
    this.emitInput();
  }

  async fetchTermsAndConditions() {
    const { data } = await this.$apollo.query({
      query: LegalAgreementsQuery,
    });

    this.termsAndConditionsData = data.termsAndConditions;
    this.privacyPolicyData = data.privacyPolicy;
    this.nodeNonInvestmentStatementData = data.nodeNonInvestmentStatement;
    this.battlestarGalacticaTermsAndConditionsData =
      data.battlestarGalacticaTermsAndConditions;
    this.fetching = false;
  }

  emitInput(): void {
    this.$emit('input', this.emitPayload);
  }

  get emitPayload(): ILegalAgreementsPayload {
    return {
      documentsAccepted: this.documentsAccepted,
      allAccepted: this.allRequiredDocumentsAccepted,
    };
  }

  get acceptedDocuments() {
    return [
      {
        required:
          !!this.termsAndConditions ||
          !!this.termsAndConditionsWithPrivacyPolicy,
        documentType: DocumentTypes.TERMS_AND_CONDITIONS,
        versionAccepted: this.termsAndConditionsVersionAccepted,
      },
      {
        required:
          !!this.privacyPolicy || !!this.termsAndConditionsWithPrivacyPolicy,
        documentType: DocumentTypes.PRIVACY_POLICY,
        versionAccepted: this.privacyPolicyVersionAccepted,
      },
      {
        required: !!this.nodeNonInvestmentStatement,
        documentType: DocumentTypes.NODE_NON_INVESTMENT_STATEMENT,
        versionAccepted: this.nodeNonInvestmentStatementVersionAccepted,
      },
      {
        required: !!this.battlestarGalacticaTermsAndConditions,
        documentType: DocumentTypes.BATTLESTAR_GALACTICA_TERMS_AND_CONDITIONS,
        versionAccepted: this
          .battlestarGalacticaTermsAndConditionsVersionAccepted,
      },
    ];
  }

  get allRequiredDocumentsAccepted() {
    return this.acceptedDocuments.every(({ required, versionAccepted }) => {
      return !required || versionAccepted;
    });
  }

  get documentsAccepted() {
    return this.acceptedDocuments.filter(({ required, versionAccepted }) => {
      return required && versionAccepted;
    });
  }

  get termsAndConditionsWithPrivacyPolicyStatus(): boolean {
    return (
      this.privacyPolicyVersionAccepted !== '' &&
      this.termsAndConditionsVersionAccepted !== ''
    );
  }

  set termsAndConditionsWithPrivacyPolicyStatus(isAccepted: boolean) {
    this.termsAndConditionsVersionAccepted = isAccepted
      ? this.termsAndConditionsData.version
      : '';

    this.privacyPolicyVersionAccepted = isAccepted
      ? this.privacyPolicyData.version
      : '';

    this.emitInput();
  }

  get termsAndConditionsStatus(): boolean {
    return this.termsAndConditionsVersionAccepted !== '';
  }

  set termsAndConditionsStatus(isAccepted: boolean) {
    this.termsAndConditionsVersionAccepted = isAccepted
      ? this.termsAndConditionsData.version
      : '';

    this.emitInput();
  }

  get privacyPolicyStatus(): boolean {
    return this.privacyPolicyVersionAccepted !== '';
  }

  set privacyPolicyStatus(isAccepted: boolean) {
    this.privacyPolicyVersionAccepted = isAccepted
      ? this.privacyPolicyData.version
      : '';

    this.emitInput();
  }

  get nodeNonInvestmentStatus(): boolean {
    return this.nodeNonInvestmentStatementVersionAccepted !== '';
  }

  set nodeNonInvestmentStatus(isAccepted: boolean) {
    this.nodeNonInvestmentStatementVersionAccepted = isAccepted
      ? this.nodeNonInvestmentStatementData.version
      : '';

    this.emitInput();
  }

  get battleStarGalacticaTermsAndConditionsStatus(): boolean {
    return this.battlestarGalacticaTermsAndConditionsVersionAccepted !== '';
  }

  set battleStarGalacticaTermsAndConditionsStatus(isAccepted: boolean) {
    this.battlestarGalacticaTermsAndConditionsVersionAccepted = isAccepted
      ? this.battlestarGalacticaTermsAndConditionsData.version
      : '';

    this.emitInput();
  }

  @Watch('value')
  onValueChanged(n: ILegalAgreementsPayload) {
    this.initVars(n);
  }
}
