














































import { Component, Vue } from 'vue-property-decorator';
import { galaSiteLinks } from '~/data/gala_site_links';

@Component({})
export default class GalaSitesMenu extends Vue {
  fadeIn = false;
  siteLinkCards = galaSiteLinks;

  mounted() {
    setTimeout(() => {
      this.fadeIn = true;
    }, 50);
  }

  beforeDestroy() {
    this.$emit('hideMenu');
  }

  closeSitesMenu() {
    this.fadeIn = false;

    setTimeout(() => {
      this.$emit('hideMenu');
    }, 300);
  }

  include() {
    return [document.querySelector('#sites-menu-btn')];
  }
}
