


















































import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator';
@Component
export default class ValidatedPassword extends Vue {
  @Prop(Boolean) onCurrentStep!: boolean;
  @Prop({ default: 'Password' })
  readonly label!: string;
  @Prop({ default: false })
  readonly inFlight!: boolean;
  @Prop({ type: String, default: '0' }) readonly tabindex!: string;

  passInput = '';
  showPass = false;
  showRequirements = false;

  get eightCharacters() {
    return this.passInput.length >= 8;
  }

  get oneLowerCase() {
    const hasOneLowercase = /[a-z]/g;
    const test = hasOneLowercase.test(this.passInput);
    return test;
  }

  get oneUpperCase() {
    const hasOneUppercase = /[A-Z]/g;
    return hasOneUppercase.test(this.passInput);
  }

  get oneNumber() {
    const hasOneNumber = /[0-9]/g;
    return hasOneNumber.test(this.passInput);
  }

  get oneSymbol() {
    const hasSymbolRegex = /[.,!@#$%^&*<>?()\-_=+\\{}[\];:"~`|\']/;
    const hasSymbol = hasSymbolRegex.test(this.passInput);
    return hasSymbol;
  }

  get noSpaces() {
    const hasSpaces = /\s/g;
    return !hasSpaces.test(this.passInput);
  }

  get valid() {
    return (
      this.noSpaces &&
      this.oneSymbol &&
      this.oneNumber &&
      this.oneUpperCase &&
      this.oneLowerCase &&
      this.eightCharacters
    );
  }

  handleEnter() {
    if (this.valid) {
      this.$emit('enter-submit', [this.valid, this.passInput !== '']);
    }
  }

  @Emit('input-focused')
  handleFocus() {
    this.showRequirements = true;
  }

  @Emit('pass-valid-change')
  passValidChanged(passValid: boolean) {
    return passValid;
  }

  @Emit('pass-change')
  passChanged(pass: string) {
    return pass;
  }

  @Watch('valid')
  handleValid(isValid: boolean) {
    this.passValidChanged(isValid);
  }

  @Watch('passInput')
  handlePassInputChange(pass: string) {
    this.passChanged(pass);
  }

  @Watch('onCurrentStep')
  handleOnCurrentStep(handleOnCurrentStep: boolean) {
    if (handleOnCurrentStep) {
      (this.$refs.passInput as any).focus();
      this.showPass = false;
    }
  }

  @Watch('inFlight')
  handleInFlight(isInflight: boolean, wasInFlight: boolean) {
    if (wasInFlight === true && isInflight === false) {
      this.passInput = '';
    }
  }
}
