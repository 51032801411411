var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wallet-connection-error-banner pa-2 error"},[_c('TwoFactorAuthInputPrompt',{attrs:{"inProgress":_vm.twoFaInProgress || _vm.twoFaFinished},on:{"submit":_vm.onTwoFaSubmit},model:{value:(_vm.showAuthDialog),callback:function ($$v) {_vm.showAuthDialog=$$v},expression:"showAuthDialog"}}),_vm._v(" "),_c('div',[_c('v-icon',{staticClass:"mr-4",attrs:{"size":"28"}},[_vm._v("error")]),_vm._v(" "),_c('p',{staticClass:"mb-0 mr-4 medium-font"},[_vm._v("\n      "+_vm._s(_vm.shortMessage)+"\n    ")]),_vm._v(" "),_c('button',{staticClass:"gala-btn small outline clear medium-font",on:{"click":function($event){return _vm.toggleWalletConnectionError(true)}}},[_vm._v("\n      "+_vm._s(_vm.$t('components.home.walletConnectionErrorBanner.resolve'))+"\n    ")])],1),_vm._v(" "),(_vm.showWalletConnectionError && _vm.connectedExternalWallet.ethAddress)?_c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.showWalletConnectionError),callback:function ($$v) {_vm.showWalletConnectionError=$$v},expression:"showWalletConnectionError"}},[_c('div',{staticClass:"modalBackground pa-9 wallet-connection-error-modal text-center"},[_c('v-icon',{staticClass:"close-icon",attrs:{"size":"24px","color":"accentLight"},on:{"click":function($event){return _vm.toggleWalletConnectionError(false)}}},[_vm._v("\n        close\n      ")]),_vm._v(" "),(
          _vm.walletConnectionError.code ===
            _vm.walletConnectionErrorCode.MISSING_PROVIDER
        )?_c('div',{staticClass:"what-is-wallet-container"},[_c('h1',[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.wallet.connectWeb3Wallet.installProviderDialog.title',
              {
                provider: _vm.capitalize(_vm.connectedExternalWallet.walletProvider),
              }
            ))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              "components.home.walletConnectionErrorBanner.missingProvider.description",
              {
                provider: _vm.capitalize(_vm.connectedExternalWallet.walletProvider),
                address: _vm.connectedExternalWallet.ethAddress,
              }
            ))+"\n        ")]),_vm._v(" "),_c('button',{staticClass:"gala-btn medium"},[_c('a',{attrs:{"href":_vm.missingProviderLink,"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'components.wallet.connectWeb3Wallet.installProviderDialog.goToWebsite',
                {
                  provider: _vm.capitalize(
                    _vm.connectedExternalWallet.walletProvider
                  ),
                }
              ))+"\n          ")])])]):_c('div',[_c('h1',{staticClass:"font-weight-bold px-1 mb-4 create-wallet-header text-center"},[_vm._v("\n          "+_vm._s(_vm.shortMessage)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.walletConnectionError.message))]),_vm._v(" "),(
            _vm.walletConnectionError.code ===
              _vm.walletConnectionErrorCode.MISSING_BROWSER_CONNECTION
          )?_c('button',{staticClass:"gala-btn medium",on:{"click":_vm.handleConnectClick}},[_vm._v("\n          "+_vm._s(_vm.$t('components.home.walletConnectionErrorBanner.connect'))+"\n        ")]):_vm._e()])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }