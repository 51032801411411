export function fixExploitUri(uri: any) {
  function fixIt(text: string) {
    const url = new URL(text);
    if (url?.protocol && url.protocol.toLowerCase() !== 'javascript:') {
      return url.href;
    }
    return '';
  }
  if (typeof uri === 'string') {
    return fixIt(uri);
  } else if (uri?.isArray()) {
    return uri.map((item: any) => {
      if (typeof item === 'string') {
        return fixIt(item);
      }
      return item;
    });
  }
  return uri;
}
