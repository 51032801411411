













































































import { Component, Mixins } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { IExchangeReward, IInventoryState } from '~/store/inventory/types';
import { IRootState } from '~/store/types';
import { getChainInfo } from '~/data/chain_info';
import ItemCard from '~/components/Games/Items/ItemCard.vue';
import GameActionButton from '~/components/Games/GameActionButton.vue';
import { GameActionButtonHost } from '~/mixins';

const gyriChainInfo = getChainInfo('GYRI');

@Component({
  components: {
    ItemCard,
    GameActionButton,
  },
})
export default class GyriExchangeRewards extends Mixins(GameActionButtonHost) {
  @State((inventory: IInventoryState) => inventory.mostRecentExchangeRewards, {
    namespace: 'inventory',
  })
  private readonly rewards!: IExchangeReward[];

  @State((state: IRootState) => state.showGyriExchangeRewardsModal)
  private readonly showModal!: boolean;

  @Mutation('setShowGyriExchangeRewardsModal')
  private readonly setShowGyriExchangeRewardsModal!: (show: boolean) => void;

  private readonly gyriChainInfo = gyriChainInfo;

  created() {
    this.gameActionButtonHostCreated();
  }

  destroyed() {
    this.gameActionButtonHostDestroyed();
  }

  get sortedRewards() {
    const sortOrder = [
      'Ancient',
      'Legendary',
      'Epic',
      'Rare',
      'Uncommon',
      'Common',
    ];
    return Array.from(this.rewards).sort((a, b) => {
      const aIndex = sortOrder.indexOf(a.rarityLabel);
      const bIndex = sortOrder.indexOf(b.rarityLabel);
      if (aIndex === bIndex) {
        return a.name.localeCompare(b.name);
      }
      return aIndex - bIndex;
    });
  }

  get gameName() {
    return this.rewards?.[0]?.game ?? '';
  }

  get game() {
    const thisGame = this.games.find(g => g.name === this.gameName);

    if (thisGame) {
      const installStatus = this.gamesInstallStatuses[thisGame.codeName];
      return {
        ...thisGame,
        isInstalled: installStatus?.isInstalled ?? undefined,
        installedVersion: installStatus?.version ?? undefined,
      };
    }
  }

  close() {
    this.setShowGyriExchangeRewardsModal(false);
  }

  goToStore(gameRoute: string) {
    if (!this.$route.path.startsWith('/games/buy-item')) {
      this.$router.push(`/games/${gameRoute}`);
    }

    this.close();
  }

  rewardFullName(reward: IExchangeReward) {
    if (reward.instance !== '0') {
      return `${reward.name} #${reward.instance}`;
    }

    return reward.name;
  }
}
