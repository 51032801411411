var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[(!_vm.valid)?_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('h2',{staticClass:"mb-2"},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.greatJob'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"white--text"},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.confirm'))+"\n    ")]),_vm._v(" "),(_vm.wordsToConfirm.length)?_c('p',{staticClass:"notranslate font-weight-medium"},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.selectWord', {
          number: _vm.formattedNumber(
            _vm.wordsToConfirm[_vm.currentWordToConfirmIndex].order + 1
          ),
        }))+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.valid)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"justify-center":"","pb-6":"","pt-2":"","wrap":""}},_vm._l((_vm.randomWords),function(word){return _c('button',{key:word.word,class:{
          'gala-btn ma-2': true,
          notranslate: true,
          'accentBlue white--text': !_vm.incorrectGuess,
          'invalid error white--text': word.word === _vm.incorrectGuess,
          'accentLight textColor--text':
            _vm.incorrectGuess && word.word !== _vm.incorrectGuess,
        },attrs:{"translate":"no","disabled":!!_vm.incorrectGuess},on:{"click":function($event){return _vm.checkIfCorrect(word)}}},[_vm._v("\n        "+_vm._s(word.word)+"\n      ")])}),0)],1):_vm._e(),_vm._v(" "),(_vm.incorrectGuess)?_c('v-flex',{attrs:{"text-center":""}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.tryAgain}},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.tryAgain'))+"\n    ")])],1):_vm._e(),_vm._v(" "),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticStyle:{"max-width":"390px"},attrs:{"justify-center":"","pb-6":"","pt-2":"","mx-auto":"","wrap":""}},[_vm._l((_vm.confirmedWords),function(confirmed){return _c('v-flex',{key:confirmed.order,attrs:{"shrink":"","mr-6":""}},[_c('v-icon',{staticClass:"ml-0 text--lighten-1",attrs:{"dark":"","right":"","color":"success","size":"16"}},[_vm._v("\n          check\n        ")]),_vm._v(" "),_c('span',{staticClass:"success--text text--lighten-1 notranslate"},[_vm._v("\n          "+_vm._s(confirmed.word)+"\n        ")])],1)}),_vm._v(" "),(_vm.confirmedWords.length < 3)?_c('v-flex',{attrs:{"shrink":""}}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }