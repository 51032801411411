

















import { Component, Vue } from 'vue-property-decorator';
import { intlHead } from '~/utils/i18n_head';

@Component({
  head: intlHead(),
})
export default class PlayScreen extends Vue {
  snackbarSuccessText = '';
  snackbarErrorText = '';
  showSuccessSnackbar = false;
  showErrorSnackbar = false;

  triggerErrorSnackBar(message: string) {
    this.snackbarErrorText = message;
    this.showErrorSnackbar = true;
  }
  triggerSuccessSnackBar(message: string) {
    this.snackbarSuccessText = message;
    this.showSuccessSnackbar = true;
  }
}
