const domain = process.env.galaBaseDomain;

export default function setCookie(
  key: string,
  value: string,
  expirationHours: number | null = null,
): void {
  if (expirationHours) {
    const now = new Date();
    const expiration = now.setTime(
      now.getTime() + expirationHours * 60 * 60 * 1000,
    );

    document.cookie = `${key}=${value}; domain=${domain}; path=/; expires=${new Date(
      expiration,
    )}`;
  } else {
    document.cookie = `${key}=${value}; domain=${domain}; path=/;`;
  }
}
