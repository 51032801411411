






























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class GradientHexagon extends Vue {
  @Prop(String) readonly imgSrc!: string;
  @Prop([String, Number]) readonly text!: string | number;
  @Prop(String) readonly uniqueId!: string;
  @Prop({ type: String, default: 'blue' }) readonly color!: string;
  @Prop({ type: Number, default: 70 }) readonly imgScale!: number;
  @Prop({ type: String, default: '12vw' }) readonly width!: string;
  @Prop({ type: String, default: '80px' }) readonly minWidth!: string;
  @Prop({ type: Boolean, default: false }) readonly stroke!: boolean;

  colorOptions = {
    blue: ['rgb(40,222,247)', 'rgb(19,124,223)'],
    gold: ['#B68453', '#ECDB97'],
  };

  get svgStyles() {
    return {
      height: this.width,
      width: this.width,
      minHeight: this.minWidth,
      minWidth: this.minWidth,
    };
  }

  get imgStartCoordinate() {
    return (100 - this.imgScale) / 2;
  }
}
