


import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CoinflowEnvs, CoinflowUtils } from './lib/common';

@Component
export default class CoinflowPurchaseProtection extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  readonly args!: {
    env: CoinflowEnvs;
    merchantId: string;
  };

  partnerId: string | undefined = undefined;

  data() {
    return {
      partnerId: undefined,
    };
  }

  async mounted() {
    try {
      const pId = await new CoinflowUtils(this.args.env).getNSurePartnerId(
        this.args.merchantId,
      );
      this.partnerId = pId;
    } catch (error) {
      console.error('Failed to get partner ID:', error);
    }
  }

  get applicationId() {
    return this.args.env === 'prod'
      ? '9JBW2RHC7JNJN8ZQ'
      : 'SANDBOX_CTCE4XK53ZW0R7V1';
  }

  get sdkUrl() {
    return this.args.env === 'prod'
      ? 'https://sdk.nsureapi.com/sdk.js'
      : 'https://sdk.nsureapi.com/sdk-sandbox.js';
  }

  @Watch('partnerId')
  onPartnerIdChanged(newPartnerId: string | undefined) {
    if (!newPartnerId) return;

    const initializeScript = `window.nSureAsyncInit = function(deviceId) {
                                window.nSureSDK.init({
                                  appId: '${this.applicationId}',
                                  partnerId: '${newPartnerId}',
                                });
                              };`;

    const initializeScriptTag = document.createElement('script');
    initializeScriptTag.innerHTML = initializeScript;
    document.head.appendChild(initializeScriptTag);

    const sdkScriptTag = document.createElement('script');
    sdkScriptTag.src = this.sdkUrl;
    document.head.appendChild(sdkScriptTag);

    this.$once('hook:beforeDestroy', () => {
      document.head.removeChild(sdkScriptTag);
      document.head.removeChild(initializeScriptTag);
    });
  }
}
