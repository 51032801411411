

































import { Vue, Component, Prop } from 'vue-property-decorator';
import GalaLogo from '../UIComponents/GalaLogo.vue';

@Component({ components: { GalaLogo } })
export default class GamesBrandedModal extends Vue {
  @Prop(String) readonly header!: string;
  @Prop(String) readonly mainText!: string;
  @Prop({ type: String, default: 'Close' })
  readonly closeButtonText!: string;
  @Prop({ type: String, default: '570' })
  readonly maxWidth!: string;
  @Prop(Boolean) readonly showModal!: boolean;

  closeDialog() {
    this.$emit('close');
  }
}
