import { MutationTree } from 'vuex';
import { ethers } from 'ethers';
import { ExternalProvider } from '@ethersproject/providers';

import {
  IW3wState,
  IConnectedWallet,
  IExternalWallet,
  IWalletConnectionError,
} from './types';

export const mutations: MutationTree<IW3wState> = {
  setConnectedWallet(state: IW3wState, connectedWallet: IConnectedWallet) {
    state.connectedWallet = connectedWallet;
    state.walletConnectionError = null;
  },
  setWeb3Provider(state: IW3wState, provider: ExternalProvider) {
    state.provider = provider;
    state.web3Provider = new ethers.providers.Web3Provider(provider, 'any');
  },
  setWalletConnectionError(state: IW3wState, error: IWalletConnectionError) {
    state.walletConnectionError = error;
  },
  resetConnectedWallet(state: IW3wState) {
    state.connectedWallet = {
      address: '',
      label: '',
    };
  },
  resetWeb3Provider(state: IW3wState) {
    state.provider = null;
    state.web3Provider = null;
  },
  updateExternalWallets(
    state: IW3wState,
    {
      externalWallets,
      connectedExternalWallet,
    }: {
      externalWallets: IExternalWallet[];
      connectedExternalWallet: IExternalWallet;
    },
  ) {
    state.externalWallets = externalWallets;
    state.connectedExternalWallet = connectedExternalWallet || {
      ethAddress: '',
      walletProvider: '',
    };
  },
  toggleWalletConnectionError(state: IW3wState, show?: boolean) {
    state.showWalletConnectionError =
      show === null || show === undefined
        ? !state.showWalletConnectionError
        : show;
  },
  filterProviderOptionsByUserId(state: IW3wState, userId: string) {
    const filteredProviders = state.providerOptions.filter(option => {
      const whitelist = state.providerWhitelists.find(
        w => w.providerLabel === option.label,
      );

      return (
        !whitelist || (userId && whitelist.allowedUserIds.includes(userId))
      );
    });

    state.providerOptions = filteredProviders;
  },
};
