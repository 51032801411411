import { CachePersistor } from 'apollo-cache-persist';

export async function resetCache(cache: any, purge = true, fieldName?: string) {
  if (fieldName) {
    await cache.evict({ id: 'ROOT_QUERY', fieldName });
    const persistor = new CachePersistor({
      cache,
      // @ts-ignore
      storage: window.sessionStorage,
    });
    return persistor.persist();
  } else {
    await cache.reset();
  }

  if (purge) {
    const persistor = new CachePersistor({
      cache,
      // @ts-ignore
      storage: window.sessionStorage,
    });
    return persistor.purge();
  }
}
