import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { IGame, IUserAllowance } from '~/store/games/types';
import _matches from 'lodash.matches';
import neededAllowancesQuery from '~/queries/neededAllowances.gql';

export enum AllowanceType {
  Use = 0,
  Lock = 1,
  Spend = 2,
  Transfer = 3,
  Mint = 4,
  Swap = 5,
  Burn = 6,
}

@Component
export default class CheckForRequiredAllowances extends Vue {
  @Action('getUserAllowances', { namespace: 'games' })
  private getUserAllowances!: (payload?: {
    collection: string;
  }) => Promise<IUserAllowance[]>;

  isCheckingAllowances = false;

  private async getNeedsLockAllowance(game: IGame) {
    const { data } = await this.$apollo.query<
      {
        neededAllowances: Array<{
          needsAllowance: boolean;
          type: AllowanceType;
        }>;
      },
      { gameName: string }
    >({
      query: neededAllowancesQuery,
      fetchPolicy: 'no-cache',
      variables: {
        gameName: game.name,
      },
    });

    return data.neededAllowances.some(
      d => d.needsAllowance && d.type === AllowanceType.Lock,
    );
  }

  private async getNeedsSpendAllowance(game: IGame) {
    // This check should eventually happen on the backend when we decide how other games will set their allowance requirements
    try {
      this.isCheckingAllowances = true;
      if (game.requiredAllowances && game.requiredAllowances.length) {
        const allowancesGiven =
          game.allowancesGiven && game.allowancesGiven.length
            ? game.allowancesGiven
            : await this.getUserAllowances({
                collection: game.collection,
              });
        if (allowancesGiven) {
          // check if user has already granted allowances
          const validAllowancesWithRemainingAmounts = allowancesGiven.filter(
            allowance => {
              const valid =
                !allowance.expires || allowance.expires > Date.now();
              return (
                valid &&
                (+allowance.usesSpent < +allowance.uses ||
                  +allowance.amountSpent < +allowance.amount)
              );
            },
          );

          if (!validAllowancesWithRemainingAmounts.length) {
            this.isCheckingAllowances = false;
            return true;
          }

          const hasRequiredAllowances = game.requiredAllowances
            .map(a => ({
              // amount: a.amount, not checking for amount match until we know what the amount will be for sure
              tokenId: a.tokenId,
              action: a.action,
            }))
            .every(requiredAllowance => {
              return validAllowancesWithRemainingAmounts.some(
                _matches(requiredAllowance),
              );
            });
          this.isCheckingAllowances = false;
          return !hasRequiredAllowances;
        }
        // if no, return true
        this.isCheckingAllowances = false;
        return true;
      }
      this.isCheckingAllowances = false;
      return false;
    } catch (error) {
      this.isCheckingAllowances = false;
      throw error;
    }
  }

  async checkAllowanceRequirement(game: IGame) {
    const [needsSpendAllowance, needsLockAllowance] = await Promise.all([
      this.getNeedsSpendAllowance(game),
      this.getNeedsLockAllowance(game),
    ]);

    return {
      needsSpendAllowance,
      needsLockAllowance,
    };
  }
}
