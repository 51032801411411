


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';

@Component({ components: { CountryFlag } })
export default class DropUp extends Vue {
  @Prop({ type: Array, default: () => {} })
  readonly options!: Array<{
    value: string;
    displayText: string;
    flagCode: string;
  }>;

  @Prop({ type: String, required: true })
  readonly value!: string;

  isOpen = false;

  get sortedOptions() {
    return [
      ...this.options.filter(o => o.value !== this.value),
      this.selectedOption,
    ].filter(Boolean);
  }

  get selectedOption() {
    return this.options.find(option => option.value === this.value);
  }

  get selectedFlagCode() {
    return this.selectedOption?.flagCode;
  }

  get selectedDisplayText() {
    return this.selectedOption?.displayText ?? '';
  }
}
