import { Component, Vue } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { IGame } from '~/store/games/types';

/**
 * Use me when you want to use the GameActionButton component
 * Make sure to call my gameActionButtonHostCreated() function
 * when your created() function is called. And my
 * gameActionButtonHostDestroyed() function when your
 * destroyed() function is called. Use my gamesInstallStatuses
 * property to get the install status of the games.
 * (most or all of this logic could probably be moved into
 * the GameActionButton component, but that looked like a
 * bigger refactor)
 */

@Component
export default class GameActionButtonHost extends Vue {
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @State(games => games.games, { namespace: 'games' })
  games!: IGame[];

  launcherMessageProcessedEventName = 'launcherMessageProcessed';
  renderIndex = 0;
  gamesInstallStatuses: {
    [key: string]: { isInstalled: boolean; version: string };
  } = {};

  gameActionButtonHostCreated() {
    this.checkForGamesInstalled();
  }

  gameActionButtonHostDestroyed(
    args: { disableAutoReconnect?: boolean } = {},
  ) {}

  async checkForGamesInstalled() {
    // TODO_LAUNCHER: Perhaps look into alternate ways to determine game installs. Leaving this snippet behind for reference.
    // this.gamesInstallStatuses[event.message.gameName] = {
    //   isInstalled: event.message.isInstalled,
    //   version: event.message.version,
    // };
  }
}
