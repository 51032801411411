import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import {
  IEnabledTwoFaCheckpoints,
  TwoFaCheckpoint,
} from '~/types/two-fa-checkpoints';
import { getSnackbarMessageKeyForTwoFaError } from '~/utils';
import { isTwoFaError } from '~/utils/errorCodes';

/*
 * This mixin provides a "this.doAuthQuery" function that you can call.
 * It takes two arguments: first a delegate function to execute
 * a GraphQL query that might require 2FA. Second, the TwoFaCheckpoint
 * that, if enabled, will cause 2FA to be required for the query.
 * If 2FA might not be required, the delegate function will first be
 * called with no arguments. If it throws an InvalidTwoFaError, then
 * the user will be prompted for their 2FA code, and the delegate
 * will be called again with that 2FA code as its only argument.
 * The "this.doAuthQuery" function is an async function that either
 * returns undefined (if the user canceled the prompt) or else whatever
 * the query delegate function returned.
 *
 * To use this mixin, your component template must contain an element that looks EXACTLY like this:

<TwoFactorAuthInputPrompt
  v-model="showAuthDialog"
  :inProgress="twoFaInProgress || twoFaFinished"
  :softHide="softHideAuthDialog"
  @submit="onTwoFaSubmit"
/>

import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
*/

@Component
export default class AuthQuery extends Vue {
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @State(profile => profile.user.enabledTwoFaCheckpoints, {
    namespace: 'profile',
  })
  private twoFaCheckpointSettings!: IEnabledTwoFaCheckpoints;

  @State(profile => profile.user.twoFaEnabled, { namespace: 'profile' })
  private twoFaIsEnabled!: boolean;

  showAuthDialog = false;
  softHideAuthDialog = false;
  softHideAuthDialogDuringQuery = false;
  twoFaInProgress = false;
  twoFaFinished = false;
  queryPromiseFulfill?: (value: unknown) => void;
  queryPromiseReject?: (value: Error) => void;

  queryDelegate: (twoFaPin: string) => any = () => {};

  unmounted() {
    this.fulfill(undefined);
  }

  errorCaptured(err: Error) {
    this.reject(err);
  }

  async doAuthQuery<TReturnType>(
    queryDelegate: (twoFaPin: string) => Promise<TReturnType>,
    twoFaCheckpoint?: TwoFaCheckpoint,
    options: { hideDialogDuringQuery?: boolean } = {},
  ): Promise<TReturnType> {
    if (this.queryPromiseReject) {
      this.reject(
        new Error(
          'Starting a new auth query flow, but the previous one does not seem to be finished. There should be only one at a time.',
        ),
      );
    }

    this.softHideAuthDialogDuringQuery = Boolean(options.hideDialogDuringQuery);
    this.twoFaFinished = false;
    this.queryDelegate = queryDelegate;

    // If we might not need a 2FA pin, try to execute the query without a pin.
    if (
      !this.twoFaIsEnabled ||
      !twoFaCheckpoint ||
      !this.twoFaCheckpointSettings?.[twoFaCheckpoint]
    ) {
      if (this.softHideAuthDialogDuringQuery) {
        this.softHideAuthDialog = true;
      }

      try {
        return await this.queryDelegate('');
      } catch (error) {
        if (!isTwoFaError(error)) {
          this.$sentry.captureException(error);
          throw error;
        }
      } finally {
        this.softHideAuthDialog = false;
      }
    }

    // If we do need 2FA pin (either because the server
    // said so when we made the previous request, or because
    // the user's settings in the Vuex store suggest we do)
    // show the 2FA dialog and return a promise that
    // we'll resolve when the 2FA dialog finishes.
    this.showAuthDialog = true;
    return new Promise((fulfill, reject) => {
      this.queryPromiseFulfill = fulfill;
      this.queryPromiseReject = reject;
    }) as any;
  }

  // This should be used in the template of the component
  // that uses this mixin.
  /* tslint:disable-next-line:no-unused-variable */
  private async onTwoFaSubmit(twoFaPin: string) {
    try {
      this.twoFaInProgress = true;

      if (this.softHideAuthDialogDuringQuery) {
        this.softHideAuthDialog = true;
      }

      const returnValue = await this.queryDelegate(twoFaPin);
      this.fulfill(returnValue);
    } catch (error) {
      const twoFaErrorSnackbarMessageKey = getSnackbarMessageKeyForTwoFaError(
        error,
      );
      if (twoFaErrorSnackbarMessageKey) {
        this.updateSnackbarErrorText(this.$t(twoFaErrorSnackbarMessageKey));
        this.toggleErrorSnackbar();
      } else {
        return this.reject(error as Error);
      }
    } finally {
      this.twoFaInProgress = false;
      this.softHideAuthDialog = false;
    }
  }

  private reject(error: Error) {
    this.twoFaFinished = true;
    this.showAuthDialog = false;
    const reject = this.queryPromiseReject;
    if (reject) {
      this.queryPromiseFulfill = undefined;
      this.queryPromiseReject = undefined;
      reject(error);
    }
  }

  private fulfill(result: any) {
    this.twoFaFinished = true;
    this.showAuthDialog = false;
    const fulfill = this.queryPromiseFulfill;
    if (fulfill) {
      this.queryPromiseFulfill = undefined;
      this.queryPromiseReject = undefined;
      fulfill(result);
    }
  }

  @Watch('showAuthDialog')
  // This should be used in the template of the component
  // that uses this mixin.
  /* tslint:disable-next-line:no-unused-variable */
  private handleShowAuthDialogChanged(show: boolean) {
    if (!show) {
      this.fulfill(undefined);
    }
  }
}
