
















import { DocumentNode } from 'graphql';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { sanitizeLegalDocumentHtml } from '../../utils';

@Component
export default class LegalDocument extends Vue {
  @Prop({ required: true, type: Object })
  private readonly query!: DocumentNode;

  @Prop({ required: true, type: Function })
  private readonly contentSelector!: (queryResponse: any) => any;

  legalDocumentHtml = '';
  async created() {
    const { data } = await this.$apollo.query({
      query: this.query,
      fetchPolicy: 'no-cache',
    });
    const content = this.contentSelector(data);
    if (content) {
      this.legalDocumentHtml = sanitizeLegalDocumentHtml(content);
    }
  }
}
