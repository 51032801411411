




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { sanitizeLegalDocumentHtml } from '../../utils';
import Checkbox from '../UIComponents/Checkbox.vue';
import AgreementStatement from './AgreementStatement.vue';
@Component({
  components: { Checkbox, AgreementStatement },
})
export default class ScrollToAccept extends Vue {
  @Prop({ type: String, required: true })
  readonly documentName!: string;

  @Prop({ type: String, required: true })
  readonly htmlContent!: string;

  @Prop({ type: String })
  readonly documentVersion!: string;

  @Prop({ type: String })
  readonly focusTrackName!: string;

  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(isChecked: boolean) {
    this.$emit('input', isChecked);
  }

  checkboxDisabled = true;

  get fetching() {
    return this.htmlContent === '';
  }

  get sanitizedHtmlContent() {
    return sanitizeLegalDocumentHtml(this.htmlContent);
  }

  onScroll({
    target: { scrollTop, clientHeight, scrollHeight },
  }: {
    target: HTMLElement;
  }) {
    if (!this.htmlContent) return;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      this.checkboxDisabled = false;
    }
  }

  toggleOptIn(isChecked: boolean) {
    if (this.checkboxDisabled) return;

    if (this.focusTrackName) {
      this.$emit('track-focus', this.focusTrackName, {
        checkBoxChecked: isChecked,
      });
    }

    this.localValue = isChecked;
  }
}
