


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class VerifyEmailBlock extends Vue {
  @Prop(String) readonly email!: boolean;
}
