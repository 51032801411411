import { WalletConnectionErrorCode } from '~/store/web3_wallet/types';

export class WalletConnectionError extends Error {
  cause?: WalletConnectionErrorCode;
  code?: number;

  constructor(message: string, cause?: WalletConnectionErrorCode) {
    super(message);
    this.cause = cause;
  }
}
