






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component
export default class NotificationBell extends Vue {
  @State(notifications => notifications.newNotifications.total, {
    namespace: 'notifications',
  })
  readonly newNotificationCount!: number;
}
