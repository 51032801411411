
































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CopyToClipboard } from '~/mixins';
@Component
export default class ShareButtons extends Mixins(CopyToClipboard) {
  galaLogo = require('@/assets/logos/gala-logo_horizontal_white.png');
  @Prop(String) readonly shareUrl!: string;
  @Prop(String) readonly referralCode!: string;
  @Prop({ type: Boolean, default: false }) readonly isModal!: boolean;
  @Prop({ type: Object, default: {} }) readonly currentUser!: {
    firstName: string;
    lastName: string;
  };

  get inviteCopyText() {
    if (
      // !this.referralCode ||
      !this.shareUrl ||
      !this.currentUser.firstName ||
      !this.currentUser.lastName
    ) {
      return '';
    }

    return this.$t('components.share.shareButtons.inviteCopyUrlText', {
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      // referralCode: this.referralCode,
      shareUrl: this.shareUrl,
    }) as string;
  }

  get inviteNativeShareText() {
    if (
      // !this.referralCode ||
      !this.shareUrl ||
      !this.currentUser.firstName ||
      !this.currentUser.lastName
    ) {
      return '';
    }

    return this.$t('components.share.shareButtons.inviteCopyUrlText', {
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      shareUrl: this.shareUrl,
      // referralCode: this.referralCode,
    }) as string;
  }

  get nativeShareSupported() {
    const share = !!(navigator as any).share;
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const nonSafariOrIos = !isSafari || isIOS;
    return !!(share && nonSafariOrIos);
  }

  triggerNativeShare() {
    const nativeShare = (navigator as any).share;
    if (nativeShare && this.inviteNativeShareText && this.shareUrl) {
      try {
        (navigator as any).share({
          title: `Check out Gala!`,
          text: this.inviteNativeShareText,
          url: this.shareUrl,
        });
      } catch (error) {
        this.$sentry.captureException(error);
        console.warn(error);
      }
    }
  }
}
