export enum FiatProvider {
  MOONPAY = 'moonpay',
  STRIPE = 'stripe',
  COINFLOW = 'coinflow',
}

export const FiatIcons: Record<FiatProvider, string> = {
  [FiatProvider.MOONPAY]: require(`@/assets/logos/moonpay-logo.png`),
  [FiatProvider.STRIPE]:
    'https://images.ctfassets.net/fzn2n1nzq965/HTTOloNPhisV9P4hlMPNA/cacf1bb88b9fc492dfad34378d844280/Stripe_icon_-_square.svg?q=20&w=48',
  [FiatProvider.COINFLOW]: require('@/assets/logos/coinflow-logo.png'),
};
