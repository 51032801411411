import { isInvalidTwoFaError, isTwoFaRateLimitError } from './errorCodes';

export default function getSnackbarMessageKeyForTwoFaError(
  err: any,
): string | undefined {
  if (isInvalidTwoFaError(err)) {
    return 'common.twoFa.twoFaInvalid';
  } else if (isTwoFaRateLimitError(err)) {
    return 'common.twoFa.twoFaRateLimited';
  }

  return undefined;
}
