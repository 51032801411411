

















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import {
  ISanityPromoPack,
  ISanityPromoItem,
} from '../../store/cms_content/types';
import { IUser } from '../../store/profile/types';
import CustomExchangeReveal, {
  CustomExchangeType,
} from '~/components/Games/Items/CustomExchangeReveal.vue';

enum PromoState {
  default = 'default',
  exchange = 'exchange',
  postAction = 'postAction',
}

@Component({
  components: { CustomExchangeReveal },
})
export default class PromoPackDialog extends Vue {
  @State('showPromoPackDialog') readonly showPromoPackDialogState!: boolean;
  @State('activePromoPack') readonly activePromoPack?: ISanityPromoPack;
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Action('setVideoBoxType', { namespace: 'customReveal' })
  private setVideoBoxType!: (payload: any) => void;

  @Action('promptToRegister')
  private promptToRegister!: (options?: {
    redirectAfterLoginPath?: string;
  }) => void;

  @Mutation togglePromoPackDialog!: (args: { active: boolean }) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  state = PromoState.default;
  code?: string;
  showMoreInfo = false;

  get promoPack() {
    const loggedInPromo = this.activePromoPack?.promoItems?.find(
      x => !x.showCondition || x.showCondition === 'loggedIn',
    );
    const loggedOutPromo = this.activePromoPack?.promoItems?.find(
      x => !x.showCondition || x.showCondition === 'loggedOut',
    );
    const postActionPromo = this.activePromoPack?.promoItems?.find(
      x => x.showCondition === 'postAction',
    );

    if (this.state === PromoState.postAction && postActionPromo) {
      return postActionPromo;
    } else if (this.currentUser.loggedIn && loggedInPromo) {
      return loggedInPromo;
    } else if (!this.currentUser.loggedIn && loggedOutPromo) {
      return loggedOutPromo;
    }
  }

  get showMoreInfoPromoPack() {
    return (
      this.promoPack?.hasMoreInfo &&
      this.activePromoPack?.promoItems?.find(
        x => x.showCondition === 'moreInfo',
      )
    );
  }

  get showPromoPackDialog() {
    return this.showPromoPackDialogState && !!this.promoPack;
  }

  set showPromoPackDialog(value: boolean) {
    this.togglePromoPackDialog({ active: value });
  }

  get exchangeConfig() {
    return {
      type: CustomExchangeType.CODE,
      autoOpen: true,
      data: {
        code: this.code,
        exchangeRevealVideo: this.promoPack?.exchangeRevealVideo,
      },
    };
  }

  created() {
    if (this.$route.query.c) {
      this.code = this.$route.query.c as string;
    }
  }

  trackPromoPackCloseEvent = () => {
    this.$ua.trackGalaPackClosedEvent({
      path: this.$route.path,
    });
  };

  handleAction(promoPack: ISanityPromoItem) {
    if (promoPack?.buttonAction === 'signUp') {
      this.signUp();
    } else if (promoPack?.buttonAction === 'redeem') {
      if (!this.code) {
        this.updateSnackbarErrorText('Redemption code not found');
        this.toggleErrorSnackbar();
        return;
      }
      if (promoPack.exchangeRevealVideo) {
        this.setVideoBoxType(promoPack.exchangeRevealVideo);
        this.state = PromoState.exchange;
      } else {
        this.togglePromoPackDialog({ active: false });
        this.$router.push({
          path: 'redeem',
          query: { c: this.$route.query.c },
        });
      }
    } else if (promoPack.buttonAction === 'link' && promoPack.buttonLink) {
      this.togglePromoPackDialog({ active: false });
      if (promoPack.buttonTarget) {
        window.open(promoPack.buttonLink, '_blank');
      } else {
        this.$router.push(promoPack.buttonLink);
      }
    } else {
      if (promoPack.showCondition === 'moreInfo') {
        this.showMoreInfo = false;
      } else {
        this.togglePromoPackDialog({ active: false });
      }
    }
  }

  signUp() {
    this.$ua.trackGalaPackSignUpClickEvent({
      loginMethod: 'any',
      path: this.$route.path,
    });
    this.promptToRegister({ redirectAfterLoginPath: this.$route.fullPath });
  }

  signUpGoogle() {
    this.$ua.trackGalaPackSignUpClickEvent({
      loginMethod: 'Google',
      path: this.$route.path,
    });
    this.$auth.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
        connection: 'google-oauth2',
      },
    });
  }

  signUpApple() {
    this.$ua.trackGalaPackSignUpClickEvent({
      path: this.$route.path,
      loginMethod: 'Apple',
    });
    this.$auth.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
        connection: 'apple',
      },
    });
  }

  signUpEmail() {
    this.$ua.trackGalaPackSignUpClickEvent({
      path: this.$route.path,
      loginMethod: 'Email',
    });
    this.$auth.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
        connection: 'Username-Password-Authentication',
      },
    });
  }

  handleExchangeComplete() {
    const postActionPromo = this.activePromoPack?.promoItems?.find(
      x => x.showCondition === 'postAction',
    );
    if (postActionPromo) {
      this.state = PromoState.postAction;
    } else {
      this.showPromoPackDialog = false;
    }
  }

  handlePromoPackClose() {
    this.showPromoPackDialog = false;
  }

  get logo() {
    return require(`@/assets/logos/gala-games-only-logo.svg`);
  }

  get poweredByGala() {
    return require(`@/assets/logos/powered-by-gala-logo.svg`);
  }

  get googleIcon() {
    return require(`@/assets/icons/icon_google.svg`);
  }

  get appleIcon() {
    return require(`@/assets/icons/icon_apple_logo.svg`);
  }
}
