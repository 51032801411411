import {
  LocalStorageKeys,
  getFromBrowserStorage,
  removeFromBrowserStorage,
  setInBrowserStorage,
} from './browserStorage';

export function setRedirectAfterLogin(route: string) {
  setInBrowserStorage(LocalStorageKeys.redirectAfterLogin, route);
}

export function consumeRedirectAfterLogin() {
  const value = getFromBrowserStorage<string>(
    LocalStorageKeys.redirectAfterLogin,
  );
  removeFromBrowserStorage(LocalStorageKeys.redirectAfterLogin);
  return value;
}
