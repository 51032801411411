import axios from 'axios';
import { ISanityGame } from './sanity.service';

export interface IBuildInfo {
  tenantId: string;
  platform: string;
  version: string;
  dateUploaded: Date;
  run?: string[];
  sync?: string[];
  redirectUrl?: string;
}
export interface ILatestBuilds {
  [tenantId: string]: {
    latest: { [platform: string]: string }; // Version
    tenantId: string;
  };
}

class CreatorsService {
  baseUrl = process.env.creatorsPublicUrl;
  async getLatestBuilds(): Promise<ILatestBuilds> {
    const results = await axios.get(
      `${this.baseUrl}/builds/${process.env.creatorsEnv}/latest`,
    );
    return results.data;
  }
  async getBuild(url: string): Promise<IBuildInfo> {
    const results = await axios.get(`${this.baseUrl}/${url}`);
    return results.data;
  }
  async getGames(locale: string): Promise<ISanityGame[]> {
    const results = await axios.get(`${this.baseUrl}/games/${locale}`);
    return results.data;
  }
}

export default new CreatorsService();
