



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import GradientHexagon from '~/components/UIComponents/GradientHexagon.vue';
import {
  getFromBrowserStorage,
  setInBrowserStorage,
  LocalStorageKeys,
} from '~/utils/browserStorage';

@Component({
  components: { GradientHexagon, FullScreenDialog },
})
export default class TwoFactorAuthPrompt extends Vue {
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State(profile => profile.user.twoFaEnabled, { namespace: 'profile' })
  twoFaEnabled!: boolean;
  @State(profile => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;

  @Mutation('updateShowSettingsPage')
  private updateShowSettingsPage!: (args: any) => void;

  securityIcon = require('@/assets/icons/icon_security_2FWA.png');
  modalControl = true;
  isInitialWalletCreation = true;
  isUserSettingUp2FA = true;
  delayShow = false;

  created() {
    if (this.walletExists || this.walletConnected) {
      this.isInitialWalletCreation = false;
    }

    if (this.$route.query.component !== 'TwoFa') {
      this.isUserSettingUp2FA = false;
    }

    const delay = getFromBrowserStorage<number>(
      LocalStorageKeys.hideTwoFaSetup,
    );
    if (delay && delay > Date.now()) {
      this.delayShow = true;
    }
  }

  get showModal() {
    return (
      this.modalControl &&
      this.loggedIn &&
      (this.walletExists || this.walletConnected) &&
      !this.twoFaEnabled &&
      !this.isInitialWalletCreation &&
      !this.isUserSettingUp2FA &&
      !this.delayShow
    );
  }

  handleSetupClick() {
    this.modalControl = false;
    this.updateShowSettingsPage('Two-Factor Authentication');
    this.$router.push('/account');
  }

  @Watch('walletExists')
  onWalletExistsChange(val: boolean | null, oldValue: boolean | null) {
    // This condition is only met when a user who already has a wallet logs in.
    // On initial wallet creation, the oldValue for walletExists will be false rather than null.
    if (val === true && oldValue === null) {
      this.isInitialWalletCreation = false;
    }
  }

  @Watch('showModal')
  setModalDelay(value: boolean) {
    if (value === false) {
      setInBrowserStorage(
        LocalStorageKeys.hideTwoFaSetup,
        (Date.now() + 1000 * 60 * 60 * 24 * 7).toString(), // Hide for 1 week
      );
    }
  }
}
