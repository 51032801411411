import { ActionTree } from 'vuex';
import { IGamesState, IUserAllowance, IGameActiveUsers } from './types';
import { IRootState } from '../types';
import itemsForSaleByGala from '~/queries/gameItemProducts.gql';
import tokenAllowancesQuery from '~/queries/tokenAllowances.gql';
import gameActiveUsers from '~/queries/gameActiveUsers.gql';
import addActiveUser from '~/mutations/gameAddActiveUser.gql';
// import deleteActiveUser from '~/mutations/gameDeleteActiveUser.gql';

export const actions: ActionTree<IGamesState, IRootState> = {
  async gameActiveUsers(
    { commit },
    {
      gameName,
      inDataBricks,
    }: {
      gameName: string;
      inDataBricks?: boolean;
    },
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const results = (await client.query({
        query: gameActiveUsers,
        variables: { gameName, inDataBricks },
        fetchPolicy: 'cache-first',
      })) as { data: { gameActiveUsers: IGameActiveUsers } };

      commit('updateGameActiveUsers', {
        gameName,
        activeUsers: results.data.gameActiveUsers.activeUsers,
      });
    }
  },

  async addActiveUser(
    { commit },
    {
      gameName,
      cacheTtl,
    }: {
      gameName: string;
      cacheTtl?: number;
    },
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      await client.mutate({
        mutation: addActiveUser,
        variables: { gameName, cacheTtl },
      });

      commit('addGameActiveUser', {
        gameName,
      });
    }
  },

  async getItemsForSaleByGala(
    { commit },
    gameItemProductsInput: {
      game?: string;
      baseId?: string;
      topItems?: number;
    } = {},
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;
      const results = (await client.query({
        query: itemsForSaleByGala,
        variables: {
          gameItemProductsInput,
        },
        fetchPolicy: 'no-cache',
      })) as { data: { gameItemProducts: any[] } };
      commit('updateItemsForSaleByGala', results.data.gameItemProducts);
    }
  },

  async getUserAllowances({ commit }, { collection }: { collection: string }) {
    if (this.app.apolloProvider) {
      const apolloClient = this.app.apolloProvider.clients.gateway;

      const { data } = (await apolloClient.query({
        query: tokenAllowancesQuery,
        variables: {
          options: { collection },
          isIssuer: true,
        },
        fetchPolicy: 'no-cache',
      })) as {
        data: {
          tokenAllowances: IUserAllowance[];
        };
      };

      commit('updateAllowancesGiven', {
        collection,
        allowances: data.tokenAllowances || [],
      });
      return data.tokenAllowances || [];
    }
  },
};
