import axios from 'axios';

class IpifyService {
  async getIpAddress(): Promise<string> {
    try {
      const response = await Promise.any([
        axios.get('https://api64.ipify.org?format=json', {
          timeout: 3000,
        }),
        axios.get('https://api.ipify.org?format=json', {
          timeout: 3000,
        }),
      ]);

      return response.data.ip;
    } catch (error) {
      return '';
    }
  }
}
export default new IpifyService();
