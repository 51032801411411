var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetching)?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('div',[(_vm.termsAndConditions === 'scroll-to-accept')?_c('ScrollToAccept',{attrs:{"documentName":_vm.$t('components.legalAgreements.legalAgreements.termsOfService'),"documentVersion":_vm.termsAndConditionsData.version,"htmlContent":_vm.termsAndConditionsData.content,"focusTrackName":"Terms of Service"},model:{value:(_vm.termsAndConditionsStatus),callback:function ($$v) {_vm.termsAndConditionsStatus=$$v},expression:"termsAndConditionsStatus"}}):(_vm.termsAndConditions === 'checkbox')?_c('AgreementStatement',{model:{value:(_vm.termsAndConditionsStatus),callback:function ($$v) {_vm.termsAndConditionsStatus=$$v},expression:"termsAndConditionsStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.termsAndConditionsData.content,"title":_vm.$t('components.legalAgreements.legalAgreements.termsOfService')}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.termsOfService'))+"\n      ")]),_vm._v(" "),(
          _vm.additionalTermsOfService &&
            _vm.additionalTermsOfService.href &&
            _vm.additionalTermsOfService.text
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.and'))+"\n        "),_c('a',{staticClass:"accentTeal--text",attrs:{"href":_vm.additionalTermsOfService.href,"target":"_blank","rel":"noreferrer noopener","aria-label":_vm.additionalTermsOfService.text}},[_vm._v("\n          "+_vm._s(_vm.additionalTermsOfService.text)+"\n        ")])]):_vm._e()],1):(_vm.termsAndConditionsWithPrivacyPolicy === 'checkbox')?_c('AgreementStatement',{attrs:{"agreementText":_vm.$t('components.legalAgreements.agreementStatement.simpleAgreement')},model:{value:(_vm.termsAndConditionsWithPrivacyPolicyStatus),callback:function ($$v) {_vm.termsAndConditionsWithPrivacyPolicyStatus=$$v},expression:"termsAndConditionsWithPrivacyPolicyStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.termsAndConditionsData.content,"title":_vm.$t('components.legalAgreements.legalAgreements.termsOfService')}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.termsOfService'))+"\n      ")]),_vm._v(" "),(
          _vm.additionalTermsOfService &&
            _vm.additionalTermsOfService.href &&
            _vm.additionalTermsOfService.text
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.and'))+"\n        "),_c('a',{staticClass:"accentTeal--text",attrs:{"href":_vm.additionalTermsOfService.href,"target":"_blank","rel":"noreferrer noopener","aria-label":_vm.additionalTermsOfService.text}},[_vm._v("\n          "+_vm._s(_vm.additionalTermsOfService.text)+"\n        ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.battlestarGalacticaTermsAndConditions === 'checkbox')?_c('AgreementStatement',{model:{value:(_vm.battleStarGalacticaTermsAndConditionsStatus),callback:function ($$v) {_vm.battleStarGalacticaTermsAndConditionsStatus=$$v},expression:"battleStarGalacticaTermsAndConditionsStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.battlestarGalacticaTermsAndConditionsData.content,"title":_vm.$t('components.legalAgreements.legalAgreements.termsOfService')}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.termsOfService'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.privacyPolicy === 'checkbox' && _vm.privacyPolicyData.content)?_c('AgreementStatement',{model:{value:(_vm.privacyPolicyStatus),callback:function ($$v) {_vm.privacyPolicyStatus=$$v},expression:"privacyPolicyStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.privacyPolicyData.content,"title":_vm.$t('common.misc.privacyPolicy')}},[_vm._v("\n        "+_vm._s(_vm.$t('common.misc.privacyPolicy'))+"\n      ")]),_vm._v(" "),(
          _vm.additionalPrivacyPolicy &&
            _vm.additionalPrivacyPolicy.href &&
            _vm.additionalPrivacyPolicy.text
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.and'))+"\n        "),_c('a',{staticClass:"accentTeal--text",attrs:{"href":_vm.additionalPrivacyPolicy.href,"target":"_blank","rel":"noreferrer noopener","aria-label":_vm.additionalPrivacyPolicy.text}},[_vm._v("\n          "+_vm._s(_vm.additionalPrivacyPolicy.text)+"\n        ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.nodeNonInvestmentStatement)?_c('AgreementStatement',{attrs:{"agreementText":_vm.nodeNonInvestmentStatement.text},model:{value:(_vm.nodeNonInvestmentStatus),callback:function ($$v) {_vm.nodeNonInvestmentStatus=$$v},expression:"nodeNonInvestmentStatus"}},[(_vm.nodeNonInvestmentStatement.trailingLink)?_c('a',{staticClass:"accentTeal--text",attrs:{"href":_vm.nodeNonInvestmentStatement.trailingLink.href,"target":"_blank","rel":"noreferrer noopener"}},[_vm._v("\n        "+_vm._s(_vm.nodeNonInvestmentStatement.trailingLink.text)+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.nodeNonInvestmentStatement.trailingLink &&
            _vm.nodeNonInvestmentStatement.trailingLink.text2
        )?_c('span',[_vm._v("\n        "+_vm._s(_vm.nodeNonInvestmentStatement.trailingLink.text2)+"\n      ")]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }