

































































































































import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DisplayRecoveryPhrase extends Vue {
  @Prop(Boolean) readonly onCurrentStep!: boolean;
  @Prop(Boolean) readonly showRecoveryPhrase!: boolean;
  @Prop() readonly mnemonic!: string[];
  @Prop() readonly brand!: string;

  writtenDown = false;
  doubleConfirm = false;
  showInstructionsDialog = false;
  get valid() {
    return this.writtenDown && this.doubleConfirm;
  }

  get instructions() {
    return [
      {
        text: this.$t(
          'components.newWallet.displayRecoveryPhrase.correctOrder',
        ) as string,
        color: 'success',
        icon: 'check_circle',
      },
      {
        text: this.$t(
          'components.newWallet.displayRecoveryPhrase.tripleCheck',
        ) as string,
        color: 'success',
        icon: 'check_circle',
      },
      {
        text: this.$t(
          'components.newWallet.displayRecoveryPhrase.storePhraseSafely',
        ) as string,
        color: 'success',
        icon: 'check_circle',
      },
      {
        text: this.$t(
          'components.newWallet.displayRecoveryPhrase.neverSharePhrase',
        ) as string,
        color: 'error',
        icon: 'error',
      },
      {
        text: this.$t(
          'components.newWallet.displayRecoveryPhrase.neverDigitallyCopyPhrase',
        ) as string,
        color: 'error',
        icon: 'error',
      },
    ];
  }

  @Emit('acknowledge-mnemonic')
  acknowledgementsValidChange(boxChecked: boolean) {
    return boxChecked;
  }

  @Watch('valid')
  handleValid(isValid: boolean) {
    this.acknowledgementsValidChange(isValid);
  }
  @Watch('onCurrentStep')
  handleonCurrentStepChange(onCurrentStep: boolean) {
    if (onCurrentStep) {
      this.writtenDown = false;
      this.doubleConfirm = false;
      this.$emit('on-current-step', true);
    }
  }
}
