var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"search",attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"color":"white","no-filter":"","item-text":"value","placeholder":"Search items and games","prepend-inner-icon":"mdi-magnify","return-object":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.handleSelection},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var tile = ref.tile;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"mr-auto pr-4 list-item"},[_vm._v("\n        "+_vm._s(item.value)+"\n        "),(item.game)?_c('span',{staticClass:"grey--text"},[_vm._v("- "+_vm._s(item.game))]):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"search-pill",style:({
          'background-color': ("var(" + (item.type === 'game'
              ? '--v-accentBlue-base'
              : '--v-primary-darken1') + ")"),
        })},[_vm._v("\n        "+_vm._s(item.type)+"\n      ")])])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }