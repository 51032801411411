



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class NoticeDialog extends Vue {
  @Prop({ type: Boolean })
  readonly showModal!: boolean;

  @Prop({ type: String })
  readonly content!: string;

  get translatedContent() {
    return this.$t(this.content);
  }
}
