import { Component, Vue, Prop } from 'vue-property-decorator';
import { IGameItemForSaleByGala } from '~/store/games/types';

@Component
export default class ProductSupplyMix extends Vue {
  @Prop(Object) readonly item!: IGameItemForSaleByGala;

  isTiered = false;

  get shouldHideRemaining() {
    // Quick front-end solution to hide remaining supply for Spider Tanks upgrade components,
    // before longer-term solution of supporting truly infinite supply.
    return (
      this.item?.game === 'Spider Tanks' &&
      ['Component', 'Arachnium', 'Drop Pods'].includes(
        this.item?.category ?? '',
      )
    );
  }

  public created() {
    this.isTiered = !!this.item.tiers;
  }

  get rarityData() {
    return this.item ? this.item.rarity : undefined;
  }

  get supplyInTier() {
    return this.item.tiers[this.item.currentTier - 1].supply;
  }

  get totalTieredSupply() {
    return !this.item.tiers
      ? 0
      : this.item.tiers.reduce((accum, a) => accum + a.supply, 0);
  }

  get supplyLimit() {
    return this.rarityData?.supplyLimit || 0;
  }

  get amountLeftInStore() {
    return (
      this.item.tiers
        .slice(this.item.currentTier)
        .reduce((accum, a) => accum + a.supply, 0) + this.item.qtyLeft
    );
  }

  get completelySoldOut() {
    if (this.item.tiers) {
      return (
        this.item.currentTier === this.item.tiers.length &&
        this.item.qtyLeft === 0
      );
    }

    return this.item.qtyLeft === 0;
  }

  get amountLeft() {
    let totalInStore = 0;
    if (this.item.tiers) {
      totalInStore = this.totalTieredSupply;
    } else if (this.rarityData?.supplyLimit) {
      totalInStore = this.rarityData?.supplyLimit;
    }

    if (!this.item.tiers) {
      return [
        this.$numeral(
          this.item.qtyLeft,
          this.item.qtyLeft > 100000 ? '0,0 a' : '0,0',
        ),
        '/',
        this.$numeral(totalInStore, totalInStore > 100000 ? '0,0 a' : '0,0'),
        ' ',
        this.$t('common.transactions.nonTierItemsRemainingInStore.string'),
      ].join('');
    }

    const leftAvailableInTier = this.item.qtyLeft;
    const leftAvailableInStore =
      this.item.tiers
        .slice(this.item.currentTier)
        .reduce((accum, a) => accum + a.supply, 0) + this.item.qtyLeft;
    const totalInTier = this.item?.tiers[this.item.currentTier - 1].supply;
    return [
      this.$numeral(
        leftAvailableInTier,
        leftAvailableInTier > 100000 ? '0,0 a' : '0,0',
      ),
      '/',
      this.$numeral(totalInTier, totalInTier > 100000 ? '0,0 a' : '0,0'),
      ' ',
      this.$t('common.transactions.itemsLeftInTier.string'),
      ' ',
      this.$numeral(
        leftAvailableInStore,
        leftAvailableInStore > 100000 ? '0,0 a' : '0,0',
      ),
      '/',
      this.$numeral(totalInStore, totalInStore > 100000 ? '0,0 a' : '0,0'),
      ' ',
      this.$t('common.transactions.tierItemsRemainingInStore.string'),
    ].join('');
  }
}
