import { Module } from 'vuex';
import { IPromoPopup } from './types';
import { IRootState } from '../types';
import { IPromotionEligibility } from '../profile/types';

const promoPopup: Module<IPromoPopup, IRootState> = {
  namespaced: true,
  state: {
    promoInitialized: false,
    hasSeen: false,
    hasClaimed: false,
    livePromoId: 'mystery-box-for-friends-0',
    currentPromo: undefined,
    isSending: false,
  },
  getters: {
    getHasSeen: state => {
      return state.hasSeen;
    },
    getHasClaimed: state => {
      return state.hasClaimed;
    },
    getCurrPromo: state => {
      return state.currentPromo;
    },
    getPromoInitialized: state => {
      return state.promoInitialized;
    },
    getIsSending: state => {
      return state.isSending;
    },
  },
  actions: {
    async initializePromo({ commit, state, rootState }) {
      const currentUser = rootState.profile?.user;
      if (!currentUser) {
        return false;
      }
      const currPromo =
        currentUser.promotionEligibility.find(
          promo => promo.promotionId === state.livePromoId,
        ) || null;
      if (!currPromo) {
        return false;
      } else {
        commit('updateCurrentPromo', currPromo);
      }
      return true;
    },
    async setHasSeen({ commit }, val: boolean) {
      commit('updateHasSeen', val);
    },
    async setHasClaimed({ commit }, val: boolean) {
      commit('updateHasClaimed', val);
    },
    async setCurrentPromo({ commit }, val: IPromotionEligibility) {
      commit('updateCurrentPromo', val);
    },
    async setPromoInitialized({ commit }, val: boolean) {
      commit('updatePromoInitialized', val);
    },
    async setIsSending({ commit }, val: boolean) {
      commit('updateIsSending', val);
    },
  },
  mutations: {
    updateHasSeen(state, val: boolean) {
      state.hasSeen = val;
    },
    updateHasClaimed(state, val: boolean) {
      state.hasClaimed = val;
    },
    updateCurrentPromo(state, val: IPromotionEligibility) {
      state.currentPromo = val;
    },
    updatePromoInitialized(state, val: boolean) {
      state.promoInitialized = val;
    },
    updateIsSending(state, val: boolean) {
      state.isSending = val;
    },
  },
};

export default promoPopup;
