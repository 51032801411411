import { Context } from '@nuxt/types';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';

export default async function(context: Context) {
  const { app, route } = context;

  if (!(await app.$auth.isLoggedIn())) {
    setRedirectAfterLogin(`/?login=1&goto=${route.name}`);
    await app.$auth.loginWithRedirect({
      authorizationParams: { prompt: 'login' },
    });
  }
}
