import { render, staticRenderFns } from "./LegalAgreements.vue?vue&type=template&id=657d2696&scoped=true&"
import script from "./LegalAgreements.vue?vue&type=script&lang=ts&"
export * from "./LegalAgreements.vue?vue&type=script&lang=ts&"
import style0 from "./LegalAgreements.vue?vue&type=style&index=0&id=657d2696&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657d2696",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VLayout,VProgressCircular})
