import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { INotificationsState } from './types';
import { IRootState } from '../types';

export const state: INotificationsState = {
  newNotifications: {
    total: 0,
  },
};

const namespaced = true;

const notifications: Module<INotificationsState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default notifications;
