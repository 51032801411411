import numeral from 'numeral';
import { Vue } from 'vue-property-decorator';

declare module 'vue/types/vue' {
  // tslint:disable-next-line
  interface Vue {
    $numeral: (input: number | string, formatString: string) => string;
    $toLocaleString: (
      input: number | string,
      options: { maxDigits?: number; minDigits?: number },
    ) => string;
    $cryptoFormat: (input: number | string) => string;
    $fiatFormat: (input: number | string) => string;
    $localCurrency: (
      value: number,
      currency?: string,
      decimals?: number,
    ) => string;
  }
}

function configureNumeral(locale: 'en' | 'de' = 'en') {
  if (!(numeral as any).locales['intl-decimal-comma']) {
    numeral.register('locale', 'intl-decimal-comma', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal(number) {
        // Copied from the en-GB one: https://github.com/adamwdraper/Numeral-js/blob/master/locales/en-gb.js
        // Numeral forces us to provide this when registering locales
        const b = number % 10;
        // tslint:disable-next-line: no-bitwise
        return ~~((number % 100) / 10) === 1
          ? 'th'
          : b === 1
          ? 'st'
          : b === 2
          ? 'nd'
          : b === 3
          ? 'rd'
          : 'th';
      },
      currency: {
        symbol: '$',
      },
    });
  }

  const numeralLocale = locale === 'en' ? 'en' : 'intl-decimal-comma';
  numeral.locale(numeralLocale);
}

Vue.mixin({
  methods: {
    $numeral(input: number | string, formatString: string) {
      const number = Number(input);
      const formatCode = (this.$store as any).getters.numericFormatJsCode;
      configureNumeral(formatCode);

      const valWithMin = +number >= 1e-6 ? +number : 0;
      const result = numeral(Number(valWithMin.toFixed(8))).format(
        formatString,
      );

      return result;
    },
    $toLocaleString(
      input: number | string,
      { maxDigits, minDigits }: { maxDigits?: number; minDigits?: number },
    ) {
      const formatCode = (this.$store as any).getters.numericFormatJsCode;
      return input.toLocaleString(formatCode, {
        maximumFractionDigits: maxDigits,
        minimumFractionDigits: minDigits,
      });
    },
    $cryptoFormat(input: number | string) {
      const value = Number(input);

      if (Number.isNaN(value)) {
        return '';
      }

      const posValue = Math.abs(value);
      const decimalsForValueCeiling = [
        {
          ceil: 10,
          decimals: 5,
        },
        {
          ceil: 100,
          decimals: 3,
        },
        {
          ceil: 1000,
          decimals: 2,
        },
      ];

      const decimals =
        decimalsForValueCeiling.find(({ ceil }) => ceil > posValue)?.decimals ??
        0;

      return this.$toLocaleString(value, { maxDigits: decimals });
    },
    $fiatFormat(value: number | string) {
      const valueNum = Number(value);

      if (Number.isNaN(valueNum)) {
        return '';
      }

      const decimalsForValueCeiling = [
        {
          ceil: 0.000001,
          decimals: 6,
        },
        {
          ceil: 0.00001,
          decimals: 5,
        },
        {
          ceil: 0.0001,
          decimals: 4,
        },
        {
          ceil: 0.001,
          decimals: 3,
        },
      ];

      const decimals =
        decimalsForValueCeiling.find(({ ceil }) => ceil > valueNum)?.decimals ??
        2;
      return this.$toLocaleString(value, { maxDigits: decimals, minDigits: 2 });
    },
    $localCurrency(
      value: number,
      currency: string = 'USD',
      decimals: number = 2,
    ) {
      if (value < 0.01) {
        decimals = 4;
      }

      const formatCode = (this.$store as any).getters.numericFormatJsCode;
      return value.toLocaleString(formatCode, {
        localeMatcher: 'best fit',
        style: 'currency',
        currency,
        maximumFractionDigits: decimals,
      });
    },
  },
});
