

































































import { Component, Mixins } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { IDiscordProfile } from '~/store/profile/types';
import DiscordService from '~/services/discord.service';
import createDiscordProfile from '~/mutations/createDiscordProfile.gql';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import AuthQuery from '~/mixins/AuthQuery';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';

@Component({
  components: {
    TwoFactorAuthInputPrompt,
  },
})
export default class DiscordConnect extends Mixins(AuthQuery) {
  @State(profile => profile.user.discordProfile, { namespace: 'profile' })
  readonly discordProfile!: IDiscordProfile;
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  readonly loggedIn!: boolean;
  @State('showDiscordConnectingDialog')
  readonly showDiscordConnectingDialogState!: boolean;
  @State('discordLink') readonly discordLink!: string;

  connecting = true;
  connectingTitle = '';
  connectingMessage = '';
  connectingError = false;
  emptyLogo = require('@/assets/logos/discord-empty-profile.png');

  get showDiscordConnectingDialog() {
    return this.showDiscordConnectingDialogState;
  }
  set showDiscordConnectingDialog(value) {
    this.toggleDiscordConnectingDialog(value);
  }

  get connected() {
    return !!this.discordProfile;
  }

  get profileImage() {
    return this.discordProfile?.avatar
      ? `https://cdn.discordapp.com/avatars/${this.discordProfile?.id}/${this.discordProfile?.avatar}.png`
      : this.emptyLogo;
  }

  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;
  @Mutation toggleDiscordConnectingDialog!: (args: any) => void;

  mounted() {
    const code = this.$route.query.code;
    const encodedState = this.$route.query.state;
    if (code && encodedState) {
      this.authenticateCode(code.toString(), encodedState.toString());
    }
  }

  async authenticateCode(code: string, encodedState: string) {
    if (!DiscordService.checkStateParam(encodedState)) {
      console.error('State param does not match');
      return;
    }

    this.toggleDiscordConnectingDialog(true);
    this.connecting = true;
    this.connectingTitle = this.$t(
      'components.discord.discordConnectingDialog.connecting',
    ) as string;

    try {
      const discordProfile = await this.createDiscordProfile(code);

      // User canceled
      if (!discordProfile) {
        // UA tracking
        this.$ua.trackDiscordProfileLinkUserCancelEvent();

        this.toggleDiscordConnectingDialog(false);
        return;
      }

      this.updateUser({ discordProfile });
      this.connectingTitle = this.$t(
        'components.discord.discordConnectingDialog.connectedTitle',
        { username: discordProfile.username },
      ) as string;
      this.connectingMessage = this.$t(
        'components.discord.discordConnectingDialog.connectedMsg',
      ) as string;
    } catch (error) {
      if (
        error &&
        error.message &&
        error.message.includes(
          'Discord profile has already been linked to another user',
        )
      ) {
        this.connectingTitle = this.$t(
          'components.discord.discordConnectingDialog.profileUnavailableTitle',
        ) as string;
        this.connectingMessage = this.$t(
          'components.discord.discordConnectingDialog.profileUnavailableMsg',
        ) as string;
      } else {
        // UA tracking
        this.$ua.trackDiscordProfileLinkErrorEvent(error);

        this.$sentry.captureException(error);
        this.connectingTitle = this.$t(
          'common.notifications.defaultErrorTitle',
        ) as string;
        this.connectingMessage = this.$t(
          'components.discord.discordConnectingDialog.defaultErrorMsg',
        ) as string;
        this.$sentry.captureException(error);
        this.connectingTitle = 'An error has occurred';
        this.connectingMessage =
          'Something happened while trying to connect your Discord account. Please refresh the browser and try again.';
      }

      this.connectingError = true;
    } finally {
      this.connecting = false;
    }
  }

  createDiscordProfile(code: string): Promise<IDiscordProfile> {
    return this.doAuthQuery(async totpToken => {
      const apolloClient = this.$apolloProvider.clients.gateway;

      const { data } = await apolloClient.mutate({
        mutation: createDiscordProfile,
        variables: { code, totpToken },
      });

      if (
        data.createDiscordProfile &&
        !data.createDiscordProfile.success &&
        data.createDiscordProfile.message ===
          'Discord profile has already been linked to another user'
      ) {
        // UA tracking
        this.$ua.trackDiscordProfileAlreadyLinkedToAnotherUserEvent();

        throw new Error(data.createDiscordProfile.message);
      }

      // UA tracking
      this.$ua.trackDiscordProfileLinkCompleteEvent({
        discordProfile: data.createDiscordProfile?.discordProfile,
      });

      return data.createDiscordProfile?.discordProfile;
    }, TwoFaCheckpoint.accountModifications);
  }
}
