interface IRestrictionData<
  TReasonDetails extends Record<string, any> = Record<string, any>
> {
  reason?: string;
  reasonDetails?: {
    id?: string;
    ruleSetType?: string;
    ruleResults?: Array<IRuleResult<TReasonDetails>>;
  };
  success?: boolean;
}

interface IRuleResult<
  TReasonDetails extends Record<string, any> = Record<string, any>
> {
  filterResults?: Record<string, IFilterResult<TReasonDetails>>;
  ruleIdx?: number;
  success?: boolean;
}

interface IFilterResult<
  TReasonDetails extends Record<string, any> = Record<string, any>
> {
  reason?: string;
  reasonDetails?: {
    failClauses?: any[];
    successClauses?: Array<ISuccessClause<TReasonDetails>>;
  };
  success?: boolean;
}

interface ISuccessClause<
  TReasonDetails extends Record<string, any> = Record<string, any>
> {
  clauseIdx?: number;
  reason?: string;
  reasonDetails?: TReasonDetails;
}

export function isRestrictionSuccess(restrictionData: IRestrictionData) {
  return restrictionData?.success;
}

export function getFirstRestrictionSuccessResultByFilter(
  restrictionData: IRestrictionData,
  filter: string,
  reasonDetailValue?: string,
):
  | undefined
  | null
  | Array<{
      absDesiredAdjustmentAmountPerItemInUsdCents: number;
      basePrice: number;
      desiredAdjustmentAmountPerItemInUsdCents: number;
      previousPrice: string;
      price: string;
      token: string;
      tokenPriceInCents: number;
      usdBasePriceInCents: number;
      usdPriceInCents: number;
    }> {
  let results;

  if (restrictionData?.success && restrictionData?.reasonDetails?.ruleResults) {
    restrictionData.reasonDetails.ruleResults.forEach((ruleResult: any) => {
      const restrictionFilterData =
        ruleResult?.filterResults && ruleResult.filterResults[filter]
          ? ruleResult.filterResults[filter]
          : null;
      if (restrictionFilterData && restrictionFilterData.success) {
        const successData = restrictionFilterData.reasonDetails?.successClauses;

        if (successData[0]) {
          if (reasonDetailValue) {
            results = successData[0].reasonDetails
              ? successData[0].reasonDetails[reasonDetailValue]
              : null;
          } else {
            results = successData[0].reasonDetails
              ? successData[0].reasonDetails
              : null;
          }
        }
      }
    });
  }

  return results;
}

export function isSuccessfulPriceAdjustmentCodeBased(
  restrictionData: IRestrictionData,
): boolean {
  let isCodeBasedAdjustment = false;

  if (restrictionData?.success) {
    restrictionData.reasonDetails?.ruleResults?.forEach(ruleResult => {
      const restrictionCodeData = ruleResult?.filterResults?.code ?? null;
      const restrictionPriceAdjustmentData =
        ruleResult?.filterResults?.priceAdjustment ?? null;

      if (
        restrictionPriceAdjustmentData?.success &&
        restrictionCodeData?.success
      ) {
        isCodeBasedAdjustment = true;
      }
    });
  }

  return isCodeBasedAdjustment;
}
