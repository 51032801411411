import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IGame } from '~/store/games/types';
import { IUser } from '~/store/profile/types';
import { RequiredUserItem } from '~/types/user-items';

@Component
export default class CheckForRequiredItems extends Vue {
  @State(inventory => inventory.userItems, { namespace: 'inventory' })
  userItems!: RequiredUserItem[];
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  private checkRequiredItems(game: IGame) {
    let hasARequiredItem = false;
    try {
      const uitems = this.userItems
        .filter(c => c.collection === game.collection)
        .map(i => i.ethereumBaseId);
      const ritems = game.requiredItemIds?.map(i => i.baseId) || [];
      if (uitems.some(i => ritems?.includes(i))) {
        hasARequiredItem = true;
      }
    } catch (error) {
      throw error;
    }
    return hasARequiredItem;
  }

  checkItemsRequirements(game: IGame) {
    const userHasRequiredItem = this.checkRequiredItems(game);

    return {
      userHasRequiredItem,
    };
  }

  getRequiredGameItemStatus(game: IGame) {
    let requiredItemButton = true;
    if (game.isReleased && game.hasItemRequiredToPlay) {
      if (this.currentUser.loggedIn) {
        const hasAnItem = this.checkItemsRequirements(game);
        if (hasAnItem.userHasRequiredItem) {
          requiredItemButton = false;
        }
      } else {
        requiredItemButton = true;
      }
    } else {
      requiredItemButton = false;
    }
    return requiredItemButton;
  }
}
