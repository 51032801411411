
















































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import ValidatedPassword from './ValidatedPassword.vue';
import ConfirmPassword from './ConfirmPassword.vue';
import DisplayRecoveryPhrase from './DisplayRecoveryPhrase.vue';
import ConfirmMnemonic from './ConfirmMnemonic.vue';
import mnemonicQuery from '@/queries/mnemonic.gql';
import createWalletMutation from '@/mutations/createWallet.gql';
import getUser from '~/queries/getUser.gql';
import createGyriPassphraseMutation from '@/mutations/createGyriPassphrase.gql';
import { IUser } from '../../store/profile/types';
import GradientHexagon from '~/components/UIComponents/GradientHexagon.vue';
import { GalaChainInfo } from '~/types/gala-chain';

@Component({
  components: {
    ValidatedPassword,
    ConfirmPassword,
    DisplayRecoveryPhrase,
    ConfirmMnemonic,
    GradientHexagon,
  },
})
export default class NewWallet extends Vue {
  @State(profile => profile.user, { namespace: 'profile' })
  user!: IUser;

  @Prop(Function) readonly closeModal!: () => void;
  @Prop(String) readonly firstName?: string;
  @Prop(String) readonly lastName?: string;
  @Prop(Boolean) readonly gyriPassphrase?: boolean;
  @Prop(Boolean) readonly transparentBg?: boolean;

  @Mutation('toggleErrorSnackbar')
  toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation('updateSnackbarErrorText')
  updateSnackbarErrorText!: (args: any) => void;
  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;

  @Action('fetchShareConfig', { namespace: 'share' })
  fetchShareConfig!: () => void;
  @Action('getWalletsData', { namespace: 'inventory' })
  private getWalletsData!: () => void;

  step = 1;
  passValid = false;
  password = '';
  confirmPassInput = '';
  confirmPassValid = false;
  ageConfirmation = false;
  showRecoveryPhrase = false;
  acknowledgedMnemonic = false;
  mnemonicConfirmed = false;
  brand = process.env.brand;
  newMnemonic = [];
  success = false;
  isSavingWallet = false;
  walletCreationDisabled = false;
  dynamite = require(`~/assets/onboarding/icon_dynamite.png`);
  galaChainName = GalaChainInfo.CHAIN_NAME;

  get modalTitle(): string {
    const titleForStep: Record<number, string> = {
      1: this.$t(
        'components.newWallet.newWallet.createTransferCode',
      ).toString(),
      2: this.$t(
        'components.newWallet.newWallet.writeRecoveryPhrase',
      ).toString(),
      3: this.$t(
        'components.newWallet.newWallet.confirmRecoveryPhrase',
      ).toString(),
      4: this.$t('components.newWallet.newWallet.dynamite').toString(),
    };

    return titleForStep[this.step] ?? '';
  }

  get allValid() {
    return (
      this.passValid &&
      this.confirmPassValid &&
      this.acknowledgedMnemonic &&
      this.mnemonicConfirmed
    );
  }

  get continueBtnClass() {
    const base = `wallet-setup-creation-continue-btn-step-${this.step}`;
    if (this.step === 2)
      return (
        base +
        '-' +
        (this.showRecoveryPhrase ? 'continue' : 'showRecoveryPhrase')
      );
    if (this.step >= 3) return base + ' wallet-setup-creation-finish';

    return base;
  }
  get backBtnText() {
    return this.step === 1
      ? this.$t('common.cta.cancel')
      : this.$t('common.navigation.back');
  }
  get continueBtnText() {
    return this.step === 2 && !this.showRecoveryPhrase
      ? this.$t('components.newWallet.newWallet.showRecovery')
      : this.step >= 3
      ? this.$t('components.newWallet.newWallet.finish')
      : this.$t('common.navigation.continue');
  }
  get continueBtnDisabled() {
    switch (this.step) {
      case 1:
        return (
          !this.passValid || !this.confirmPassValid || !this.ageConfirmation
        );
      case 2:
        return this.showRecoveryPhrase ? !this.acknowledgedMnemonic : false;
      case 3:
        return !this.allValid;
      default:
        return false;
    }
  }

  async created() {
    const { data } = await this.$apollo.query({
      query: mnemonicQuery,
      fetchPolicy: 'no-cache',
    });
    if (data && data.mnemonic) {
      this.newMnemonic = data.mnemonic.split(' ');
    }
  }

  handleBack() {
    if (this.step === 1) {
      this.closeModal();
    } else if (this.step === 2 && this.showRecoveryPhrase) {
      this.showRecoveryPhrase = false;
    } else {
      this.goBackAStep();
    }
  }

  handleContinue() {
    if (this.step === 1 && this.continueBtnDisabled) {
      // NOOP
    } else if (this.step === 2 && !this.showRecoveryPhrase) {
      this.showRecoveryPhrase = true;
    } else if (this.step === 3) {
      this.handleAllValid();
    } else if (this.step === 4 && this.success) {
      this.gyriPassphrase
        ? this.handleGyriPassphraseSuccess()
        : this.handleSuccessClose();
    } else {
      this.goForwardAStep();
    }
  }

  goBackAStep() {
    this.step--;
  }
  goForwardAStep() {
    this.step++;
  }

  handleGyriPassphraseSuccess() {
    this.updateUser({ hasGyriPassphrase: true });
    this.success = false;
    this.$emit('success', this.password);
    this.closeModal();
  }

  handleSuccessClose() {
    this.updateUser({ walletExists: true });
    this.getWalletsData();
    localStorage.setItem('walletCreated', Date.now().toString());
    this.fetchShareConfig();
    this.success = false;
    this.$emit('success');
    this.closeModal();
  }

  handlePassValid(valid: boolean) {
    this.passValid = valid;
  }

  handlePassChange(pass: string) {
    this.password = pass;
  }

  async createWallet() {
    this.isSavingWallet = true;
    try {
      const mutation = this.gyriPassphrase
        ? createGyriPassphraseMutation
        : createWalletMutation;
      const mutationName = this.gyriPassphrase
        ? 'createGyriPassphrase'
        : 'createWallet';
      const { data } = await this.$apollo.mutate({
        mutation,
        variables: {
          firstName: this.firstName,
          lastName: this.lastName,
          mnemonic: this.newMnemonic.join(' '),
          walletPassword: this.password,
        },
        refetchQueries: [
          {
            query: getUser,
          },
        ],
      });

      if (data && data[mutationName]) {
        const { success } = data[mutationName];

        if (!success) throw new Error('Wallet creation error');

        // UA tracking
        this.$ua.trackWalletCreationCompleteEvent();

        if (this.$apolloProvider) {
          try {
            await this.$auth.forceTokenRefresh();
          } catch (err) {
            this.$sentry.captureException(err);
          }
        }
        this.step = 4;
        this.success = true;
        this.isSavingWallet = false;

        if (this.gyriPassphrase) {
          this.updateUser({ hasGyriPassphrase: true });
        }
      }
    } catch (error) {
      // UA tracking
      this.$ua.trackWalletCreationErrorEvent(error);

      this.$sentry.captureException(error);
      this.isSavingWallet = false;

      this.updateSnackbarErrorText(
        this.$t('components.newWallet.newWallet.errorMsg') as string,
      );
      this.toggleErrorSnackbar();
    }
  }

  async handleAllValid() {
    try {
      if (this.allValid) {
        //prettier-ignore
        const strongPasswordPattern = 
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*,()-_+=`~\][\{}\\|<>?.])(?=.{8,})/
        const isPasswordStrong = strongPasswordPattern.test(this.password);

        if (!isPasswordStrong) {
          throw new Error(
            this.$t('components.newWallet.newWallet.weakPassword') as string,
          );
        }

        if (this.newMnemonic.length !== 12) {
          throw new Error(
            this.$t(
              'components.newWallet.newWallet.incorrectMnemonic',
            ) as string,
          );
        }

        await this.createWallet();
      }
    } catch (error) {
      const errorMessage = (error as Error).message;

      if (
        errorMessage ===
          (this.$t('components.newWallet.newWallet.weakPassword') as string) ||
        errorMessage ===
          (this.$t(
            'components.newWallet.newWallet.incorrectMnemonic',
          ) as string)
      ) {
        this.updateSnackbarErrorText(errorMessage);
      } else {
        this.$sentry.captureException(error);
        this.updateSnackbarErrorText(
          this.$t('components.newWallet.newWallet.unableToSetUp') as string,
        );
      }

      this.toggleErrorSnackbar();
    }
  }

  @Watch('mnemonicConfirmed')
  onMnemonicConfirmed(newVal: boolean, oldVal: boolean) {
    if (oldVal === false && newVal === true && this.allValid) {
      this.handleContinue();
    }
  }
}
