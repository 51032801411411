export async function removeExifFromImage(file: File) {
  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event: any) => {
      if (event?.target?.result) {
        img.src =
          typeof event.target.result === 'string' ? event.target.result : '';
      }
    };
    reader.onerror = reject;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);

      canvas.toBlob(
        (blob: any) => {
          if (blob) {
            // Convert the Blob to a File for uploading
            const cleanFile = new File([blob], file.name, { type: file.type });
            resolve(cleanFile);
          } else {
            reject(new Error('Failed to convert canvas to blob'));
          }
        },
        'image/jpeg',
        0.9,
      );
    };

    img.onerror = reject;
    reader.readAsDataURL(file);
  });
}
