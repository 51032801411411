// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/Figtree-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/Figtree-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/Figtree-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/Figtree-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/fonts/Figtree-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("assets/fonts/Figtree-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("assets/fonts/Figtree-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("assets/fonts/Figtree-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("assets/fonts/Figtree-Black.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("assets/fonts/Figtree-Black.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
exports.push([module.id, "@font-face{font-family:\"Figtree\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:\"Figtree\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Figtree\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:\"Figtree\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Figtree\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\");font-weight:900;font-style:normal;font-display:swap}", ""]);
// Exports
module.exports = exports;
