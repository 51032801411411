









































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import ExclamationTriangle from '~/components/SvgIcons/ExclamationTriangle.vue';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import AuthQuery from '~/mixins/AuthQuery';
import grantGameAllowance from '~/mutations/grantGameAllowance.gql';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { IRequiredAllowance } from '../../store/games/types';

enum DisplayState {
  DETAILS = 'DETAILS',
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
}

@Component({
  components: {
    RequirePasscode,
    ExclamationTriangle,
    TwoFactorAuthInputPrompt,
  },
})
export default class GiveLockAllowanceComponent extends Mixins(AuthQuery) {
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Prop({ type: Boolean })
  readonly showModal!: boolean;
  @Prop(String) readonly gameName!: string;
  @Prop(String) readonly gameImg!: string;
  @Prop() readonly requiredAllowances!: IRequiredAllowance[];

  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation('toggleCreateWalletPrompt')
  toggleCreateWalletPrompt!: (payload: {
    show?: boolean;
    walletType?: string;
  }) => void;

  DisplayState = DisplayState;
  displayState = DisplayState.DETAILS;
  isInvalidPasswordError = false;
  isCanceledTwoFaError = false;

  accept() {
    if (!this.hasGyriPassphrase) {
      this.toggleCreateWalletPrompt({ show: true, walletType: 'gyri' });
      return;
    }
    this.displayState = this.DisplayState.ACCEPT;
  }

  decline() {
    this.displayState = this.DisplayState.DECLINE;
  }

  goBack() {
    this.displayState = this.DisplayState.DETAILS;
  }

  async grantAllowance(transferCode: string) {
    this.isInvalidPasswordError = false;
    this.isCanceledTwoFaError = false;
    try {
      const result = await this.doAuthQuery(async (totpToken: string) => {
        await this.$apollo.mutate({
          mutation: grantGameAllowance,
          variables: {
            gameName: this.gameName,
            transferCode,
            totpToken,
          },
        });

        return true;
      }, TwoFaCheckpoint.transactions);

      if (!result) {
        this.isCanceledTwoFaError = true;
        return { success: false };
      }

      this.updateSnackbarSuccessText(
        this.$t('components.modalPrompts.gyriAllowanceModal.grantSuccess'),
      );
      this.toggleSuccessSnackbar();

      return { success: true };
    } catch (err) {
      const invalidPassword = (err as any)?.graphQLErrors?.some(
        (e: any) => e.message === 'Incorrect password',
      );

      if (invalidPassword) {
        this.isInvalidPasswordError = true;
      }

      throw err;
    }
  }

  requiredField(val: string) {
    return !!val || this.$t('common.notifications.requiredField');
  }

  handleGrantComplete({ success }: { success: boolean }) {
    if (this.isCanceledTwoFaError) {
      this.$emit(
        'error',
        this.$t('components.modalPrompts.gyriAllowanceModal.canceledTwoFA'),
      );

      return;
    }

    if (success) {
      this.$emit('success');
      // UA tracking
      this.$ua.trackGameGiveAllowanceCompleteEvent({
        game: this.gameName,
      });
    } else {
      const errorMessage = this.isInvalidPasswordError
        ? this.$t(
            'components.modalPrompts.gyriAllowanceModal.incorrectTransferCode',
          )
        : this.$t('common.errors.somethingWentWrong');

      // UA tracking
      this.$ua.trackGameGiveAllowanceErrorEvent({
        game: this.gameName,
        error: errorMessage,
      });
      this.$emit('error', errorMessage);
    }
  }
}
