import { Component, Vue } from 'vue-property-decorator';
import isQualified from '../queries/isQualified.gql';
import isQualifiedBatch from '../queries/isQualifiedBatch.gql';
import { isRestrictionSuccess } from '../utils/parseRestrictionResults';
import { IRestriction } from '../types/restriction';

@Component
export default class Restrictions extends Vue {
  async isQualified(id: string, payload: Record<string, any>) {
    if (!id || id === '') {
      return true;
    }
    const { data } = await this.$apollo.query<{ isQualified: IRestriction }>({
      query: isQualified,
      variables: {
        id,
        payload,
      },
    });
    return !!isRestrictionSuccess(data.isQualified);
  }

  async isQualifiedBatch(
    restrictions: Array<{ id: string; payload: Record<string, any> }>,
  ) {
    const { data } = await this.$apollo.query<{
      isQualifiedBatch: IRestriction[];
    }>({
      query: isQualifiedBatch,
      variables: { restrictions },
    });
    return data.isQualifiedBatch.map(x => ({
      id: x.reasonDetails.id,
      success: x.success,
    }));
  }
}
