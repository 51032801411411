export enum SessionStorageKeys {
  gameAuthClient = 'gameAuthClient',
}

export enum LocalStorageKeys {
  redirectAfterLogin = 'redirectAfterLogin',
  hideTwoFaSetup = 'hideTwoFaSetup',
  hideEmailVerification = 'hideEmailVerification',
  redirectAfterWalletConnection = 'redirectAfterWalletConnection',
}

export const BrowserStorageKey = {
  ...SessionStorageKeys,
  ...LocalStorageKeys,
};

function getStorageType(key: keyof typeof BrowserStorageKey) {
  if (Object.keys(SessionStorageKeys).includes(key)) {
    return sessionStorage;
  }

  if (Object.keys(LocalStorageKeys).includes(key)) {
    return localStorage;
  }

  throw new Error(`Unknown storage key: ${key}`);
}

export function setInBrowserStorage(
  key: keyof typeof BrowserStorageKey,
  value: unknown,
) {
  const storageType = getStorageType(key);
  storageType.setItem(key, JSON.stringify(value));
}

export function getFromBrowserStorage<T>(
  key: keyof typeof BrowserStorageKey,
): T | null {
  const storageType = getStorageType(key);
  const value = storageType.getItem(key);
  return value ? JSON.parse(value) : null;
}

export function removeFromBrowserStorage(key: keyof typeof BrowserStorageKey) {
  const storageType = getStorageType(key);
  storageType.removeItem(key);
}
