














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import TriggerWalletCreationBtn from '~/components/NewWallet/TriggerWalletCreationBtn.vue';
import { setInBrowserStorage, LocalStorageKeys } from '~/utils/browserStorage';

@Component({
  components: {
    TriggerWalletCreationBtn,
  },
})
export default class CreateWalletModalPrompt extends Vue {
  @State('createWalletPrompt') createWalletPromptState!: {
    show: boolean;
    walletType: string;
  };
  @State(profile => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State(profile => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (args: any) => void;

  get showCreateWalletPrompt() {
    return this.createWalletPromptState.show;
  }
  set showCreateWalletPrompt(value) {
    this.toggleCreateWalletPrompt({ show: value });
  }

  get settingGyriPassphrase() {
    return this.createWalletPromptState.walletType
      ? this.createWalletPromptState.walletType === 'gyri'
      : this.walletConnected && !this.hasGyriPassphrase;
  }

  get headingText() {
    return this.settingGyriPassphrase
      ? this.$t('components.settings.gyriPassphrase.createPassphrase')
      : this.$t('components.newWallet.createWalletModalPrompt.setUpWallet');
  }

  handleCloseModal(success?: boolean) {
    const currentRoute = this.$router.currentRoute?.fullPath
      ? this.$router.currentRoute.fullPath
      : '';
    setInBrowserStorage(
      LocalStorageKeys.redirectAfterWalletConnection,
      currentRoute,
    );
    this.$emit('success', success);
    this.toggleCreateWalletPrompt({ show: false });
  }
}
