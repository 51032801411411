import { Context } from '@nuxt/types';

export default async function(context: Context) {
  const { redirect, query } = context;

  const { session_token, state } = query;
  try {
    if (!session_token || !state) {
      redirect('/');
    }
  } catch (error) {
    redirect('/');
  }
}
