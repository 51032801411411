import { Module } from 'vuex';

import {
  getBinanceWalletProvider,
  getCoinbaseWalletProvider,
  getMetaMaskProvider,
  getOkxProvider,
} from '~/utils/providers';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IW3wState, Network, IProviderWhitelist } from './types';
import { IRootState } from '../types';

export const state: IW3wState = {
  provider: null,
  web3Provider: null,
  externalWallets: [],
  lastChainId: null,
  networks: {
    1: 'mainnet',
    3: 'ropsten',
    4: 'rinkeby',
    5: 'goerli',
    11155111: 'sepolia',
  },
  expectedNetwork:
    process.env.networkType === 'testnet' ? Network.sepolia : Network.mainnet,
  providerOptions: [
    {
      label: 'coinbase wallet',
      name: 'Coinbase Wallet',
      getInterface: getCoinbaseWalletProvider,
      icon: 'https://static.gala.games/images/web3/coinbase_wallet_logo.png',
      missingProviderMobileLink: (dappPath: string) =>
        `https://go.cb-w.com/dapp?cb_url=${dappPath}?wb=true`,
      missingProviderDesktopLink: 'https://www.coinbase.com/wallet',
    },
    {
      label: 'metamask',
      name: 'MetaMask',
      getInterface: getMetaMaskProvider,
      icon: 'https://static.gala.games/images/web3/metamask-logo-small.png',
      missingProviderMobileLink: (dappPath: string) =>
        `https://metamask.app.link/dapp/${dappPath}?wb=true`,
      missingProviderDesktopLink: 'https://metamask.io/',
    },
    {
      label: 'OKX wallet',
      name: 'OKX wallet',
      getInterface: getOkxProvider,
      icon: 'https://static.gala.games/images/web3/okx-icon-circle.png',
      // OKX doesn't provide a universal link for deeplinking on mobile like metamask and coinbase wallet do.
      missingProviderMobileLink: (dappPath: string) =>
        `https://www.okx.com/download`,
      missingProviderDesktopLink: 'https://www.okx.com/web3',
    },
    {
      label: 'binance wallet',
      name: 'Binance Wallet',
      getInterface: getBinanceWalletProvider,
      icon: 'https://static.gala.games/images/web3/binance-icon.png',
      missingProviderMobileLink: (dappPath: string) =>
        `https://www.binance.com/en/web3wallet`,
      missingProviderDesktopLink: 'https://www.binance.com/en/web3wallet',
    },
  ].filter(
    provider => !process.env.disabledW3WProviders?.includes(provider.label),
  ),
  connectedWallet: {
    address: '',
    label: '',
  },
  connectedExternalWallet: {
    ethAddress: '',
    walletProvider: '',
  },
  walletConnectionError: null,
  showWalletConnectionError: false,
  providerWhitelists:
    ((process.env.w3wProviderWhitelist as unknown) as IProviderWhitelist[]) ||
    [],
};

const namespaced = true;

const web3Wallet: Module<IW3wState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default web3Wallet;
