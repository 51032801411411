import { INetworkNotifications } from '~/types/notification';

export interface IUser {
  preferences: { currency: string; language: string; notifications: boolean };
  email: string;
  emailVerified: boolean | undefined;
  phone: string;
  firstName: string;
  lastName: string;
  fullNameNotProvided: boolean;
  displayName: string;
  role: string;
  permissions: string[];
  walletConnected: boolean | null;
  walletExists: boolean | null;
  twoFaAuthenticated: boolean;
  twoFaEnabled: boolean;
  loggedIn: boolean;
  id: string;
  profilePhotoUrl: string | null;
  gameToken: string;
  goldMember: boolean;
  discordProfile: IDiscordProfile | undefined;
  networkNotifications: INetworkNotifications;
  galaHonor: IGalaHonor;
  referralCode: string;
  isInBlockedCountry: boolean;
  shouldBlockGameplay: boolean;
  connectedAccounts?: Array<{
    connectionName: string;
    accountId: string;
  }>;
  hasGyriPassphrase: boolean;
  auth0UserIdentities: IAuth0UserIdentity[];
  campaignWelcomeModal: string;
  created: Date;
  promotionEligibility: IPromotionEligibility[];
}

export interface IAuth0UserIdentity {
  provider: string;
  connection: string;
  email: string;
  user_id: string;
  isSocial: string;
}

export interface IProfileState {
  ipLocation: {
    country: string;
    region: string;
  };
  user: IUser;
  activated: boolean | undefined;
  shareUrl: string;
  error: boolean;
  isFetching: boolean;
  gameTokenMaxAge: number;
  gameTokenTimeSet: number;
}

export interface IDiscordProfile {
  id: string;
  username: string;
  avatar: string;
}

export interface IGalaHonor {
  spiderTanks?: {
    currentHonor: number;
    estimatedHonorChange: number;
  };
}

export enum EGames {
  SPIDER_TANKS = 'SpiderTanks',
}

export enum HonorActionType {
  REDEEMING = 'redeeming',
  GOOD = 'good',
  BAD = 'bad',
}

export enum HonorAction {
  BRIDGE_CURRENCY_TO_ETH = 'bridgeCurrencyToEth',
  TRANSFER_CURRENCY = 'transferCurrency',
  PLAY_AND_EARN = 'playAndEarn',
  BRIDGE_CURRENCY_TO_GYRI = 'bridgeCurrencyToGyri',
  RUN_NODE = 'runNode',
  PURCHASE = 'purchase',
}

export interface IHonorAction {
  userId: string;
  action: HonorAction;
  actionType: HonorActionType;
  game: EGames;
  honorPoints: number;
  context: {
    amount?: number;
  };
  timestamp: Date;
}

export interface IConnectWalletMutationOptions {
  authData: {
    ethAddress: string;
    signedContent: string;
    sig: string;
    timestamp: string;
  };
  walletProvider: string;
  totpToken: string;
}

export interface IUserRegisterErrorMessage {
  checkMessage: string;
  snackbarMessage: string;
  snackbarTranslatable?: boolean;
}

export interface PromotionImageUris {
  main: string;
}

export interface IPromotionEligibility {
  promotionId: string;
  eligible: boolean;
  visualType: string;
  imageUris: PromotionImageUris;
}
