















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { isEmail } from 'validator';

type ValidationRules = Array<(v: string) => boolean | string>;

@Component
export default class SignUpComponent extends Vue {
  @Prop({ type: String, default: '' })
  readonly initialEmail!: string;
  @Prop({ type: String, default: '66' })
  readonly tag!: string;
  @Prop({ type: String, default: '7' })
  readonly list!: string;
  @Prop({ type: String, default: 'white' })
  readonly btnStyle!: 'purple' | 'white';
  @Prop({ type: String })
  readonly buttonText!: string;
  @Prop({ type: String })
  readonly successMessage!: string;
  @Prop({ type: String })
  readonly errorMessage!: string;
  @Prop({
    type: String,
    default: '',
  })
  readonly placeholderText!: string;
  @Prop({ type: Boolean, default: false })
  readonly dense!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly filled!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly rowLayout!: boolean;
  @Prop(Boolean) showHeader!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly enableSimpleSignup!: boolean;

  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: string) => void;
  @Mutation updateSnackbarSuccessText!: (args: string) => void;

  email = this.initialEmail;
  isFormValid = true;
  isSubscribing = false;
  subscribeTCsChecked = false;
  subscribePromotionsChecked = false;
  rules: {
    email?: ValidationRules;
    tc?: ValidationRules;
    promo?: ValidationRules;
  } = {};

  submitForm() {
    const form = this.$refs.form as HTMLFormElement;

    // lazy-validation option doesn't seem to work, so we
    // set validation rules after the user has tried to submit once
    this.rules = this.enableSimpleSignup
      ? {}
      : {
          email: this.emailRules,
          tc: this.tcCheckboxRules,
          promo: this.promoCheckboxRules,
        };

    this.$nextTick(() => {
      if (form?.validate()) {
        this.subscribe();
      }
    });
  }

  get emailRules() {
    return [
      (v: string) =>
        !!v ||
        (this.$t('common.notifications.requiredFieldWithName', {
          name: this.$t('common.inputLabels.email'),
        }) as string),
      (v: string) =>
        /.+@.+\..+/.test(v) ||
        (this.$t('common.notifications.enterValidEmail') as string),
    ];
  }

  get tcCheckboxRules() {
    return [
      (v: string) =>
        !!v || (this.$t('common.notifications.agreeToContinue') as string),
    ];
  }

  get promoCheckboxRules() {
    return [
      (v: string) =>
        !!v || (this.$t('common.notifications.agreeToContinue') as string),
    ];
  }

  get buttonLabel() {
    return (
      this.buttonText ||
      (this.$t('components.uiComponents.signUp.imIn') as string)
    );
  }

  get successText() {
    return (
      this.successMessage ||
      (this.$t('components.uiComponents.signUp.successMessage') as string)
    );
  }

  get errorText() {
    return (
      this.errorMessage ||
      (this.$t('components.uiComponents.signUp.errorMessage') as string)
    );
  }

  public async subscribe() {
    if (!isEmail(this.email)) {
      const errorMessage = this.$t(
        'common.notifications.pleaseEnterAValidEmail',
      ) as string;
      this.email = '';
      this.subscribeTCsChecked = false;
      this.subscribePromotionsChecked = false;

      this.updateSnackbarErrorText(errorMessage);
      this.toggleErrorSnackbar();

      return;
    }

    this.isSubscribing = true;

    const signupPayload = {
      email: this.email,
      tag: this.tag,
      list: this.list,
    };

    fetch(
      'https://8ut1qllg36.execute-api.us-east-1.amazonaws.com/production/handler',
      {
        method: 'POST',
        body: JSON.stringify(signupPayload),
      },
    )
      .then(async () => {
        this.updateSnackbarSuccessText(this.successText);
        this.toggleSuccessSnackbar();
        this.$ua.trackEmailSignupCompleteEvent(signupPayload);
        this.$emit('subscribe-success');
      })
      .catch(() => {
        this.updateSnackbarErrorText(this.errorText);
        this.$ua.trackEmailSignupErrorEvent({
          ...signupPayload,
          errorMessage: this.errorText,
        });
        this.toggleErrorSnackbar();
      })
      .finally(() => {
        this.rules = {};
        (this.$refs.form as HTMLFormElement).reset();
        this.isSubscribing = false;
        this.$emit('subscribe-complete');
      });
  }

  goTo(route: string) {
    this.$router.push(route);
  }
}
