

































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import sendVerifyEmailMutation from '~/mutations/sendVerifyEmail.gql';
import GamesBrandedModal from '~/components/UIComponents/GamesBrandedModal.vue';

@Component({
  components: { GamesBrandedModal },
})
export default class VerifyEmailToPlayModal extends Vue {
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.emailVerified, { namespace: 'profile' })
  emailVerified!: boolean;
  @State(profile => profile.user.email, { namespace: 'profile' })
  email!: boolean;

  @Prop(Boolean) readonly displayVerifyEmailModal!: boolean;

  sending = false;
  sent = false;
  sendError = '';

  get showModal() {
    return (
      this.displayVerifyEmailModal &&
      this.loggedIn &&
      this.email &&
      !this.emailVerified &&
      this.emailVerified !== undefined
    );
  }

  async handleSendVerificationEmail() {
    try {
      this.sending = true;
      const { data } = await this.$apollo.mutate({
        mutation: sendVerifyEmailMutation,
      });

      this.sending = false;

      if (data.sendVerifyEmail && data.sendVerifyEmail.success) {
        // UA tracking
        this.$ua.trackSendEmailVerificationEmailEvent();

        this.sent = true;
      } else {
        throw new Error('failed to save acceptance');
      }
    } catch (error) {
      this.$sentry.captureException(error);
      this.sending = false;
      this.sendError = this.$t(
        'components.emailVerification.verifyEmailAddress.sendError',
      ) as string;
    }
  }

  closeDialog() {
    this.$emit('closeVerifyEmailDialog');
  }

  @Watch('displayVerifyEmailModal')
  resetState(val: boolean, oldVal: boolean) {
    if (oldVal === true && val === false) {
      this.sending = false;
      this.sent = false;
      this.sendError = '';
    }
  }
}
