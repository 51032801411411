import { Module } from 'vuex';
import {
  ICarouselBanner,
  ICmsContentState,
  IInventoryCollection,
  ISimpleBanner,
  ISanityHomepageTakeOver,
  ISanityPromoPack,
  IBlogBlockRule,
  ICampaignWelcomeModal,
  ISanityTopToolbarBanner,
  ISanityGalaLauncher,
  ISanityGalaLauncherV2,
  IGameCardCarousel,
  IGameCard,
} from './types';
import { IRootState } from '../types';
import sanityService, { ISanityGame } from '~/services/sanity.service';
import { IGame } from '../games/types';
import creatorsService, { ILatestBuilds } from '~/services/creators.service';

const namespaced = true;

function sanitizeGames(
  games: ISanityGame[],
  i18n: any,
  ipCountryKey: string,
  ipRegionKey: string,
  builds?: ILatestBuilds,
): IGame[] {
  return games
    .filter(game => {
      return game.carouselImages;
    })
    .map(game => {
      const carouselVideos =
        game.carouselVideos?.length > 0 ? game.carouselVideos : [];

      const sanitizedGame: IGame = {
        ...game,
        gameIFrameCustomCss: game.gameIFrameCustomCss?.code,
        gameIFrameWrapperDivCustomCss: game.gameIFrameWrapperDivCustomCss?.code,
        displayedPlatform: game.platform
          .map(platform => i18n.t(`store.games.index.platforms.${platform}`))
          .join('/'),
        craftedItems: [],
        logo: game.gamePageHeaderLogo,
        showNodePromotion: false,
        isTotallyBlocked:
          game.totallyBlockedInLocations?.includes(ipCountryKey) ||
          game.totallyBlockedInLocations?.includes(ipRegionKey),
        descriptionAssets: [
          {
            thumbnail: game.trailerThumbnail,
            medium: game.trailer,
            poster: game.trailerThumbnail,
            type: 'video' as 'video',
          },
          ...game.carouselImages.map(image => ({
            thumbnail: image,
            medium: image,
            large: image,
            type: 'img' as 'img',
          })),
          ...carouselVideos.map(video => ({
            thumbnail:
              'https://static.gala.games/images/play-btn-thumbnail.png',
            medium: video,
            type: 'video' as 'video',
            hidePlayIcon: true,
          })),
        ].filter(a => a.thumbnail),
      };

      if (game.tenantId && builds) {
        const creatorBuildInfo = builds[game.tenantId];
        if (creatorBuildInfo) {
          if (creatorBuildInfo.latest.windows) {
            sanitizedGame.windowsUsesCreators = true;
            sanitizedGame.latestWindowsVersion =
              creatorBuildInfo.latest.windows;
            sanitizedGame.installerUrlWin = `api/builds/tenants/${game.tenantId}/platforms/windows/versions`;
          }
          if (creatorBuildInfo.latest.mac) {
            sanitizedGame.macUsesCreators = true;
            sanitizedGame.latestMacVersion = creatorBuildInfo.latest.mac;
            sanitizedGame.installerUrlMac = `api/builds/tenants/${game.tenantId}/platforms/mac/versions`;
          }
          if (creatorBuildInfo.latest.android) {
            sanitizedGame.hasApkFile = true;
            sanitizedGame.apkUsesCreators = true;
            sanitizedGame.apkLink = `builds/tenants/${game.tenantId}/platforms/android/versions/${creatorBuildInfo.latest.android}`;
          }
          if (creatorBuildInfo.latest.ios) {
            sanitizedGame.hasIOSDownload = true;
            sanitizedGame.iOSUsesCreators = true;
            sanitizedGame.iOSDownloadLink = `builds/tenants/${game.tenantId}/platforms/ios/versions/${creatorBuildInfo.latest.ios}`;
          }
        }
      }

      return sanitizedGame;
    });
}

function sanitizeGameCardCarousel(carousel: IGameCardCarousel) {
  return carousel?.gameCards ? carousel.gameCards : [];
}

function sanitizeCarouselBanners(
  banners: ICarouselBanner[],
  ipCountryKey: string,
  ipRegionKey: string,
) {
  return banners
    .map(b => ({
      ...b,
      learnMoreLink: b.learnMoreLink?.replace('https://app.gala.games', ''),
      playLink: b.playLink?.replace('https://app.gala.games', ''),
    }))
    .filter(
      b =>
        !b.blockedInLocations?.includes(ipCountryKey) &&
        !b.blockedInLocations?.includes(ipRegionKey),
    );
}

function sanitizeSimpleBanners(
  banners: ISimpleBanner[],
  ipCountryKey: string,
  ipRegionKey: string,
) {
  return banners.filter(
    b =>
      !b.blockedInLocations?.includes(ipCountryKey) &&
      !b.blockedInLocations?.includes(ipRegionKey),
  );
}

function sanitizeHPTOs(hptos: ISanityHomepageTakeOver) {
  return hptos;
}

function sanitizePromoPack(promoPacks: ISanityPromoPack[]) {
  return promoPacks;
}

function sanitizeGalaLauncherInfo(galaLauncherInfo: ISanityGalaLauncher) {
  return galaLauncherInfo;
}

function sanitizeGalaLauncherV2Info(galaLauncherV2Info: ISanityGalaLauncherV2) {
  return galaLauncherV2Info;
}

function sanitizeCampaignWelcomeModals(modals: ICampaignWelcomeModal[]) {
  return modals;
}

function sanitizeTopToolbarBanner(topToolbarBanner: ISanityTopToolbarBanner) {
  return topToolbarBanner;
}

const cmsContent: Module<ICmsContentState, IRootState> = {
  namespaced,
  state: {
    simpleBanners: [],
    carouselBanners: [],
    gameCardCarousel: [],
    inventoryCollections: [],
    blogBlockRules: [],
    campaignWelcomeModals: [],
    homepageTakeover: {
      name: '',
      enabled: false,
      logo: '',
      startDate: '',
      endDate: '',
      noVideoMobile: false,
      bgImageDesktop: '',
      bgImageMobile: '',
      bgVideo: '',
      ctaColor: '',
      ctaLink: '',
      ctaText: '',
      ctaTextColor: '',
      customFont: '',
      customStyles: '',
      glowColor: '',
      headline: '',
      subhead: '',
      textColor: '',
      textShadow: false,
    },
    promoPacks: [],
    topToolbarBanner: {
      enabled: false,
      nodeOwnersOnly: false,
      allowUserToCloseBanner: false,
      toolbarBannerMobileText: '',
      toolbarBannerDesktopText: '',
      url: '',
      toolbarBannerLinkText: '',
    },
    galaLauncherInfo: {
      version: '',
      installerUrlWin: '',
      installerUrlMac: '',
      gameServerEndpointWin: '',
      gameServerEndpointMac: '',
      upgradeFixVersion: '',
    },
    galaLauncherV2Info: {
      version: '',
      installerUrlWin: '',
      installerUrlMac: '',
      gameServerEndpointWin: '',
      gameServerEndpointMac: '',
    },
  },
  getters: {
    inventoryIconForCollectionName: (state: ICmsContentState) => {
      return Object.fromEntries(
        state.inventoryCollections.map(collection => [
          collection.name,
          collection,
        ]),
      );
    },
    mainBanner: (state: ICmsContentState) => {
      return state.simpleBanners.find(banner => banner.showOnMain);
    },
    mainBanners: (state: ICmsContentState) => {
      return state.simpleBanners.filter(banner => banner.showOnMain);
    },
    storeBanner: (state: ICmsContentState) => {
      return state.simpleBanners.find(banner => banner.showOnStore);
    },
    storeBanners: (state: ICmsContentState) => {
      return state.simpleBanners.filter(banner => banner.showOnStore);
    },
  },
  actions: {
    loadCmsContent: async (
      context,
      args: {
        i18n: any;
        sentry: { captureException: (a: unknown) => unknown };
        ipLocation: {
          country: string;
          region: string;
        };
      },
    ) => {
      const locale = args.i18n.locale;
      const ipCountryKey = args?.ipLocation?.country ?? '';
      const ipRegionKey = `region-${ipCountryKey}-${args.ipLocation?.region ??
        ''}`;

      const results = await Promise.allSettled([
        sanityService
          .getCarouselBanners(locale)
          .then(carouselBanners =>
            context.commit(
              'setCarouselBanners',
              sanitizeCarouselBanners(
                carouselBanners,
                ipCountryKey,
                ipRegionKey,
              ),
            ),
          ),
        sanityService
          .getSimpleBanners(locale)
          .then(simpleBanners =>
            context.commit(
              'setSimpleBanners',
              sanitizeSimpleBanners(simpleBanners, ipCountryKey, ipRegionKey),
            ),
          ),
        sanityService
          .getInventoryCollections(locale)
          .then(inventoryCollections =>
            context.commit('setInventoryCollections', inventoryCollections),
          ),
        creatorsService.getLatestBuilds().then(creatorsBuilds => {
          return sanityService.getGames(locale).then(games => {
            context.commit(
              'games/setGames',
              sanitizeGames(
                games,
                args.i18n,
                ipCountryKey,
                ipRegionKey,
                creatorsBuilds,
              ),
              {
                root: true,
              },
            );
          });
        }),

        sanityService.getPromoPackInformation(locale).then(promoPacks => {
          context.commit('setPromoPacks', sanitizePromoPack(promoPacks));
        }),
        sanityService.getTopToolbarBanner(locale).then(topToolbarBanner => {
          context.commit(
            'setTopToolbarBanner',
            sanitizeTopToolbarBanner(topToolbarBanner),
          );
        }),
        sanityService
          .getCampaignWelcomeModals(locale, ipCountryKey, ipRegionKey)
          .then(modals => {
            context.commit(
              'setCampaignWelcomeModals',
              sanitizeCampaignWelcomeModals(modals),
            );
          }),
        sanityService
          .getHomepageTakeOvers(locale, ipCountryKey, ipRegionKey)
          .then(hptos => {
            context.commit('setHomepageTakeover', sanitizeHPTOs(hptos));
          }),
        sanityService
          .getBlogBlockRules(ipCountryKey, ipRegionKey)
          .then(rules => {
            context.commit('setBlogBlockRules', rules);
          }),
        sanityService
          .getGalaLauncherInformation(locale)
          .then(galaLauncherInfo => {
            context.commit(
              'setGalaLauncherInfo',
              sanitizeGalaLauncherInfo(galaLauncherInfo),
            );
          }),
        sanityService
          .getGalaLauncherV2Information(locale)
          .then(galaLauncherV2Info => {
            context.commit(
              'setGalaLauncherV2Info',
              sanitizeGalaLauncherV2Info(galaLauncherV2Info),
            );
          }),
        sanityService.getGameCardCarousel(locale).then(carousel => {
          context.commit(
            'setGameCardCarousel',
            sanitizeGameCardCarousel(carousel),
          );
        }),
      ]);

      for (const result of results) {
        if (result.status === 'rejected') {
          console.warn(result.reason);
          args.sentry.captureException(result.reason);
        }
      }
    },
  },
  mutations: {
    setGameCardCarousel(state, carousel: IGameCard[]) {
      state.gameCardCarousel = carousel;
    },
    setCarouselBanners(state, banners: ICarouselBanner[]) {
      state.carouselBanners = [...banners];
    },
    setSimpleBanners(state, banners: ISimpleBanner[]) {
      state.simpleBanners = [...banners];
    },
    setInventoryCollections(state, collections: IInventoryCollection[]) {
      state.inventoryCollections = [...collections];
    },
    setHomepageTakeover(state, hpto: ISanityHomepageTakeOver) {
      state.homepageTakeover = hpto;
    },
    setPromoPacks(state, promoPacks: ISanityPromoPack[]) {
      state.promoPacks = promoPacks;
    },
    setBlogBlockRules(state, rules: IBlogBlockRule[]) {
      state.blogBlockRules = rules;
    },
    setCampaignWelcomeModals(state, modals: ICampaignWelcomeModal[]) {
      state.campaignWelcomeModals = modals;
    },
    setTopToolbarBanner(state, topToolbarBanner: ISanityTopToolbarBanner) {
      state.topToolbarBanner = topToolbarBanner;
    },
    setGalaLauncherInfo(state, galaLauncherInfo: ISanityGalaLauncher) {
      state.galaLauncherInfo = galaLauncherInfo;
    },
    setGalaLauncherV2Info(state, galaLauncherV2Info: ISanityGalaLauncherV2) {
      state.galaLauncherV2Info = galaLauncherV2Info;
    },
  },
};

export default cmsContent;
