



























































































































































































































































import axios from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import uniqueString from 'unique-string';
import { State, Action } from 'vuex-class';

import { ISanityGalaLauncherV2 } from '~/store/cms_content/types';
import { IGame } from '~/store/games/types';
import LauncherGenerateId from '~/queries/launcherGenerateId.gql';
import LauncherSignUri from '~/queries/launcherSignUri.gql';

enum DisplayState {
  LAUNCHING = 'LAUNCHING',
  NEED_LAUNCHER = 'NEED_LAUNCHER',
  DOWNLOADING_LAUNCHER = 'DOWNLOAD_LAUNCHER',
}

@Component({
  components: {},
})
export default class GameLaunchDialog extends Vue {
  @State(cmsContent => cmsContent.galaLauncherV2Info, {
    namespace: 'cmsContent',
  })
  galaLauncherV2Info!: ISanityGalaLauncherV2;

  @Prop({ type: Boolean })
  readonly showModal!: boolean;
  @Prop(String) readonly gameName!: string;
  @Prop(String) readonly gameImg!: string;
  @Prop(String) readonly gameToken!: string;
  @Prop(Object) readonly game!: IGame;

  @Action('addActiveUser', { namespace: 'games' })
  public addActiveUser!: (data: {
    gameName: string;
    cacheTtl?: number;
  }) => Promise<void>;

  isWindows: boolean = navigator.userAgent.includes('Win');
  isMac: boolean = navigator.userAgent.includes('Mac');

  launcherInstallerUrl: string = '';
  launcherInstallerFileName: string = '';
  launcherId: string = '';
  gameLaunchUri: string = '';

  showInstallerDownloadSpinner: boolean = true;

  DisplayState = DisplayState;
  displayState = DisplayState.LAUNCHING;

  async created() {
    this.displayState = DisplayState.LAUNCHING;

    let installerUrl = null;
    if (this.isWindows && this.game.isWinReleased) {
      // Install game, windows version
      installerUrl = this.game.installerUrlWin;
    } else if (this.isMac && this.game.isMacReleased) {
      // Install game, mac version
      installerUrl = this.game.installerUrlMac;
    } else {
      throw new Error('Platform not supported');
    }

    const gameName = this.game.codeName;

    let useCreators = this.getGameUsesCreators();
    if (useCreators !== true) {
      useCreators = false;
    }

    interface StringKeyedObject {
      [key: string]: string;
    }
    const params: StringKeyedObject = {
      url: `${installerUrl}/${this.getGameLatestVersion()}`,
      jwt: this.gameToken,
      useCreators: useCreators.toString(),
      displayName: this.game?.displayName,
      devStatus: this.getEarlyAccessDetails(),
      img: this.game?.linkImage,
    };

    // ex: gala://spider-tanks?asdf ...
    const paramsString = new URLSearchParams(params).toString();
    const queryString = `${gameName}?${paramsString}`;
    const unsignedUri = `${process.env.launcherUriProtocol}${queryString}`;
    const signedUri = await this.launcherSignUri(unsignedUri);
    this.gameLaunchUri = signedUri;
    // console.log(`URI scheme: ${this.gameLaunchUri}`);

    // This line results in firing off the scheme and engaging the launcher
    window.location.href = this.gameLaunchUri;

    // Until we have API checks in place, the best way to determine whether the
    // launcher is installed is to check whether the website still has focus
    setTimeout(() => {
      // If the website still has focus after a couple
      // seconds, assume that the gala launcher is not installed
      if (document.hasFocus()) {
        this.displayState = DisplayState.NEED_LAUNCHER;
        return;
      }

      // Website doesn't have focus. Launch in progress. Close this dialog
      if (this.game?.codeName && !this.game.activePlayerCountInDataBricks) {
        this.addActiveUser({
          gameName: this.game.codeName,
          cacheTtl: this.game.timePlayerIsActive,
        });
      }
      this.$emit('close');
    }, 4000); // # seconds timeout
  }

  getGameUsesCreators() {
    if (this.isWindows) {
      return this.game.windowsUsesCreators;
    }

    if (this.isMac) {
      return this.game.macUsesCreators;
    }
  }

  getGameLatestVersion() {
    if (this.isWindows) {
      return this.game.latestWindowsVersion;
    }

    if (this.isMac) {
      return this.game.latestMacVersion;
    }

    return undefined;
  }

  getEarlyAccessDetails() {
    const devStatus = this.game?.devStatusAndProgress;
    if (this.game?.customDevStatus) {
      return this.game.customDevStatus;
    } else if (devStatus?.primaryStatus === 'early access') {
      return devStatus.statusDetails
        ? devStatus.statusDetails
        : devStatus.primaryStatus;
    }
    return '';
  }

  async downloadGalaLauncher() {
    if (this.isWindows && this.galaLauncherV2Info.installerUrlWin) {
      this.launcherInstallerUrl = this.galaLauncherV2Info.installerUrlWin;
    } else if (this.isMac && this.galaLauncherV2Info.installerUrlMac) {
      this.launcherInstallerUrl = this.galaLauncherV2Info.installerUrlMac;
    } else {
      throw new Error('Platform not supported');
    }

    await this.setLauncherInstallerFileName();
    // console.log(`Launcher Installer URL: ${this.launcherInstallerUrl}`);
    // console.log(`File name: ${this.launcherInstallerFileName}`);

    this.launcherInstallerUrl +=
      '?response-content-disposition=attachment;filename=';
    this.launcherInstallerUrl += this.launcherInstallerFileName;

    // UA tracking
    this.$ua.trackLauncherInitialDownloadBeginEvent({
      userAgent: navigator.userAgent,
      currentVersion: this.galaLauncherV2Info.version,
      isWindows: this.isWindows,
      isMac: this.isMac,
    });

    const link = document.createElement('a');
    link.href = this.launcherInstallerUrl;

    document.body.appendChild(link);
    link.click();
    link.remove();

    await new Promise(resolve => setTimeout(resolve, 3000));
    this.showInstallerDownloadSpinner = false;
  }

  async setLauncherInstallerFileName() {
    const urlObj = new URL(this.launcherInstallerUrl);
    const fullPath = urlObj.pathname;

    const fileName = fullPath.substring(fullPath.lastIndexOf('/') + 1);
    const fileExtensionIndex = fileName.lastIndexOf('.');
    // const baseName = fileName.substring(0, fileExtensionIndex);
    const extension = fileName.substring(fileExtensionIndex);

    this.launcherId = await this.launcherGenerateId();
    // console.log('this.launcherId', this.launcherId);

    // We don't want an incredibly long ID string since it will be embedded
    // in the installer's file name. 10 characters should be sufficient.
    // const idString = uniqueString();
    // this.launcherId = idString.substring(0, 10);

    const fileNameWithId = `GalaLauncherInstaller[${this.launcherId}]${extension}`;
    this.launcherInstallerFileName = fileNameWithId;
  }

  async launcherGenerateId() {
    // console.log('launcherGenerateId');
    if (this.$apolloProvider) {
      const client = this.$apolloProvider.defaultClient;
      const fetchPolicy = 'network-only';
      const { data } = await client.query({
        query: LauncherGenerateId,
        variables: {
          uri: this.gameLaunchUri,
        },
        fetchPolicy,
      });
      // console.log('data', data);

      return data?.launcherGenerateId?.generatedId;
    }
  }

  async launcherSignUri(uri: string) {
    // console.log(`launcherSignUri: ${uri}`);
    if (this.$apolloProvider) {
      const client = this.$apolloProvider.defaultClient;
      const fetchPolicy = 'network-only';
      const { data } = await client.query({
        query: LauncherSignUri,
        variables: {
          uri: uri,
        },
        fetchPolicy,
      });
      // console.log('data', data);
      // console.log(`Signed URI: ${data?.launcherSignUri?.signedUri}`);
      return data?.launcherSignUri?.signedUri;
    }
  }

  downloadClicked() {
    this.showInstallerDownloadSpinner = true;
    this.displayState = this.DisplayState.DOWNLOADING_LAUNCHER;
    this.downloadGalaLauncher();
  }

  declineClicked() {
    this.$emit('close');
  }

  closeClicked() {
    this.$emit('close');
  }
}
