// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/banners/gala-profile-banner.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".nav-drawer .v-toolbar__content{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;padding-bottom:0;padding-top:0}.nav-drawer .close{background:#000;border-radius:50%;width:25px;height:25px;display:flex;align-items:center;justify-content:center}.nav-drawer .profile-progress-icon{transform:translateY(50%)}.nav-drawer .nav-circle-item{border:2px solid var(--v-primary-lighten4);width:38px;height:38px;border-radius:20px;display:flex;align-items:center;justify-content:center;transition:border-color .2s ease}.nav-drawer .nav-circle-item:hover{border-color:#fff;cursor:pointer}", ""]);
// Exports
module.exports = exports;
