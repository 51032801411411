import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import copy from 'copy-to-clipboard';

// You can declare a mixin as the same style as components.
@Component
export default class CopyToClipboard extends Vue {
  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  copyToClipboard(value: string) {
    try {
      copy(value, {
        debug: process.env.nodeEnv !== 'production',
        message: this.$t('mixins.copyToClipboard.pressKeyToCopy') as string,
        format: 'text/plain',
      });

      this.updateSnackbarSuccessText(this.$t('mixins.copyToClipboard.copied'));
      this.toggleSuccessSnackbar();
    } catch (err) {
      this.$sentry.captureException(err);
      this.updateSnackbarErrorText(
        this.$t('mixins.copyToClipboard.unableToCopy') as string,
      );
      this.toggleErrorSnackbar();
    }
  }
}
