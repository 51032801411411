











































import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import VerifyEmailBlock from '~/components/EmailVerification/VerifyEmailBlock.vue';
import AcceptTermsAndConditions from '~/components/Home/AcceptTermsAndConditions.vue';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';

@Component({
  components: {
    VerifyEmailBlock,
    AcceptTermsAndConditions,
  },
})
export default class LoginAndRegister extends Vue {
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  showAcceptTermsAndConditions = false;
  showVerifyEmailBlock = false;
  referredBy = '';

  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;

  @Action('getGameJWT', { namespace: 'profile' })
  private getGameJwt!: () => void;

  mounted() {
    const { register, login } = this.$route.query;
    if (login) {
      this.goToLoginService();
    } else if (register) {
      this.goToRegisterService();
    }
  }

  created() {
    if (process.browser) {
      const r = localStorage.getItem('r') as string;
      const referredBy = localStorage.getItem('referredBy') as string;
      const referredByParam = (this.$nuxt.$route.query.r ??
        this.$nuxt.$route.query.referralCode) as string | null;
      this.referredBy = (referredByParam || r || referredBy || '').replace(
        /[^a-zA-Z0-9]/g,
        '',
      );
      if (this.referredBy) {
        localStorage.setItem('referredBy', this.referredBy);
      }
    }
  }

  goToLoginService() {
    if (!['/loggedin'].includes(this.$route.path)) {
      setRedirectAfterLogin(this.$route.fullPath);
    }

    return this.$auth.loginWithRedirect({
      authorizationParams: { prompt: 'login' },
    });
  }

  goToRegisterService() {
    setRedirectAfterLogin(this.$route.fullPath);
    return this.$auth.loginWithRedirect({
      authorizationParams: { prompt: 'login', screen_hint: 'signup' },
    });
  }

  handleSignedIn() {
    this.updateUser({ loggedIn: true });
    this.getGameJwt();
  }

  handleTermsAndConditionsAccepted() {
    this.showAcceptTermsAndConditions = false;
    setTimeout(() => (this.showVerifyEmailBlock = true), 300);
  }

  handleCloseVerifyEmailBlock() {
    this.showVerifyEmailBlock = true;
    this.updateUser({ loggedIn: true });
  }
}
