import { Contract, providers } from 'ethers';
import erc20Abi from '../data/abi/erc20.json';
export const checkForSufficientErc20Allowance = async ({
  owner,
  spender,
  contractAddress,
  neededAmount,
  web3Provider,
}: {
  owner: string;
  spender: string;
  contractAddress: string;
  neededAmount: number;
  web3Provider: providers.Web3Provider | null;
}) => {
  if (web3Provider) {
    const signer = web3Provider.getSigner(owner);
    const contract = new Contract(contractAddress, erc20Abi, signer);
    const allowance = await contract.allowance(owner, spender);
    if (allowance) {
      const decimals = await contract.decimals();
      const allowanceInDecimals = allowance.toString() / 10 ** decimals;
      return allowanceInDecimals >= neededAmount;
    }
    return false;
  } else {
    return false;
  }
};
